
			<app-layout-header></app-layout-header>
			<!-- Page Content -->
			<div class="content">
				<div class="container">

					<div class="row">
						<div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
						
                                <app-menu-layout></app-menu-layout>

							
						</div>
						
						<div class="col-md-7 col-lg-8 col-xl-9">

                            

							<div class="row">
								<div class="col-sm-12">
									<div class="card">
                                        <div class="card-body pt-0">
                                        
                                            <!-- Tab Menu -->
                                            <nav class="user-tabs mb-4">
                                                <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                                    <li class="nav-item">
                                                        <a class="nav-link active" href="#pat_accounts" data-bs-toggle="tab">Users</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" href="#pat_refundrequest" data-bs-toggle="tab">Categories</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <!-- /Tab Menu -->
                                            
                                            <!-- Tab Content -->
                                            <div class="tab-content pt-0">
                                                
                                                <!-- Accounts Tab -->
                                                <div id="pat_accounts" class="tab-pane fade show active">
                                                    <div class="mb-0">
                                                        <div>
                                                                <div class="row">

                                                                    <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let user of favorites">
                                                                        <div class="doctor-profile-widget doc-grid">
                                                                            <div class="doc-pro-img">
                                                                                <a [routerLink]="['/user',user.pay_call_id]"> 
                                                                                    <div class="doctor-profile-img">
                                                                                        <img src="{{user.thumbnail}}" class="img-fluid" alt="PayCall user - {{user.full_name}}" style="height: 13rem;object-fit: contain;">
                                                                                    </div>
                                                                                </a>
                                                                                <div class="reviews-ratings">
                                                                                    <p>
                                                                                        <span><i class="fa fa-star"></i> 4.5</span>
                                                                                    </p>
                                                                                </div>
                                                                                <div class="fav-btn">
                                                                                    <a href="javascript:void(0)" class="favourite-icon" style="color: red;">
                                                                                        <i class="fa fa-heart"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                            <div class="doc-content">
                                                                                <div class="doc-pro-info text-center">
                                                                                    <div class="doc-pro-name">
                                                                                        <h4><a [routerLink]="['/user',user.pay_call_id]">{{user.full_name}}</a><i class="fa fa-check-circle"></i></h4>
                                                                                        <p class="d-flex justify-content-center align-items-center">
                                                                                            <span class="btn btn-outline-primary btn-sm">Cardiology</span>&nbsp;
                                                                                            <span class="btn btn-outline-primary btn-sm">Cardiology</span>
                                                                                    </div>
                                                                                    <div class="review-price d-none">
                                                                                        <p>$1100.00<span>/hr</span></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="doc-pro-location action-profile-btns d-flex align-items-center justify-content-around">
                                                                                    <!--<p><i class="feather-map-pin"></i> <span>0.9</span> mi - New York, USA</p>
                                                                                    <p><i class="feather-award"></i> <span>15</span> Years of Experience</p>-->
                                                                                    <a href="#0"><label><i class="fa fa-phone"></i><p>${{user.voice_call_price}}<span>/Min</span></p></label></a>
                                                                                    <a href="#0"><label><i class="fa fa-video-camera"></i><p>${{user.video_call_price}}<span>/Min</span></p></label></a>
                                                                                    <a href="#0"><label><i class="fa fa-comment"></i><p>${{user.text_message_price}}<span>/Text</span></p></label></a>
                                                                                    <a href="#0"><label><i class="fa fa-microphone"></i><p>${{user.text_message_price}}<span>/Vm</span></p></label></a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- /Accounts Tab -->
                                                
                                                <!-- Refund Request Tab -->
                                                <div class="tab-pane fade" id="pat_refundrequest">
                                                    <div class="card card-table mb-0">
                                                        <div class="card-body">

                                                                Soon !!


                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- /Refund Request Tab -->
                                                    
                                            </div>
                                            <!-- Tab Content -->
                                            
                                        </div>
                                    </div>
								</div>
							</div>
								
						</div>
					</div>

				</div>

			</div>		
			<!-- /Page Content -->
            
          