
  <div>
    <h5>
        Connect wallet
    </h5>
    <p class="text-sm font-normal text-gray-500 dark:text-gray-400">Connect with one of our available wallet providers or create a new one.</p>


      <button class="d-flex justify-content-between align-items-center wallet-item border-0 w-100" (click)="openMetamask()">
        <div>
            <svg width="40" height="40" viewBox="0 0 42 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M32.9583 1L19.8242 10.7183L22.2666 4.99099L32.9583 1Z" fill="#E17726" stroke="#E17726"
                    stroke-width="0.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2.66284 1L15.68 10.809L13.3546 4.99098L2.66284 1Z" fill="#E27625" stroke="#E27625"
                    stroke-width="0.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M28.2292 23.5334L24.7346 28.872L32.2175 30.9323L34.3611 23.6501L28.2292 23.5334Z"
                    fill="#E27625" stroke="#E27625" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M1.27271 23.6501L3.40325 30.9323L10.8732 28.872L7.39154 23.5334L1.27271 23.6501Z"
                    fill="#E27625" stroke="#E27625" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M10.4704 14.5149L8.39185 17.6507L15.7968 17.9876L15.55 10.0186L10.4704 14.5149Z"
                    fill="#E27625" stroke="#E27625" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M25.1503 14.515L19.9929 9.92798L19.824 17.9877L27.2289  17.6508L25.1503 14.515Z"
                    fill="#E27625" stroke="#E27625" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M10.8733 28.872L15.3552 26.7081L11.4969 23.7019L10.8733 28.872Z" fill="#E27625"
                    stroke="#E27625" stroke-width="0.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20.2659 26.7081L24.7348 28.872L24.1242 23.7019L20.2659 26.7081Z" fill="#E27625"
                    stroke="#E27625" stroke-width="0.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M24.7348 28.8722L20.2659 26.7083L20.6296 29.6108L20.5906 30.8418L24.7348 28.8722Z"
                    fill="#D5BFB2" stroke="#D5BFB2" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M10.8733 28.8722L15.0305 30.8418L15.0045 29.6108L15.3552 26.7083L10.8733 28.8722Z"
                    fill="#D5BFB2" stroke="#D5BFB2" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M15.1083 21.7842L11.3928 20.6958L14.017 19.4907L15.1083 21.7842Z" fill="#233447"
                    stroke="#233447" stroke-width="0.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20.5127 21.7842L21.604 19.4907L24.2412 20.6958L20.5127 21.7842Z" fill="#233447"
                    stroke="#233447" stroke-width="0.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.8732 28.872L11.5228 23.5334L7.3916 23.6501L10.8732 28.872Z" fill="#CC6228"
                    stroke="#CC6228" stroke-width="0.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M24.0981 23.5334L24.7347 28.872L28.2293 23.6501L24.0981 23.5334Z" fill="#CC6228"
                    stroke="#CC6228" stroke-width="0.25" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M27.2289 17.6506L19.824 17.9875L20.5125 21.7842L21.6038 19.4906L24.241 20.6957L27.2289 17.6506Z"
                    fill="#CC6228" stroke="#CC6228" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M11.3928 20.6957L14.017 19.4906L15.1083 21.7842L15.7968 17.9875L8.39185 17.6506L11.3928 20.6957Z"
                    fill="#CC6228" stroke="#CC6228" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M8.39209 17.6506L11.497 23.7019L11.393 20.6957L8.39209 17.6506Z" fill="#E27525"
                    stroke="#E27525" stroke-width="0.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M24.2412 20.6957L24.1243 23.7019L27.2292 17.6506L24.2412 20.6957Z" fill="#E27525"
                    stroke="#E27525" stroke-width="0.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.7972 17.9875L15.1086 21.7842L15.979 26.2675L16.1739 20.3588L15.7972 17.9875Z"
                    fill="#E27525" stroke="#E27525" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M19.8242 17.9875L19.4604 20.3459L19.6423 26.2675L20.5127 21.7842L19.8242 17.9875Z"
                    fill="#E27525" stroke="#E27525" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M20.5127 21.7843L19.6423 26.2676L20.2659 26.7082L24.1243 23.702L24.2412 20.6958L20.5127 21.7843Z"
                    fill="#F5841F" stroke="#F5841F" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M11.3928 20.6958L11.4968 23.702L15.3551 26.7082L15.9787 26.2676L15.1083 21.7843L11.3928 20.6958Z"
                    fill="#F5841F" stroke="#F5841F" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M20.5907 30.8417L20.6296 29.6107L20.2919 29.3256H15.3293L15.0045 29.6107L15.0305 30.8417L10.8733 28.8721L12.3283 30.0642L15.2773 32.0986H20.3308L23.2928 30.0642L24.7348 28.8721L20.5907 30.8417Z"
                    fill="#C0AC9D" stroke="#C0AC9D" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path    
                    d="M20.2658 26.7081L19.6422 26.2676H15.9787L15.3552 26.7081L15.0044 29.6107L15.3292 29.3256H20.2918L20.6296 29.6107L20.2658 26.7081Z"
                    fill="#161616" stroke="#161616" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M33.5168 11.3532L34.6211 5.98873L32.9582 1L20.2659 10.3944L25.1505 14.5149L32.0488 16.5234L33.5688 14.7482L32.9063 14.2687L33.9585 13.3099L33.1531 12.6879L34.2054 11.8845L33.5168 11.3532Z"
                    fill="#763E1A" stroke="#763E1A" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M1 5.98873L2.11724 11.3532L1.40273 11.8845L2.468 12.6879L1.66255 13.3099L2.71483 14.2687L2.05228 14.7482L3.57225 16.5234L10.4706 14.5149L15.3552 10.3944L2.66287 1L1 5.98873Z"
                    fill="#763E1A" stroke="#763E1A" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M32.0489 16.5233L25.1506 14.5149L27.2292 17.6507L24.1243 23.7019L28.2295 23.6501H34.3613L32.0489 16.5233Z"
                    fill="#F5841F" stroke="#F5841F" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M10.4704 14.5149L3.57214 16.5233L1.27271 23.6501H7.39154L11.4967 23.7019L8.39186 17.6507L10.4704 14.5149Z"
                    fill="#F5841F" stroke="#F5841F" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M19.8241 17.9876L20.2658 10.3943L22.2664 4.99097H13.3545L15.3551 10.3943L15.7968 17.9876L15.9657 20.3718L15.9787 26.2676H19.6422L19.6552 20.3718L19.8241 17.9876Z"
                    fill="#F5841F" stroke="#F5841F" stroke-width="0.25" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            <strong>Metamask</strong>
        </div>
        <span *ngIf="metamaskInstalled" class="installed">Installed</span>
    </button>
      <!--<button class="d-flex justify-content-start align-items-center wallet-item border-0 w-100" disabled>
        <svg width="40" height="40" viewBox="0 0 39 43" fill="none" xmlns="http://www.w3.org/2000/svg" exponent="342423" class="h-[258px] w-auto desktop:h-[310px]"><path d="M0.710815 6.67346L19.4317 0.606445V42.6064C6.05944 37.0059 0.710815 26.2727 0.710815 20.207V6.67346Z" fill="#0500FF"></path><path d="M38.1537 6.67346L19.4329 0.606445V42.6064C32.8051 37.0059 38.1537 26.2727 38.1537 20.207V6.67346Z" fill="url(#paint0_linear_524_75868342423)"></path><defs><linearGradient id="paint0_linear_524_75868342423" x1="33.1809" y1="-2.33467" x2="19.115" y2="42.0564" gradientUnits="userSpaceOnUse"><stop offset="0.02" stop-color="#0000FF"></stop><stop offset="0.08" stop-color="#0094FF"></stop><stop offset="0.16" stop-color="#48FF91"></stop><stop offset="0.42" stop-color="#0094FF"></stop><stop offset="0.68" stop-color="#0038FF"></stop><stop offset="0.9" stop-color="#0500FF"></stop></linearGradient></defs></svg>
        <strong>Trust Wallet</strong>
      </button>-->
      <button class="d-flex justify-content-between align-items-center wallet-item border-0 w-100" (click)="connectPhantomWallet()">
        <div>
            <svg width="40" height="40" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="128" height="128" fill="#AB9FF2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.6416 82.1477C50.8744 89.4525 42.8862 98.6966 32.2568 98.6966C27.232 98.6966 22.4004 96.628 22.4004 87.6424C22.4004 64.7584 53.6445 29.3335 82.6339 29.3335C99.1257 29.3335 105.697 40.7755 105.697 53.7689C105.697 70.4471 94.8739 89.5171 84.1156 89.5171C80.7013 89.5171 79.0264 87.6424 79.0264 84.6688C79.0264 83.8931 79.1552 83.0527 79.4129 82.1477C75.7409 88.4182 68.6546 94.2361 62.0192 94.2361C57.1877 94.2361 54.7397 91.1979 54.7397 86.9314C54.7397 85.3799 55.0618 83.7638 55.6416 82.1477ZM80.6133 53.3182C80.6133 57.1044 78.3795 58.9975 75.8806 58.9975C73.3438 58.9975 71.1479 57.1044 71.1479 53.3182C71.1479 49.532 73.3438 47.6389 75.8806 47.6389C78.3795 47.6389 80.6133 49.532 80.6133 53.3182ZM94.8102 53.3184C94.8102 57.1046 92.5763 58.9977 90.0775 58.9977C87.5407 58.9977 85.3447 57.1046 85.3447 53.3184C85.3447 49.5323 87.5407 47.6392 90.0775 47.6392C92.5763 47.6392 94.8102 49.5323 94.8102 53.3184Z" fill="#FFFDF8"/>
                </svg>
            <strong>Phantom</strong>
        </div>

        <span class="installed">Web Only</span>
      </button>
  </div>