<app-layout-header></app-layout-header>
			
			<!-- Page Content -->
			<div class="content">
				<div class="container">

					<div class="row">
						<div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
						
                            <app-menu-layout></app-menu-layout>

							
						</div>
						
						<div class="col-md-7 col-lg-8 col-xl-9">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title mb-3">Social Media Links</h4>



									<div class="row experience-cont" *ngFor="let userLink of userSocialLinks">
											<div class="col-12 col-md-10 col-lg-11">
												<div class="row">
													<div class="col-12 col-md-6 col-lg-12">
															<div class="mb-3">
																	<label class="focus-label">{{userLink.label}} link</label>
																<input 
																#instaLink
																[(ngModel)]="userLink.user_social_link_username"
																#instagram_link="ngModel"
																name="user_social_link_username"
																class="form-control">
															</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-1 col-lg-1">
												<label class="d-md-block d-sm-none d-none">&nbsp;</label>
												<a (click)="deleteUserSocialMediaLink(userLink.id)" class="btn btn-danger trash"><i class="fa fa-trash"></i></a>
											</div>
									</div>
									<div class="row">
										<div class="col-12">
											<button class="btn btn-link text-decoration-none" (click)="showHideAddSocialLinkForm()">Add Link</button>
										</div>
									</div>


									<!-- Social Form -->
									<form #form="ngForm" [hidden]="!showForm" (ngSubmit)="submitNewLink(form)">                                                                                           
										 
										<div class="row experience-cont">
											<div class="col-12 col-md-10 col-lg-10">
												<div class="row">
													<div class="col-12 col-md-6 col-lg-6">
														<div class="mb-3"><label>Social platform</label>
															<select 
															(change)="getSelectedLink($event)" class="form-control">
																<option value="{{link.id}}-{{link.link}}" *ngFor="let link of socialLinks">
																		{{link.label}}
																</option>
															</select>
														</div>
													</div>
													<div class="col-12 col-md-6 col-lg-6">
														<div class="mb-3"><label>From</label>
															<input type="hidden" [(ngModel)]="selectedLinkId" name="social_link_id" >
															<input [placeholder]="SelectedLinkUrl" name="user_social_link_username" ngModel type="text" class="form-control">
														</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-2 col-lg-1">
												<label class="d-md-block d-sm-none d-none">&nbsp;</label>
												<button type="submit" class="btn btn-danger trash bg-success border-0"><i class="fa fa-check"></i></button>
											</div>
											<div class="col-12 col-md-2 col-lg-1">
													<label class="d-md-block d-sm-none d-none">&nbsp;</label>
													<a (click)="showHideAddSocialLinkForm()" class="btn btn-danger trash"><i class="fa fa-trash"></i></a>
												</div>
										</div>

									</form>
									<!-- /Social Form -->
									
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>		
			<!-- /Page Content -->
   