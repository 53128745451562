import { Component, OnInit } from '@angular/core';
import { AgoraClient, ClientEvent, NgxAgoraService, Stream, StreamEvent } from 'ngx-agora';
import { ActivatedRoute, Router } from '@angular/router';
import { CallService } from '../../../services/apis/calls/call.service';
import { WalletService } from '../../../services/apis/wallets/wallet.service';
import * as uuid from 'uuid';
import { interval } from 'rxjs';


@Component({
  selector: 'app-video-voice-call',
  templateUrl: './video-voice-call.component.html',
  styleUrls: ['./video-voice-call.component.scss']
})
export class VideoVoiceCallComponent implements OnInit {

  title = 'angular-video';
  localCallId = 'agora_local';
  remoteCalls: string[] = [];

  private client: AgoraClient;
  private localStream: Stream;
  private uid: number;

  payPeerSecond: number;
  receiverId: number;
  senderId: number;


  isVideo: boolean = false;
  isAudio: boolean = true;
  callUserType:any;
  ccUserId:any;
  room:any;
  dataRoomUser:any;
  timeCall:number = 0;


  //controls
  isAudioMuted: boolean = false;
  isVideoMuted: boolean = false;

  constructor(
    private ngxAgoraService: NgxAgoraService,
    private route: ActivatedRoute,
    private router: Router,
    private callService: CallService,
    private walletService: WalletService
  ) {
    //this.uid = Math.floor(Math.random() * 100);  
    this.uid = uuid.v4();
    const routeParams = this.route.snapshot.paramMap;
    this.ccUserId = routeParams.get('userId');
    this.callUserType = routeParams.get('callType');
    if (this.callUserType == 'video') {
      this.isVideo = true;
    }
    if (this.route.snapshot.paramMap.get('roomId')) {
      this.room = this.route.snapshot.paramMap.get('roomId');
      this.getEnCallRoomData(this.room);
    } else {
      this.callService.createNewCall(this.ccUserId, this.uid, this.callUserType).subscribe(response => {
        this.getEnCallRoomData(this.uid);
        this.room = this.uid;
      });
    }
  }

  ngOnInit() {
    this.client = this.ngxAgoraService.createClient({ mode: 'rtc', codec: 'h264' });
    this.assignClientHandlers();

    this.localStream = this.ngxAgoraService.createStream({ streamID: this.uid, audio: true, video: this.isVideo, screen: false });
    this.assignLocalStreamHandlers();
    // Join and publish methods added in this step    
    this.initLocalStream(() => this.join(uid => this.publish(), error => console.error(error)));
  }

 
  join(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    //this.client.join(null, 'foo-bar', this.uid, onSuccess, onFailure);
    this.client.join(null, 'hihfgdfgddfd', this.uid, onSuccess, onFailure);

  }

  
  publish(): void {
    this.client.publish(this.localStream, err => console.log('Publish local stream error: ' + err));
  }

  /*private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      //console.log('Publish local stream successfully');
    });

    this.client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        //console.log('Subscribe stream failed', err);
      });
    });

    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      interval(1000).subscribe(() => {
        this.timeCall += 1;
        this.createTransactionSender(this.senderId, this.payPeerSecond);
      });
      if (!this.remoteCalls.length) {
        this.remoteCalls.push(id);
        setTimeout(() => stream.play(id), 1000);
      }
    });

    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = [];
        //console.log(`Remote stream is removed ${stream.getId()}`);
        this.router.navigate(['/call-end-page', this.room, this.timeCall]).then(() => {
          window.location.reload();
        });
      }
    });

    this.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
        //console.log(`${evt.uid} left from this channel`);
        this.router.navigate(['/call-end-page', this.room, this.timeCall]).then(() => {
          window.location.reload();
        });
      }
    });
  }*/


  /*private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      // Handle local stream being published
    });
  
    this.client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });
  
    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        // Handle subscription error
      });
    });
  
    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      interval(1000).subscribe(() => {
        this.timeCall += 1;
        this.createTransactionSender(this.senderId, this.payPeerSecond);
      });
      if (!this.remoteCalls.length) {
        this.remoteCalls.push(id);
        setTimeout(() => stream.play(id), 1000);
      }
    });
  
    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      this.handleCallEnd();
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = [];
      }
    });
  
    this.client.on(ClientEvent.PeerLeave, evt => {
      this.handleCallEnd();
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
      }
    });
  }*/


  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      // Handle local stream being published
    });
  
    this.client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });
  
    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        // Handle subscription error
      });
    });
  
    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      interval(1000).subscribe(() => {
        this.timeCall += 1;
        this.createTransactionSender(this.senderId, this.payPeerSecond);
      });
      if (!this.remoteCalls.length) {
        this.remoteCalls.push(id);
        setTimeout(() => stream.play(id), 1000);
      }
    });
  
    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream && this.remoteCalls.length === 0) {
        // Close local stream only if no other remote streams are present
        this.localStream.close();
      }
      console.log('Remote user offline:', evt.uid); // Log the offline user's UID
      //window.location.href="/#/call-end-page/"+this.room+"/"+this.timeCall;
      this.router.navigate(['/call-end-page', this.room, this.timeCall]).then(() => {
        window.location.reload();
      });
    });
  
    this.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream && this.remoteCalls.length === 0) {
        // Close local stream only if no other remote streams are present
        this.localStream.close();
      }
      console.log('Peer left:', evt.uid); // Log the peer's UID
      //window.location.href="/#/call-end-page/"+this.room+"/"+this.timeCall;
      this.router.navigate(['/call-end-page', this.room, this.timeCall]).then(() => {
        window.location.reload();
      });

      /*this.router.navigate(['/call-end-page', this.room, this.timeCall]).then(() => {
        window.location.reload();
      });*/

    });
  }
  
  
  public handleCallEnd(): void {
    this.router.navigate(['/call-end-page', this.room, this.timeCall]).then(() => {
      window.location.reload();
    });
  }


  

  private assignLocalStreamHandlers(): void {
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
      console.log('accessAllowed');
    });

    // The user has denied access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
      console.log('accessDenied');
    });
  }

  private initLocalStream(onSuccess?: () => any): void {
    this.localStream.init(
      () => {
        // The user has granted access to the camera and mic.
        this.localStream.play(this.localCallId);
        if (onSuccess) {
          onSuccess();
        }
      },
      err => console.error('getUserMedia failed', err)
    );
  }

  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }


  getEnCallRoomData(__room: any) {
    this.callService.getEndRoomData(__room).subscribe((response) => {
      if(response != null){
        this.dataRoomUser = response;
        this.receiverId = response.receiver;
        this.senderId = response.sender;
        this.payPeerSecond = this.callUserType == 'video' ? parseFloat(response.video_call_price) / 60 : parseFloat(response.voice_call_price) / 60;
        //console.log('rooooooooooooom', response, 'paaaaaaaaay', this.payPeerSecond);
      }
    });
  }


  createTransaction(userId: any, totalPayment: any): void {
    this.walletService.createTransaction(userId, totalPayment)
      .subscribe(data => {
        // Handle successful response
        //console.log(data);


      }, error => {
        // Handle error
        console.log(error.message);
      });
  }


  createTransactionSender(userId: any, totalPayment: any): void {
    this.walletService.createTransactionSender(userId, totalPayment)
      .subscribe(data => {
        // Handle successful response
        if(data.error){
          this.router.navigate(['/call-end-page', this.room, this.timeCall]).then(() => {
            window.location.reload();
          });
        }else{
           this.createTransaction(this.receiverId, this.payPeerSecond);
        }
      }, error => {
        // Handle error
        console.log(error.message);
    });
  } 




  //Controls
  // Existing constructor and ngOnInit...

  /*toggleAudio(): void {
    if (this.localStream) {
      this.isAudioMuted = !this.isAudioMuted;
      const audioTrack = this.localStream.getAudioTrack();
      if (audioTrack) {
        audioTrack.enabled(!this.isAudioMuted);
      }
    }
  }

  toggleVideo(): void {
    if (this.localStream) {
      this.isVideoMuted = !this.isVideoMuted;
      const videoTrack = this.localStream.getVideoTrack();  this.localStream.videoTrack.setEnabled(!this.isVideoMuted);
      if (videoTrack) {
        videoTrack.setEnabled();
      }
    }
  }*/

  hangUp() {
    console.log('done close ');
    this.handleCallEnd();
  }



}
