import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Router } from '@angular/router';
import { UserService } from '../apis/users/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad  {
  constructor(private authService: UserService, private router: Router) {

  }

  canActivate(): boolean {
    //console.log('reaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaady guard', this.checkAuth());
    return this.checkAuth();
  }

  canActivateChild(): boolean {
    return this.checkAuth();
  }

  canLoad(): boolean {
    return this.checkAuth();
  }

  private checkAuth(): boolean {

    const payId = localStorage.getItem("pay_id");
    if (payId !== null && payId !== '') {
      return true;
    }else{
      this.router.navigate(['/login']);
      return false;
    }


    /*if (this.authService.isAuthenticatedUser()) {
      return true;
    } else {
      // Redirect to the login page if the user is not authenticated
      this.router.navigate(['/login']);
      return false;
    }*/
  }

}