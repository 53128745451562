/*import { Component, HostListener, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { Router } from '@angular/router';

//categories service
import { CallService } from './services/apis/calls/call.service';
import { UserService } from './services/apis/users/user.service';

import { Subscription, timer, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';


//ringing modal
import { MatDialog } from '@angular/material/dialog';
import { RingingModelComponent } from './modals/ringing-model/ringing-model.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  subscription !: Subscription;
  senderDetails: any = [];
  payCallLanguage: any;

  private sub1$: any;
  private sub2$: any;
  constructor(
    private router: Router,
    public socialAuthServive: SocialAuthService,
    public callService: CallService,
    public userService: UserService,
    private matDialog: MatDialog,
    private renderer: Renderer2
  ) {
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#164969"
        },
        button: {
          background: "#ffe000",
          text: "#164969"
        }
      },
      theme: "classic",
      content: {
        message: "PayCall uses cookies to ensure you get the best experience on our website",
        href: "https://preprod.paycall.co/"
      }
    });

  }





  ngOnInit() {
    this.setUserOnline();
    interval(1000).subscribe(x => {
      this.receiveCallIfExist();
    });

  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.setUserOffline();
    event.returnValue = 'Are you sure you want to leave?';
  }



  async showRingingModal() {

    const dialogRef = this.matDialog.open(RingingModelComponent, {
      disableClose: true,
      panelClass: 'dialog-container-custom',
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'auto',
      width: 'auto',
      data: { senderDetails: this.senderDetails },
    });

  }

  receiveCallIfExist() {
    this.callService.getCallIfExist().subscribe(response => {

      if (response[0].length !== 0) {
        this.senderDetails = response[0];
        this.updateSuccReceived(response[0].id);

      } else {
        return;
      }
    })
  }


  updateSuccReceived(id: any) {

    let status: number = 2;
    this.callService.updateStatus(id, status);
    this.showRingingModal();

  }




  setUserOnline() {

    this.userService.changeStaus(1).subscribe(response => {
      console.log(response);
    })

  }

  setUserOffline() {

    this.userService.changeStaus(0).subscribe(response => {
      console.log(response);
    })

  }




}*/



/*import { Component, HostListener, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { Router } from '@angular/router';

//categories service 
import { CallService } from './services/apis/calls/call.service';
import { UserService } from './services/apis/users/user.service';

import { Subscription, interval } from 'rxjs';

//ringing modal
import { MatDialog } from '@angular/material/dialog'; //dialogue module
import { RingingModelComponent } from './modals/ringing-model/ringing-model.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  senderDetails: any = [];
  payCallLanguage: any;

  constructor(
    private router: Router,
    public socialAuthServive: SocialAuthService,
    public callService: CallService,
    public userService: UserService,
    private matDialog: MatDialog,
    private renderer: Renderer2
  ) { 
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#164969"
        },
        button: {
          background: "#ffe000",
          text: "#164969"
        }
      },
      theme: "classic",
      content: {
        message: "PayCall uses cookies to ensure you get the best experience on our website", 
        href: "https://preprod.paycall.co/" 
      }
    });
  }

  ngOnInit() {
    this.setUserOnline();
    this.subscription = interval(1000).subscribe(() => {
      this.receiveCallIfExist();
    });
  } 

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  receiveCallIfExist() {
    this.callService.getCallIfExist().subscribe(response => {
      console.log('Received call:', response);
      if (response.length > 0) {
        this.senderDetails = response[0];
        this.updateSuccReceived(this.senderDetails.id);
      }
    });
  }

  updateSuccReceived(id: any) {
    let status: number = 2;
    this.callService.updateStatus(id, status);
    this.showRingingModal();
  }

  showRingingModal() {
    const dialogRef = this.matDialog.open(RingingModelComponent, {
      disableClose: true,
      panelClass: 'dialog-container-custom',
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'auto',
      width: 'auto',
      data: { senderDetails: this.senderDetails },
    });
  }

  setUserOnline() {
    this.userService.changeStaus(1).subscribe(response => {
      console.log(response);
    });
  }

  setUserOffline() {
    this.userService.changeStaus(0).subscribe(response => {
      console.log(response);
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.setUserOffline();
  }
} */  


import { Component, HostListener, OnInit, OnDestroy, Renderer2, NgZone } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { Router } from '@angular/router';

//categories service 
import { CallService } from './services/apis/calls/call.service';
import { UserService } from './services/apis/users/user.service';

import { Subscription, interval } from 'rxjs';

//ringing modal
import { MatDialog } from '@angular/material/dialog'; //dialogue module
import { RingingModelComponent } from './modals/ringing-model/ringing-model.component';

//google analytics service
import { AnalyticsService } from './services/helpers/analytics.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  senderDetails: any = [];
  payCallLanguage: any;

  constructor(
    private router: Router,
    public socialAuthServive: SocialAuthService,
    public callService: CallService,
    public userService: UserService,
    private matDialog: MatDialog,
    private renderer: Renderer2,
    private ngZone: NgZone, // Inject NgZone
    private analyticsSrvice: AnalyticsService
  ) { 
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#164969"
        },
        button: {
          background: "#ffe000",
          text: "#164969"
        }
      },
      theme: "classic",
      content: {
        message: "PayCall uses cookies to ensure you get the best experience on our website", 
        href: "https://preprod.paycall.co/" 
      }
    });
  }

  ngOnInit() {
    this.setUserOnline();
    this.subscription = interval(1000).subscribe(() => {
      // Run the code inside NgZone to trigger change detection
      this.ngZone.run(() => {
        this.receiveCallIfExist();
      });
    });

    this.analyticsSrvice.trackEvent('Site Load', 'PayCall Load', 'PayCall')
  } 

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  receiveCallIfExist() {
    this.callService.getCallIfExist().subscribe(response => {
      //console.log('Received call:', response);
      if (response.length > 0) {
        // Run the code inside NgZone to trigger change detection
        this.ngZone.run(() => {
          this.senderDetails = response[0];
          this.updateSuccReceived(this.senderDetails.id);
        });
      }
    });
  }

  updateSuccReceived(id: any) {
    let status: number = 2;
    this.callService.updateStatus(id, status);
    this.showRingingModal();
  }

  showRingingModal() {
    const dialogRef = this.matDialog.open(RingingModelComponent, {
      disableClose: true,
      panelClass: 'dialog-container-custom',
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'auto',
      width: 'auto',
      data: { senderDetails: this.senderDetails },
    });
  }

  setUserOnline() {
    this.userService.changeStaus(1).subscribe(response => {
      //console.log(response);
    });
  }

  setUserOffline() {
    this.userService.changeStaus(0).subscribe(response => {
      //console.log(response);
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    this.setUserOffline();
  }
}
