<div class="login-content-info">
    <div class="container">

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="account-content">
                    
                    <div class="account-info">
                        <div class="login-title pb-1">
                            <h3>PayCall Security Pin</h3>
                            <p class="mb-0">Welcome to early access at PayCall! Please enter the security PIN you received from our team.</p>
                        </div>
                        <form method="get" class="digit-group login-form-control" data-group-name="digits"
                            data-autosubmit="false" autocomplete="off" action="doctor-dashboard.html">
                            <div class="otp-box">
                                <!--<div class="forms-block">
                                    <input type="text" id="digit-1" name="digit-1" data-next="digit-2" maxlength="1">
                                    <input type="text" id="digit-2" name="digit-2" data-next="digit-3"
                                        data-previous="digit-1" maxlength="1">
                                    <input type="text" id="digit-3" name="digit-3" data-next="digit-4"
                                        data-previous="digit-2" maxlength="1">
                                    <input type="text" id="digit-4" name="digit-4" data-next="digit-5"
                                        data-previous="digit-3" maxlength="1">
                                        
                                </div>-->

                                <div class="o-c">
                                    <div class="mb-0">
                                      <div class="card mb-0 border-0">
                                        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent" [config]="config"></ng-otp-input>
                                        <span *ngIf="otp" class="o-t-p d-none">Entered otp :-{{otp}}</span>
                                      </div>
                                    </div>
                                  </div>


                            </div>
                            <div class="forms-block">
                                <div class="otp-info">
                                    <div class="otp-code">
                                        <p>Security pin is private to you don't share or send to anyone .</p>
                                    </div>
                                </div>
                            </div>
                            <div class="reset-btn d-none">
                                <button class="btn w-100" type="submit">Visit Website</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>