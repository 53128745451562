import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { UserService } from "../../../services/apis/users/user.service";

//imports
import { Observable, throwError, ReplaySubject } from "rxjs";
import { retry, catchError } from "rxjs/operators";

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent implements OnInit {

  currentUser: any = [];
  current_user_id: any = localStorage.getItem("pay_user_id");
  base64Output: string =
    "https://png.pngtree.com/png-clipart/20210915/ourmid/pngtree-user-avatar-placeholder-black-png-image_3918427.jpg";

  //social media links constants
  userSocialLinks: any = [];
  socialLinks: any = [];
  showForm:boolean = false;
  selectedLinkId: any;
  SelectedLinkUrl: any;

  constructor(
    private http: HttpClient,
    public UserService: UserService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getUserSocialMediaLinksList();
    this.getSocialMediaLinksList();
    //this.getSingleUser();
  }

  getSingleUser() {
    this.UserService.getSingleUser().subscribe((response) => {
      //console.log('dfgdgdfgfd', response);
      this.currentUser = response;
    });
  }

  

 /**
  * Fetch social media links 
  */
  getSocialMediaLinksList() {
    //Get social  media links list
    this.UserService.fetchSocialMediaLinksList().subscribe((response) => {
      //console.log('dfgdgdfgfd', response);
      this.socialLinks = response;
    });
  }

  /**
  * Fetch user social media links 
  */
   getUserSocialMediaLinksList() {
    //Get social  media links list
    this.UserService.fetchUserSocialMediaLinksList().subscribe((response) => {
      //console.log('done maaan', response);
      this.userSocialLinks = response;
    });
  }


  deleteUserSocialMediaLink(__d_link) {
    //Get social  media links list
    this.UserService.removeLink(__d_link).subscribe((response) => {
      this.ngOnInit();
      this.toastr.success(response.message);
    });
  }



  /**
   * Create new social link for user part 
  */
  showHideAddSocialLinkForm(){
    this.showForm = !this.showForm;
    this.ngOnInit();
  }



  getSelectedLink(event: any) {
    console.log("ionChange fired with value: " + event.target.value);

    const characters = event.target.value.split("-");
    this.selectedLinkId = characters[0];
    this.SelectedLinkUrl = characters[1];
    //console.log(characters[0]);
  }



  submitNewLink(f:NgForm){

    if (!f.valid) {

      return false;
      
    } else {

      //Get saved list of students
      this.UserService.createUserSocialLink(f.value).subscribe((response) => {
        this.showForm = false;
        this.ngOnInit();
        this.toastr.success(response.message);
        //this.currentUser = response;
      })
      
    }



  }



}
