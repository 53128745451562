
			<app-layout-header></app-layout-header>
			<!-- Page Content -->
			<div class="content">
				<div class="container">

					<div class="row">
						<div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
							
							<app-menu-layout></app-menu-layout>
							
						</div>
						
						<div class="col-md-7 col-lg-8 col-xl-9">

							<div class="row">
								<div class="col-md-12">
									<div class="card dash-card">
										<div class="card-body">
											<div class="row">
												
												<div class="col-md-12 col-lg-6">
													<div class="dash-widget dct-border-rht">
														<div class="circle-bar circle-bar2">
															<div class="circle-graph2" data-percent="10">
																<img src="https://cdn-icons-png.flaticon.com/512/755/755718.png" class="img-fluid" alt="Patient" style="height: 51px;">
															</div>
														</div>
														<div class="dash-widget-info">
															<h6>PayCall balance</h6>
															<h3>{{userWallet.sold}}</h3>
															<p class="text-muted">06, Nov 2023</p>
														</div>
													</div>
												</div>
												
												<div class="col-md-12 col-lg-6"> 
													<div class="dash-widget">
														<div class="circle-bar circle-bar3">
															<div class="circle-graph3" data-percent="2">
																<img src="assets/img/icon-03.png" class="img-fluid" alt="Patient">
															</div>
														</div>
														<div class="dash-widget-info">
															<h6>Appoinments</h6>
															<h3>{{notifications.length}}</h3>  
															<p class="text-muted">06, Apr 2023</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="row">
								<div class="col-md-12">
									<h4 class="mb-4">Acount Notifications</h4>
									<div class="appointment-tab">
									
										<!-- Appointment Tab -->
										<ul class="nav nav-tabs nav-tabs-solid nav-tabs-rounded d-none">
											<li class="nav-item">
												<a class="nav-link active" href="#upcoming-appointments" data-bs-toggle="tab">Upcoming</a>
											</li>
											<li class="nav-item">
												<a class="nav-link" href="#today-appointments" data-bs-toggle="tab">Today</a>
											</li> 
										</ul>
										<!-- /Appointment Tab -->
										
										<div class="tab-content">
										
											<!-- Upcoming Appointment Tab -->
											<div class="tab-pane show active" id="upcoming-appointments">
													<div class="card card-table mb-0">
															<div class="card-body">
																<div class="table-responsive">
																	<table class="table table-hover table-center mb-0">
																		<thead>
																			<tr>
																				<th>User Name</th>
																				<th>Appt Date</th>
																				<th width="150">Content</th> 
																				<th>Status</th>
																				<th>Action</th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr *ngFor="let notification of notifications">
																				<td>
																					<h2 class="table-avatar">
																						<a [routerLink]="['/user',notification.sender_id]" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" src="{{notification.thumbnail}}" alt="User Image"></a>
																						<a [routerLink]="['/user',notification.sender_id]">{{notification.full_name}}<span>#{{notification.sender_id}}</span></a>
																					</h2>
																				</td>
																				<td>{{notification.created_at | date:'dd-MM-YYYY'}} <span class="d-block text-info">{{notification.created_at | date:'h:mm'}}</span></td>
																				<td width="150"><p class="l-1">{{notification.content}}</p></td>
																				<td><span
																					class="badge rounded-pill bg-success-light">Status</span></td>
																				<td>
																					<div class="table-action">
																						
																						<a href="javascript:void(0);" class="btn btn-sm bg-success-light">
																							<i class="fa fa-check"></i> Accept
																						</a>
																						<a href="javascript:void(0);" class="btn btn-sm bg-danger-light">
																							<i class="fa fa-times"></i> Reject
																						</a>
																						<a href="javascript:void(0);" class="btn btn-sm bg-info-light">
																								<i class="fa fa-eye"></i> Chat
																							</a>
																					</div>
																				</td>
																			</tr>
																			
																		</tbody>
																	</table>		
																</div>	
															</div>	
														</div>
											</div>
											<!-- /Upcoming Appointment Tab -->
									   
											<!-- Today Appointment Tab -->
											<div class="tab-pane" id="today-appointments">
												<div class="card card-table mb-0">
													<div class="card-body">
														<div class="table-responsive">
															<table class="table table-hover table-center mb-0">
																<thead>
																	<tr>
																		<th>User Name</th>
																		<th>Appt Date</th>
																		<th>Purpose</th>
																		<th>Type</th>
																		<th>Paid Amount</th>
																		<th>Action</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>
																			<h2 class="table-avatar">
																				<a href="patient-profile.html" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" src="assets/img/patients/patient6.jpg" alt="User Image"></a>
																				<a href="patient-profile.html">Elsie Gilley <span>#PT0006</span></a>
																			</h2>
																		</td>
																		<td>14 Nov 2023 <span class="d-block text-info">6.00 PM</span></td>
																		<td>Fever</td>
																		<td>Old Patient</td>
																		<td>$300</td>
																		<td>
																			<div class="table-action">
																				<a href="javascript:void(0);" class="btn btn-sm bg-info-light">
																					<i class="far fa-eye"></i> View
																				</a>
																				
																				<a href="javascript:void(0);" class="btn btn-sm bg-success-light">
																					<i class="fas fa-check"></i> Accept
																				</a>
																				<a href="javascript:void(0);" class="btn btn-sm bg-danger-light">
																					<i class="fas fa-times"></i> Cancel
																				</a>
																			</div>
																		</td>
																	</tr>
																	
																	<tr>
																		<td>
																			<h2 class="table-avatar">
																				<a href="patient-profile.html" class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" src="assets/img/patients/patient11.jpg" alt="User Image"></a>
																				<a href="patient-profile.html">Harry Williams <span>#PT0011</span></a>
																			</h2>
																		</td>
																		<td>14 Nov 2023 <span class="d-block text-info">11.00 AM</span></td>
																		<td>General</td>
																		<td>New Patient</td>
																		<td>$450</td>
																		<td>
																			<div class="table-action">
																				<a href="javascript:void(0);" class="btn btn-sm bg-info-light">
																					<i class="far fa-eye"></i> View
																				</a>
																				
																				<a href="javascript:void(0);" class="btn btn-sm bg-success-light">
																					<i class="fas fa-check"></i> Accept
																				</a>
																				<a href="javascript:void(0);" class="btn btn-sm bg-danger-light">
																					<i class="fas fa-times"></i> Cancel
																				</a>
																			</div>
																		</td>
																	</tr>
																</tbody>
															</table>		
														</div>	
													</div>	
												</div>	
											</div>
											<!-- /Today Appointment Tab -->
											
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>

				</div>

			</div>		
			<!-- /Page Content -->
   
			