import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-chat-right',
  templateUrl: './chat-right.component.html',
  styleUrls: ['./chat-right.component.scss']
})
export class ChatRightComponent implements OnInit {

  @Input() ConversationMessages:any;
  @Input() ConversationUser:any;


  constructor() { }

  ngOnInit(): void {
  }

}
     
