import { Component, ElementRef, Renderer2, ViewChild, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Route } from '@angular/router';

//chat service 
import { ChatService } from '../../services/apis/chat/chat.service';

@Component({
  selector: 'app-conversation-popup',
  templateUrl: './conversation-popup.component.html',
  styleUrls: ['./conversation-popup.component.scss']
})
export class ConversationPopupComponent implements OnInit {

  current_user:any = localStorage.getItem('pay_id');
  form!: FormGroup;

  charsAllowed = 150;
  charsUsed = 0;

  messListe:any = [];

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ConversationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private http: HttpClient,
    private router: Router,
    public chatService: ChatService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {

    this.profileMessagesHistory();

     //send message form
     this.form = new FormGroup({
      sender_id: new FormControl(this.current_user),
      receiver_id: new FormControl(this.data.user.pay_call_id),
      message: new FormControl(null, {validators: [Validators.required]}),
    });

    

  }



  profileMessagesHistory() {

    this.chatService.getMessagesHistoryByProfile(this.data.user.pay_call_id).subscribe(response => {
      this.messListe = response.messages;
      console.log('wtfffffffffffffff', this.messListe);
    })

  }

   
  startConversationToUser(){

    console.log(this.form.value);
    
    this.http.post("https://preprod.paycall.co/index.php/api/chat/conversation/new-conversation-to-user", this.form.value)
      .subscribe(data => {
        //this.dialogRef.close();
        //this.router.navigate(['/chat'])
        //console.log('wtffffffffffffffffffffffffffffffffffffffffffffffffff', data);
       // this.messListe.push(this.form.value.message);
       this.profileMessagesHistory();
       //this.scrollToBottom();
       }, error => {
        console.log(error);
      });

  }


  getFirst(speciality:any){
    if(speciality){
      let data = JSON.parse(speciality);
      return data[0];
    }
  
  }


  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }
  }

  
  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy()
  {
    this.dialogRef.close();
  }

}
