import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-available-timings',
  templateUrl: './available-timings.component.html',
  styleUrls: ['./available-timings.component.scss']
})
export class AvailableTimingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
