<app-layout-header></app-layout-header>


<!-- Breadcrumb -->
<div class="breadcrumb-bar-two">
    <div class="container">
        <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
                <h2 class="breadcrumb-title">Refund Policy</h2>
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                        <li class="breadcrumb-item" aria-current="page">Refund Policy</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- /Breadcrumb -->


	<!-- Privacy Policy -->
    <div class="terms-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="terms-content pb-0">
                        <p><strong>Refund Policy </strong></p>
                        <p>&nbsp;</p>
                        <ol>
                        <li><strong>Refund Conditions</strong></li>
                        </ol>
                        <p>Customers are eligible for a refund for their digital credit if they submit a written request to <a href="mailto:contact@paycall.co"><strong>contact@paycall.co</strong></a> within 14 days of the purchase date, and the credit remains unused.</p>
                        <p>&nbsp;</p>
                        <ol start="2">
                        <li><strong>Refund Method</strong></li>
                        </ol>
                        <p>Refunds for digital credit will be issued to the same payment method used for the original purchase.</p>
                        <p>&nbsp;</p>
                        <ol start="3">
                        <li><strong>Exceptions </strong></li>
                        </ol>
                        <p>Please note that refunds for digital credit may not be possible if the credit has already been utilized or if specific usage conditions are not met. These exceptions will be clearly outlined in the terms of use for the digital credit or corresponding contractual agreements.</p>
                        <p>&nbsp;</p>
                        <ol start="4">
                        <li><strong>Processing Time</strong></li>
                        </ol>
                        <p>The processing of refunds for digital credit may take up to 14 business days from the receipt of the request.</p>
                        <p>&nbsp;</p>
                        <ol start="5">
                        <li><strong>Contact</strong></li>
                        </ol>
                        <p>For refund inquiries or further assistance, please contact our customer service at <a href="mailto:contact@paycall.co"><strong>contact@paycall.co</strong></a></p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Privacy Policy -->	


<app-layout-footer></app-layout-footer>