import { Component, AfterViewChecked, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

//chat service 
import { ChatService } from '../../services/apis/chat/chat.service';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';

//voice message declaration requirements
import Recorder from 'recorder-js';

import { Observable, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';

declare var MediaRecorder: any;
const httpOptions = {
  headers: new HttpHeaders({
    responseType: 'blob as json',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

declare var $: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  @ViewChild('chatConvContent') private myScrollContainer: ElementRef;


  conversations:any = [];
  ConversationMessages:any = [];
  ConversationUser:any = [];
  conversation:any = [];
  lastMessage:any = [];
  current_user:any = localStorage.getItem('pay_id');
  aff_type:string = 'test_message';

  form!: FormGroup;

  inputMess:string;

  //voice recording part
  isRecording:boolean = false;
  blobFile:any;
  recordAudio:any;
  /*sendObj = {
    audio: 'this.blobFile',
  };*/
  sendObjOdio:any
  audioContext = new AudioContext({ sampleRate: 16000 });
  recorder = new Recorder(this.audioContext, {});


  private pollingInterval = 1000; // Polling interval in milliseconds (e.g., 5000ms = 5 seconds)


  constructor(
    public chatService: ChatService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    
   }
 
  ngOnInit(): void {

    this.getMyConversations();
    this.initialiseRecordingStream();


     // Start fetching messages periodically
     interval(this.pollingInterval).pipe(
      switchMap(() => this.getNewMessages())
    ).subscribe((messages) => {
      // Handle incoming messages and update UI
      console.log('Received messages:', messages);
      this.conversations = messages;
      // Example: Update ConversationMessages or other data arrays
    });

    
  }

  ngAfterViewInit() {
    var chatAppTarget = $('.chat-window');
	(function () {
		if ($(window).width() > 991)
			chatAppTarget.removeClass('chat-slide');

		$(document).on("click", ".chat-window .chat-users-list a.open-chat", function () {
			if ($(window).width() <= 991) {
				chatAppTarget.addClass('chat-slide');
			}
			return false;
		});
		$(document).on("click", "#back_user_list", function () {
			if ($(window).width() <= 991) {
				chatAppTarget.removeClass('chat-slide');
			}
			return false;
		});
	})();
  }


  getMyConversations() {
    this.chatService.getConversations().subscribe(response => {
      this.conversations = response;
    })
  }


  private getNewMessages(): Observable<any[]> {
    // Fetch new messages from the server
    // Example URL, replace with your actual endpoint
    return this.http.get<any[]>(`https://preprod.paycall.co/index.php/api/chat/${this.current_user}`);
  }


  showSingleChat(__conversation:any) {

    this.scrollToBottom();

    this.chatService.getConversationMessages(__conversation.conversation.id).subscribe(response => {
      this.ConversationMessages = response;
      console.log('messages', response);
      this.ConversationUser = __conversation.user;
      this.conversation = __conversation.conversation;
      this.getConvLastMessage(this.conversation.id);
      this.scrollToBottom();


      //send message form
     this.form = new FormGroup({
      conversation_id: new FormControl(this.conversation.id),
      message: new FormControl(null, {validators: [Validators.required]}),
      sender_id: new FormControl(this.current_user),
      receiver_id: new FormControl(this.ConversationUser.pay_call_id), 
      message_type: new FormControl(this.lastMessage.sender_id != this.current_user ? 'replay' : 'message'),
      aff_type: new FormControl(this.aff_type),

    });


    })

  }

  getConvLastMessage(convId){
    //Get list of current user conversations
    this.chatService.getConversationLastMessage(convId).subscribe(response => {
      this.lastMessage = response;
    })
}


    //send message method done ;) 
    sendMessage(){

      console.log(this.form.value);

        this.http.post("https://preprod.paycall.co/index.php/api/chat/conversation/new-message", this.form.value)
        .subscribe(data => {  

          if(data['success'] == true){

            this.chatService.getConversationLastMessage(this.conversation.id).subscribe(response => {
              this.ConversationMessages.push( response);
            });
            
          }else{

            alert('no balnce ');

          }

          //this.form.value.message = new FormControl(''),
          //this.form.value.message = new FormControl(''),
          this.inputMess = '';
          this.scrollToBottom();
          this.ngOnInit();
         }, error => {
          console.log(error.message);
        });
     
    }

    scrollToBottom(): void {
      try {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight + 240;
      } catch(err) { }                 
  }




  //voice messages part
  
 


    async initialiseRecordingStream()
    {
      this.recordAudio = () => {
        return new Promise((resolve) => {
          navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            const mediaRecorder = new MediaRecorder(stream, {
              mimeType: 'audio/webm',
              numberOfAudioChannels: 1,
              audioBitsPerSecond: 16000,
            });
            const audioChunks = [];
  
            mediaRecorder.addEventListener('dataavailable', (event) => {
              audioChunks.push(event.data);
            });
  
            const start = () => {
              mediaRecorder.start();
            };
  
            const stop = () => {
              return new Promise((resolve) => {
                mediaRecorder.addEventListener('stop', () => {
                  const audioBlob = new Blob(audioChunks, {
                    type: 'audio/wav',
                  });
                  const reader = new FileReader();
                  reader.readAsDataURL(audioBlob);
                  reader.addEventListener(
                    'load',
                    () => {
                      const base64data = reader.result;
                      //this.sendObj.audio = base64data;
                      this.sendObjOdio = base64data;
                      this.form.value.message = base64data;
                      this.form.value.aff_type = 'voice_message';
                      this.aff_type = 'voice_message';
                      this.sendMessage();
                      this.isRecording = !this.isRecording;
                      mediaRecorder.stop();
                      //console.log(this.sanitizer.bypassSecurityTrustUrl(this.sendObjOdio));
                      // this.http.post('apiUrl', this.sendObj, httpOptions).subscribe(data => console.log(data));
                    },
                    false
                  );
                  const audioUrl = URL.createObjectURL(audioBlob);
                  //console.log('Audiourl', audioUrl);
                  const audio = new Audio(audioUrl);
                  const play = () => {
                    audio.play();
                  };
                  resolve({ audioBlob, audioUrl, play });
                });
  
                mediaRecorder.stop();
              });
            };
            resolve({ start, stop });
          });
        });
      };
    }


    async recordStream()
    { 
      this.isRecording = !this.isRecording;
      this.recorder = await this.recordAudio();
      this.recorder.start();
    }
    
  
    async stopRecording() {
      const audio = await this.recorder.stop();
      this.initialiseRecordingStream();
      //audio.play();
    }


    getSantizeUrl(url : string) {
      return this.sanitizer.bypassSecurityTrustUrl(url);
  }


}
