import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators'; 

@Injectable({
  providedIn: 'root'
})
export class FavorieService {

    // API path
    base_path = 'https://preprod.paycall.co/index.php/api/favorites';
    black_liste_base_path = 'https://preprod.paycall.co/index.php/api/black-liste';
    current_user = localStorage.getItem('pay_id');
 
    constructor(private http: HttpClient) { }
  
  
    // Handle API errors
    handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError(
        'Something bad happened; please try again later.');
    };
  
  
    // Get students data
     getSingleUserFavory(): Observable<any> {
       return this.http
         .get<any>(this.base_path +'/'+this.current_user)
         .pipe(
           retry(2),
           catchError(this.handleError)
         )
     }

     addUserToBookmark(user_id:any): Observable<any> {

      return this.http
        .get<any>(this.base_path +'/save-bookmark/'+this.current_user+'/'+user_id);
    }


    deleteBookmark(faId:any): Observable<any> {

      return this.http
        .get<any>(this.base_path +'/delete-bookmark/'+faId);
    }


    // Get students data
    getSingleUserBlackListe(): Observable<any> {
      return this.http
        .get<any>(this.black_liste_base_path +'/'+this.current_user)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    addUserToBlackListe(user_id:any): Observable<any> {

      return this.http
        .get<any>(this.black_liste_base_path +'/block-member/'+this.current_user+'/'+user_id);
    }
  
    
  
}
