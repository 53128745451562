import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

//tags input 
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { retry, catchError, map, startWith } from 'rxjs/operators';
import { UserService } from '../../services/apis/users/user.service';
import { SearchService } from '../../services/apis/search/search.service';
import { CategoryService } from '../../services/apis/categories/category.service';
import { Router, ActivatedRoute, Route } from "@angular/router";



@Component({
  selector: 'app-filter-v2',
  templateUrl: './filter-v2.component.html',
  styleUrls: ['./filter-v2.component.scss']
})
export class FilterV2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
