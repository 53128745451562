<!-- Profile Sidebar --> 
<div class="profile-sidebar">
    <div class="widget-profile pro-widget-content">
        <div class="profile-info-widget">
            <a [routerLink]="['/user',currentUser.pay_call_id]" class="booking-doc-img"> 
                <img src="{{currentUser.thumbnail}}" alt="{{currentUser.full_name}} - Thumbnail ">
            </a>
            <div class="profile-det-info"> 
                <h3>{{currentUser.full_name}}</h3>
                
                <div class="patient-details d-none">
                    <h5 class="mb-0">Speciality</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="dashboard-widget">
        <nav class="dashboard-menu">
            <ul>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a routerLink="/dashboard">
                        <i class="fa fa-home"></i>
                        <span>Dashboard</span>
                    </a>
                </li>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a routerLink="/chat">
                        <i class="fa">	&#xf075;</i>
                        <span>Messages</span>
                        <small class="unread-msg">23</small>
                    </a>
                </li>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a routerLink="/dashboard/calls">
                        <i class="fa fa-history"></i>
                        <span>Phone History</span>
                    </a>
                </li>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a routerLink="/dashboard/favorites">
                        <i class="fa fa-heart"></i>
                        <span>Favorites</span>
                    </a>
                </li>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a routerLink="/dashboard/wallet">
                        <i class="fa fa-credit-card"></i>
                        <span>Wallet</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);">
                        <i class="fa fa-calendar"></i>
                        <span>Calendar</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);">
                        <i class="fa fa-clock-o"></i>
                        <span>Available Timings</span>
                    </a>
                </li>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a routerLink="/dashboard/settings">
                            <i class="fa fa-cog"></i>
                            <span>Profile Settings</span>
                        </a>
                    </li>
                    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <a routerLink="/dashboard/privacy">
                                <i class="fa fa-lock"></i>
                                <span>Privacy</span>
                            </a>
                        </li>
                <li>
                        <a routerLink="/dashboard/social-media-links">
                            <i class="fa fa-share"></i>
                            <span>Social Media</span>
                        </a>
                    </li>
                
                
                <li>
                        <a href="javascript:void(0);">
                            <i class="fa fa-star"></i>
                            <span>Reviews</span>
                        </a>
                    </li>
                <li>
                    <a href="javascript:void(0);" (click)="logout()">
                        <i class="fa fa-sign-out"></i>
                        <span>Logout</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</div>
<!-- /Profile Sidebar -->