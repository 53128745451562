import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-success-withdraw',
  templateUrl: './success-withdraw.component.html',
  styleUrls: ['./success-withdraw.component.scss']
})
export class SuccessWithdrawComponent implements OnInit {

  public current_date=new Date();

  constructor() { }

  ngOnInit(): void {
  }

}
