<!-- Footer -->
<footer class="footer footer-one">
		<div class="footer-top" style="    padding: 25px 0px 0 !important;">
			<div class="container">
				<div class="row">
					<div class="col-lg-3 col-md-4">
						<div class="footer-widget footer-about">
							<div class="footer-logo">
								<a routerLink="/">
								 
										<img src="../../../assets/logo-footer.png" class="img-fluid" alt="Logo" style="    height: 25vh;">

								</a>
							</div>
							
						</div>
					</div>
					<div class="col-lg-6">
						<div class="row">
							<div class="col-lg-3 col-md-4">
								<div class="footer-widget footer-menu">
									<h2 class="footer-title">Explore</h2>
									<ul>
										<li><a href="javascript:void(0);">Search</a></li>
										<li><a href="javascript:void(0);">Login</a></li>
										<li><a href="javascript:void(0);">Register</a></li>
									</ul>
								</div>
							</div>
							<div class="col-lg-3 col-md-4">
								<div class="footer-widget footer-menu">
									<h2 class="footer-title">For Users</h2>
									<ul>
										<li><a href="javascript:void(0);">Appointments</a></li>
										<li><a href="javascript:void(0);">Chat</a></li>
										<li><a href="javascript:void(0);">Login</a></li>
									</ul>
								</div>
							</div>
							<div class="col-lg-6 col-md-4">
								<div class="footer-widget footer-contact">
									<h2 class="footer-title">Contact Us</h2>
									<div class="footer-contact-info">
										<div class="footer-address">
											<p><i class="feather-map-pin"></i> 9100 Herisau, Switzerland</p>
										</div>
										<div class="footer-address">
											<p><i class="feather-phone-call"></i> PayCall Hotline 100.100.100</p>
										</div>
										<div class="footer-address mb-0">
											<p routerLink="/contact-us" style="cursor: pointer;"><i class="feather-mail"></i> Contact</p>
										</div>
									</div>
								</div>
							</div> 
						</div>
					</div>
					<div class="col-lg-3 col-md-7">
						<div class="footer-widget">
							<h2 class="footer-title">Join Our Newsletter</h2>
							<div class="subscribe-form">
								<form action="#">
									<input type="email" class="form-control" placeholder="Enter Email">
									<button type="submit" class="btn">Submit</button>
								</form>
							</div>
							<div class="social-icon">
							<ul>
								<li>
									<a href="https://www.tiktok.com/@.paycall" target="_blank">
											<svg style="fill:#143CCC;" width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xml:space="preserve"><path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z"/></svg>
									</a>
								</li>
								<li>
									<a href="https://www.facebook.com/paycallapp" target="_blank" style="color: #143CCC;"><i class="fa fa-facebook"></i></a>
								</li>
								<li>
									<a href="https://www.instagram.com/_paycall" target="_blank" style="color: #143CCC;"><i class="fa fa-instagram"></i></a>
								</li>
								<li>
									<a href="https://twitter.com/_paycall" target="_blank" style="color: #143CCC;"><i class="fa fa-twitter"></i></a>
								</li>
								<li>
									<a href="https://www.linkedin.com/company/paycallapp" target="_blank" style="color: #143CCC;"><i class="fa fa-linkedin"></i></a>
								</li>
							</ul>
						</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			<div class="container">
				<!-- Copyright -->
				<div class="copyright" style="border-top: 1px solid #143CCC !important;">
					<div class="row">
						<div class="col-md-6 col-lg-6">
							<div class="copyright-text">
								<p class="mb-0"> Copyright © 2024 Paycall, Inh. Ayari .All Rights Reserved</p>
							</div>
						</div>
						<div class="col-md-6 col-lg-6">
						
							<!-- Copyright Menu -->
							<div class="copyright-menu">
								<ul class="policy-menu">
									<li><a routerLink="/policy">Cookie Policy</a></li>
									<!--<li><a routerLink="/contact-us">Contact Us</a></li>-->
									<li><a routerLink="/policy">Privacy Policy</a></li>
									<li><a routerLink="/refund-policy">Refund Policy</a></li>
									<li><a routerLink="/term-and-conditions">Terms and Conditions</a></li>
									<li><a routerLink="/faq" style="color: #143CCC;">FAQ</a></li>
								</ul>
							</div>
							<!-- /Copyright Menu -->
							
						</div>
					</div>
				</div>
				<!-- /Copyright -->					
			</div>
		</div>
	</footer>
	<!-- /Footer -->