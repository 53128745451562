import { Component, OnInit, ElementRef, ViewChild, AfterViewInit  } from '@angular/core';
import {Router} from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {NgForm} from '@angular/forms';
//imports 
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
//categories service 
import { CategoryService } from '../../../services/apis/categories/category.service';
import { UserService } from '../../../services/apis/users/user.service';


import { CountryService } from '../../../services/geolocation/country.service';


//tags input 
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {map, startWith} from 'rxjs/operators';

declare var $: any;
declare let ClassicEditor: any;

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit {


  
@ViewChild('aboutMeTextArea') aboutMeTextArea: ElementRef;

/*currentUser = {
  about_me: '' // Initialize or assign this based on your application logic
};*/


  categories:any = []; //categories table
  specialitiesListe:any;
  lanListe:any;
  currentUser:any = [];
  current_user_id:any = localStorage.getItem('pay_user_id');
  base64Output : string;
  mediaBase64Output: string = '';
  mediaPictures: any = [];
  files: File[] = [];  
  mediaBase64OutputSelected: boolean = false;

  mediaSingleFile:any;


  //tags& inputs 
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  allFruits: string[] = [];

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;


  toppings = new FormControl('');

  toppingList: string[] = ["Afrikaans",
  "Arabic",
  "Bengali",
  "Bulgarian",
  "Catalan",
  "Cantonese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Lithuanian",
  "Malay",
  "Malayalam",
  "Panjabi",
  "Tamil",
  "English",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Javanese",
  "Korean",
  "Norwegian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovene",
  "Spanish",
  "Swedish",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
  "Welsh",
  "Sign language",
  "Algerian",
  "Aramaic",
  "Armenian",
  "Berber",
  "Burmese",
  "Bosnian",
  "Brazilian",
  "Bulgarian",
  "Cypriot",
  "Corsica",
  "Creole",
  "Scottish",
  "Egyptian",
  "Esperanto",
  "Estonian",
  "Finn",
  "Flemish",
  "Georgian",
  "Hawaiian",
  "Indonesian",
  "Inuit",
  "Irish",
  "Icelandic",
  "Latin",
  "Mandarin",
  "Nepalese",
  "Sanskrit",
  "Tagalog",
  "Tahitian",
  "Tibetan",
  "Gypsy",
  "Wu"];
  languagesSelected: string[] = [];

  countries = new FormControl('');
  selectedCountry: string;
  //countries
  countriesListe: string[] = ['Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'The Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of the',
  'Congo, Republic of the',
  'Costa Rica',
  'Côte d’Ivoire',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor (Timor-Leste)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'The Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Sudan, South',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe'];


  //ipAdresse
  ipAddress:any;


  aboutMeSection:string = ' ';

 
  constructor(private router: Router,
    public categoryService: CategoryService,
    public UserService: UserService,
    private http:HttpClient,
    private toastr: ToastrService,
    private ip:CountryService
    ) {

      this.filteredFruits = this.fruitCtrl.valueChanges.pipe( startWith(null), map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));
    }


ngOnInit(): void {}



ngAfterViewInit() {

  this.getSingleUser();
  this.getUserMediaPictures();
  this.getAllTags();
  this.getIP();  
}



 
getAllTags() {
  //Get list of categories
  this.categoryService.getSubCategoriesTags().subscribe(response => {

    //this.allFruits = response;
    response.forEach(element => {
      this.allFruits.push(element.tag_label);
    });
    //console.log('all tags', this.allFruits);
  })
}



getSingleUser() {
  //Get single user data
  this.UserService.getSingleUser().subscribe(response => {
    //console.log('ljfljsdlfjsdfjd',response)
    this.currentUser = response;
    this.fruits = JSON.parse(response.speciality);
    this.toppings.setValue(JSON.parse(response.profilelanguages));
    //this.aboutMeSection = response.about_me;
    

  ClassicEditor
  .create(this.aboutMeTextArea.nativeElement, {
    removePlugins: ['CKFinderUploadAdapter', 'CKFinder']
  })
  .then(editor => {
    editor.setData(response.about_me); // Set initial data from currentUser.about_me

    editor.model.document.on('change:data', () => {
      const data = editor.getData();
      this.aboutMeSection = data; // Update currentUser.about_me on change
      //console.log(data); // Log the editor content
    });
  })
  .catch(error => {
    console.error('Error initializing CKEditor:', error);
  });
  })
}



/**
 * Edit profile picture
 */
onFileSelected(event:any) {
  this.convertFile(event.target.files[0]).subscribe(base64 => {
    this.base64Output = 'data:image/jpeg;base64,'+base64;

    const fd = new FormData();
    fd.append('thumbnail',event.target.files[0]);

    this.http.post("https://preprod.paycall.co/index.php/api/user/edit-picture/"+this.current_user_id, fd)
    .subscribe(data => {
      this.ngOnInit();
      this.toastr.success('Your profile picture have been saved.');
      //console.log(data);
     }, error => {
      console.log(error);
    });

  });
}

convertFile(file : File) : Observable<string> {
  const result = new ReplaySubject<string>(1);
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onload = (event:any) => result.next(btoa(event.target.result.toString()));
  return result;
}

/**
 * Edit profile details
 */

specChange(e){

  this.specialitiesListe = e.target.value;
  
}

profileLanguageHandler(event){

  this.lanListe = event.target.value;
  
}


submitProfile(f: NgForm) {

 
  f.value.profilelanguages = this.toppings.value;
  f.value.speciality = this.fruits;
  f.value.about_me = this.aboutMeSection;

  //console.log('form', f.value);
  //return;

  this.http.post("https://preprod.paycall.co/index.php/api/user/edit/"+this.current_user_id, f.value)
    .subscribe(data => {
      this.ngOnInit();
      this.toastr.success('Your profile details have been saved.');
      //console.log(data);
     }, error => {
      console.log(error);
    });

}



/**
  * Fetch media pictures liste 
**/
 getUserMediaPictures() {
  //Get social  media links list
  this.UserService.getUserMediaPictures(this.current_user_id).subscribe((response) => {
    //console.log('dfgdgdfgfd', response);
    this.mediaPictures = response;
  });
}


onMediaFileSelected(event:any) {
  this.convertMediaFile(event.target.files[0]).subscribe(base64 => {
    this.mediaBase64Output = 'data:image/jpeg;base64,'+base64;
    this.mediaSingleFile = event.target.files[0];
    this.mediaBase64OutputSelected = true;
  });
}

convertMediaFile(file : File) : Observable<string> {
  const result = new ReplaySubject<string>(1);
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onload = (event:any) => result.next(btoa(event.target.result.toString()));
  return result;
}



saveMediaPicture()
{
  console.log('ts clicked');
  //create new form data 
  const __formData: FormData = new FormData();
  __formData.append('picture', this.mediaSingleFile);

  //Get saved list of students
  this.UserService.addMediaPicture(__formData).subscribe((response) => {
    this.ngOnInit();
  })
}


cancelPicture()
{
  this.mediaBase64Output = '';
}



deleteUserMediaPictures(medId:any) {
  //Get social  media links list
  this.UserService.deleteSingleMediaPicture(medId).subscribe((response) => {
    this.ngOnInit();
    this.toastr.success('Media picture successfuly deleted.');
  });
}





/**
 * Tags inputs 
 */
add(event: MatChipInputEvent): void {
  const input = event.input;
  const value = event.value;

  if(this.fruits.length >= 5)
  {
    return;
  }
  // Add our fruit
  if ((value || '').trim()) {
    this.fruits.push(value.trim());
  }
  console.log('selectedValTags', this.fruits);
  // Reset the input value
  if (input) {
    input.value = '';
  }
  this.fruitCtrl.setValue(null);
}
remove(fruit: string): void {
  const index = this.fruits.indexOf(fruit);
  if (index >= 0) {
    this.fruits.splice(index, 1);
  }
}
selected(event: MatAutocompleteSelectedEvent): void {
  this.fruits.push(event.option.viewValue);
  this.fruitInput.nativeElement.value = '';
  this.fruitCtrl.setValue(null);
}
private _filter(value: string): string[] {
  const filterValue = value.toLowerCase();
  return this.allFruits.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
}


/**
 * Change pricing form
 */


  submitPayementChange(f: NgForm) {


    //console.log(f.value);

    this.http.post("https://preprod.paycall.co/index.php/api/user/edit-pricing/"+this.current_user_id, f.value)
      .subscribe(data => {
        this.ngOnInit();
        this.toastr.success('Pricing successfuly changed.');
        //console.log(data);
       }, error => {
        console.log(error);
      });

  }


  onOffPricing(event:any, column_name){
    console.log('pricing event ;)', event.target.checked);
    const __formData: FormData = new FormData();
  
    if(event.target.checked == true){

      __formData.append('column_name', column_name);
      __formData.append('value', '1');
      this.http.post("https://preprod.paycall.co/index.php/api/user/edit-pricing-on-off/"+this.current_user_id, __formData)
      .subscribe(data => {
        console.log(data);
        this.ngOnInit();
        this.toastr.success('successfuly changed');
       }, error => {
        console.log(error);
      });
      
    }else{

      __formData.append('column_name', column_name);
      __formData.append('value', '0');
      this.http.post("https://preprod.paycall.co/index.php/api/user/edit-pricing-on-off/"+this.current_user_id, __formData)
      .subscribe(data => {
        console.log('readddddddddddddddddddddy', data);
        this.ngOnInit();
        this.toastr.success('successfuly changed');
       }, error => {
        console.log(error);
      });

    }
  }



  getIP()  
  {  
    this.ip.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
    });  
  }



}
