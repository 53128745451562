import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PlisioService {
  
  
  // API path
  base_path = "https://preprod.paycall.co/index.php/api/wallet/payment/";

  isLoggedIn: boolean = false;

  constructor(
    private http: HttpClient,
  ) {}

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`,
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }


  /**
   * add new link for current user 
  **/
 createInvoice(__form: any) {
  return this.http.post<any>(this.base_path + '/create-invoice', __form)
  .pipe(
    catchError(this.handleError)
  );
}

getOperationDetails(__form: any) {
  return this.http.post<any>(this.base_path + '/get-operation-details', __form)
  .pipe(
    catchError(this.handleError)
  );
}



}
