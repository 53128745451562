import { Directive, Input, HostListener, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[appCopyToClipboard]'
})
export class CopyToClipboardDirective {

  @Input('appCopyToClipboard') textToCopy: string;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  @HostListener('click')
  onClick() {
    this.copyToClipboard();
    this.showCopiedMessage();
  }

  copyToClipboard() {
    const el = document.createElement('textarea');
    el.value = this.textToCopy;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  showCopiedMessage() {
    const message = this.renderer.createElement('span');
    const text = this.renderer.createText('Copied ! ');
    this.renderer.appendChild(message, text);
    this.renderer.appendChild(document.body, message);

    this.renderer.setStyle(message, 'position', 'fixed');
    this.renderer.setStyle(message, 'bottom', '20px');
    this.renderer.setStyle(message, 'left', '50%');
    this.renderer.setStyle(message, 'transform', 'translateX(-50%)');
    this.renderer.setStyle(message, 'background', '#333');
    this.renderer.setStyle(message, 'color', '#fff');
    this.renderer.setStyle(message, 'padding', '10px');
    this.renderer.setStyle(message, 'border-radius', '5px');
    this.renderer.setStyle(message, 'z-index', '1000');

    setTimeout(() => {
      this.renderer.removeChild(document.body, message);
    }, 2000);
  }
}
