import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  // API path
  /*base_path = 'http://localhost:8080/api/category';*/
    base_path = 'https://preprod.paycall.co/index.php/api/category';

  constructor(private http: HttpClient) { }


  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


  // Get students data
  getList(): Observable<any> {
    return this.http
      .get<any>(this.base_path)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
  }

    // Get subcategories list
    getSubCategoriesList(catId:any): Observable<any> {
      return this.http
        .get<any>(this.base_path +'/'+ catId)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }


    // Get subcategories list
    getSubCategoriesTags(): Observable<any> {
      return this.http
        .get<any>(this.base_path +'/tags')
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

  

}
