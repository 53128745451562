import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/apis/users/user.service';

@Component({
  selector: 'app-menu-layout',
  templateUrl: './menu-layout.component.html',
  styleUrls: ['./menu-layout.component.scss']
})
export class MenuLayoutComponent implements OnInit {

  currentUser:any = [];

  constructor(
    public UserService: UserService,
  ) { }

  ngOnInit(): void {

    this.getSingleUser();

  }


  getSingleUser() {
    //Get saved list of students
    this.UserService.getSingleUser().subscribe(response => {
      console.log('ljfljsdlfjsdfjd',response)
      this.currentUser = response;
    })
  }

  logout(): void {

    localStorage.removeItem('pay_id');
    localStorage.removeItem('pay_user_id');
    localStorage.removeItem('isLoggedIn');
   window.location.href="/";



/*this.socialAuthServive.signOut().then(() =>  
{
localStorage.setItem('isLoggedIn', 'false');
window.location.href="/";
}
 
);*/
}

}
