import { Component, OnInit } from '@angular/core';
import {GoogleLoginProvider, FacebookLoginProvider, SocialAuthService} from 'angularx-social-login';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';


//auth service
import { AuthService } from '../../../services/auth/auth.service';



import { MatDialog } from '@angular/material/dialog'; //dialogue module
import { SocialMediaLoginComponent } from '../../../modals/social-media-login/social-media-login.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup;
  loader:boolean = false;

  //input phone config 
  separateDialCode = true;

  constructor(
    private router: Router,
    private socialAuthService: SocialAuthService,
    private _location: Location,
    private auth: AuthService,
    private matDialog: MatDialog) {
}

  ngOnInit(): void {

    this.loginForm = new FormGroup({
      countryCode: new FormControl('1', {
        validators: [Validators.required]
      }),
      phone: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(6)]
      }),
    });

  }


  async phoneAuth() {

    //console.log(this.loginForm.value.phone);

    this.loader = true;
    //let phone = this.loginForm.value.countryCode+this.loginForm.value.phone;
    let phone = this.loginForm.value.phone.e164Number.substring(1);
    let otp = Math.floor(100000 + Math.random() * 900000);

    this.auth.sendUserOtp(phone, 'Your PayCall verification code :'+otp).subscribe(response => {

      //console.log(response);
      localStorage.setItem('tempPhpne', this.loginForm.value.phone.number.replace(/\s/g, ""));
      localStorage.setItem('tempCountry', this.loginForm.value.phone.dialCode.substring(1));
      localStorage.setItem('tempAuthCode', JSON.stringify(otp));
      this.router.navigate(['/confirm-code']);

      
    })
  
}
  
  loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
    .then(result => {
      console.log('user datas ', result);
      this.checkAvailability(result);
    }
        
    );
    
  }


  loginWithFacebook (){

    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      (userData) => {
        localStorage.setItem('isLoggedIn', 'true');
        console.log(userData);
      }
    );
  }


  async checkAvailability(userData:any) {
    /*const modal = await this.modalController.create({
      component: ConfirmLoginPage,
      componentProps: {
        userData: userData,
      },
    });
    return await modal.present();*/

    const dialogRef = this.matDialog.open(SocialMediaLoginComponent, {
      panelClass: 'dialog-container-custom',
      maxWidth: 'auto',
      maxHeight: 'auto',
      height: 'auto',
      width: 'auto',
      data: {userData: userData},
    });


  }



  backTo() {
    this._location.back();
  }

}
