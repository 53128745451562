import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators'; 


@Injectable({
  providedIn: 'root'
})
export class CallService {

     // API path
     base_path = 'https://preprod.paycall.co/index.php/api/call';
     current_user = localStorage.getItem('pay_id');
  
     constructor(private http: HttpClient) { }
   
   
     // Handle API errors
     handleError(error: HttpErrorResponse) {
       if (error.error instanceof ErrorEvent) {
         // A client-side or network error occurred. Handle it accordingly.
         console.error('An error occurred:', error.error.message);
       } else {
         // The backend returned an unsuccessful response code.
         // The response body may contain clues as to what went wrong,
         console.error(
           `Backend returned code ${error.status}, ` +
           `body was: ${error.error}`);
       }
       // return an observable with a user-facing error message
       return throwError(
         'Something bad happened; please try again later.');
     };

     createNewCall(receiver:any, roomId:any, callType:any): Observable<any> {

      return this.http
          .get<any>(this.base_path +'/start-call/'+this.current_user+'/'+receiver+'/'+roomId+'/'+callType)
          .pipe(
            retry(2),
            catchError(this.handleError)
          )

     }
   
   
     // Get call if exist data
      getCallIfExist(): Observable<any> {
        return this.http
          .get<any>(this.base_path +'/get-current-call/'+this.current_user)
          .pipe(
            retry(2),
            catchError(this.handleError)
          )
      }  


      updateStatus(callId:any, sta:Number){
      
        return this.http.get(this.base_path+'/update-call-status/'+callId+'/'+sta)
        .subscribe(data => {
          //console.log(data);
         }, error => {
          //console.log(error);
        });
  
      }

      getCallsHistory(){

        return this.http
          .get<any>(this.base_path +'/get-calls-history/'+this.current_user)
          .pipe(
            retry(2),
            catchError(this.handleError)
        )

      }


    getEndRoomData(__room:any){

      return this.http
          .get<any>(this.base_path +'/get-current-room-data/'+__room+'/'+this.current_user)
          .pipe(
            retry(2),
            catchError(this.handleError)
      )

    }
   
     
}
