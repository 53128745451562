import { Injectable } from '@angular/core';
import { PublicKey } from '@solana/web3.js';

@Injectable({
  providedIn: 'root',
})
export class PhantomWalletService {
  private provider: any;
  private publicKey: PublicKey | null = null;

  constructor() {
    this.provider = (window as any).solana;
  }

  async connectWallet(): Promise<void> {
    if (this.provider?.isPhantom) {
      try {
        const resp = await this.provider.connect();
        this.publicKey = resp.publicKey;
        if (this.publicKey) {
          //console.log('Connected address:', this.publicKey.toString());
        }
      } catch (err) {
        console.error('Connection to Phantom Wallet failed', err);
      }
    } else {
      console.error('Phantom Wallet not found.');
      this.handleMobileAuthorization();
    }
  }

  getPublicKey(): PublicKey | null {
    return this.publicKey;
  }

  private handleMobileAuthorization(): void {
    if (this.isMobileDevice()) {
      const deepLink = 'phantom://';
      window.location.href = deepLink;
    } else {
      alert('Phantom Wallet is not found. Please install it from https://phantom.app/');
    }
  }

  private isMobileDevice(): boolean {
    return /Mobi|Android/i.test(navigator.userAgent);
  }
}
