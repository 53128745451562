
			<app-layout-header></app-layout-header>
			<!-- Page Content -->
			<div class="content">
				<div class="container">

					<div class="row">
				
						
						<div class="col-md-6 col-lg-6">
						
							<!-- Booking Summary -->
							<div class="card booking-card ms-auto">
								<div class="card-header">
									<h4 class="card-title">Booking Summary</h4>
								</div>
								<div class="card-body">
								
									<!-- Booking Doctor Info -->
									<div class="booking-doc-info">
										<a href="#0" class="booking-doc-img">
											<img src="{{dataRoom.thumbnail}}" alt="User Image">
										</a>
										<div class="booking-info">
											<h4><a href="#0">{{dataRoom.full_name}}</a></h4>
											<div class="rating">
												<i class="fa fa-star filled"></i>
												<i class="fa fa-star filled"></i>
												<i class="fa fa-star filled"></i>
												<i class="fa fa-star filled"></i>
												<i class="fa fa-star"></i>
												<span class="d-inline-block average-rating" style="visibility: hidden;">35</span>
											</div>
											<div class="clinic-details" style="visibility: hidden;">
												<p class="doc-location"><i class="fas fa-map-marker-alt"></i> Newyork, USA</p>
											</div>
										</div>
									</div>
									<!-- Booking Doctor Info -->
									
									<div class="booking-summary">
										<div class="booking-item-wrap">
											<!--<ul class="booking-date">
												<li>Date :<span> 16 Nov 2023</span></li>
												<li>Time :<span> 10:00 AM</span></li>
											</ul>-->
											<ul class="booking-fee">
												<li *ngIf="current_user == dataRoom.receiver">Total Time earn :<span>{{callTimeSeconds}} minutes</span></li>
												<li *ngIf="current_user == dataRoom.sender">Total Time spent :<span>{{callTimeSeconds}} minutes</span></li>
												<!--<li>Booking Fee <span>$10</span></li>
												<li>Video Call <span>$50</span></li>-->
											</ul>
											<div class="booking-total">
												<ul class="booking-total-list">
													<li>
														<span>Total</span>
														<span class="total-cost">{{ calc | currency:'USD':'symbol':'1.2-2' }} </span> 
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- /Booking Summary -->
							
						</div>

						<div class="col-md-6 col-lg-6">
						
								
                        <!-- Write Review -->
                        <div class="write-review">
                            <!-- Write Review Form -->
                            <form>
                                <div class="mb-3">
                                    <label class="mb-2">Write a review</label>
                                    <div class="star-rating">
                                        <input id="star-5" type="radio" name="rating" value="star-5">
                                        <label for="star-5" title="5 stars">
														<i class="active fa fa-star"></i>
													</label>
                                        <input id="star-4" type="radio" name="rating" value="star-4">
                                        <label for="star-4" title="4 stars">
														<i class="active fa fa-star"></i>
													</label>
                                        <input id="star-3" type="radio" name="rating" value="star-3">
                                        <label for="star-3" title="3 stars">
														<i class="active fa fa-star"></i>
													</label>
                                        <input id="star-2" type="radio" name="rating" value="star-2">
                                        <label for="star-2" title="2 stars">
														<i class="active fa fa-star"></i>
													</label>
                                        <input id="star-1" type="radio" name="rating" value="star-1">
                                        <label for="star-1" title="1 star">
														<i class="active fa fa-star"></i>
													</label>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="mb-2">Your review</label>
                                    <textarea id="review_desc" maxlength="100" class="form-control"></textarea>

                                    <div class="d-flex justify-content-between mt-3"><small class="text-muted"><span id="chars">100</span> characters remaining</small></div>
                                </div>
                                <div class="submit-section">
                                    <button type="submit" class="btn btn-primary submit-btn">Add Review</button>
                                </div>
                            </form>
                            <!-- /Write Review Form -->

                        </div>
                        <!-- /Write Review -->
							
						</div>

						<div class="row">
                          <div class="col-lg-12 text-center">
								<div class="submit-section">
										<button [routerLink]="['/user',dataRoom.pay_call_id]" class="btn btn-primary submit-btn">Another action ? with <span style="color: blue;">{{dataRoom.full_name}}</span></button>
								</div>
						  </div>
						</div>

						
					</div>

				</div>

			</div>		
			<!-- /Page Content -->
   