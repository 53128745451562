<!-- Main Wrapper -->
<div class="main-wrapper">
	
	<router-outlet></router-outlet>
	
</div>
<!-- /Main Wrapper -->
<!-- ScrollToTop -->
<div class="progress-wrap active-progress d-none">
	<svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
		<path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
			style="transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919px, 307.919px; stroke-dashoffset: 228.265px;">
		</path>
	</svg>
</div>
<!-- /ScrollToTop -->