
<app-layout-header></app-layout-header>
		<!-- Page Content -->
		<div class="content">
			<div class="container">

				<div class="row">
					<div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">

                            <app-menu-layout></app-menu-layout>


					</div>
					<div class="col-md-7 col-lg-8 col-xl-9">

						<div class="row row-grid">
							<div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let historyItem of callsHistory">
								<div class="card widget-profile pat-widget-profile">
									<div class="card-body">
										<div class="pro-widget-content">
											<div class="profile-info-widget">
												<a href="#0" class="booking-doc-img">
													<img src="{{historyItem.thumbnail}}" alt="User Image">
												</a>
												<div class="profile-det-info">
													<h3><a href="#0">{{historyItem.full_name}}</a></h3>

													<div class="patient-details">
														<h5><b>ID :</b> {{historyItem.sender}}</h5>
														<h5 class="mb-0" class="text-danger">
                                                            <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M17 17L7 7M7 7V16M7 7H16" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                            Missed Call</h5>
													</div>
												</div>
											</div>
										</div>
										<div class="d-flex justify-content-around align-items-center">
                                                <a href="#" class="btn btn-primary btn-sm mt-3"><i class="fa fa-comments-o"></i>&nbsp;Message</a>&nbsp;&nbsp;
                                                <a href="#" class="btn btn-primary text-white btn-sm mt-3" style="    background: #004FFF;border-color:#004FFF;"><i class="fa fa-phone"></i>&nbsp;Call Now</a>
                                            </div>
									</div>
								</div>
							</div>


						</div>

					</div>
				</div>

			</div>

		</div>
		<!-- /Page Content -->
