<app-layout-header></app-layout-header>

<section class="banner-sec-twelve text-center" style="background: white;padding: 8% 0 100px;height: 100vh;" *ngIf="!isMarket">

	<div class="container signup-container bg-home-cp">
		<h1>Earn Money from Calls, Video Calls, Chats and Voicemails</h1>
		<p class="dpk">Monetize your communication effortlessly with PayCall. Set your own prices and get paid
			instantly.</p>
		<p class="dpkK">Sign up free with your wallet.</p>
		<div class="btns-login">
			<button class="btn btn-primary" (click)="showWeb3Modal()" style="padding: 14px;">Connect Wallet</button>
		</div>

		<div class="or-divider">OR</div>
		<div class="btns-login">
			<button class="btn btn-primary" style="background-color: blue;border: 0;" (click)="setMarket()">
				<svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M11 6C13.7614 6 16 8.23858 16 11M16.6588 16.6549L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				Marketplace
			</button>
		</div>


		<!--<img src="https://randomuser.me/api/portraits/women/44.jpg" class="icon icon-1" alt="user1">
		<img src="https://randomuser.me/api/portraits/men/45.jpg" class="icon icon-2" alt="user2">
		<img src="https://randomuser.me/api/portraits/women/46.jpg" class="icon icon-3" alt="user3">
		<img src="https://randomuser.me/api/portraits/men/47.jpg" class="icon icon-4" alt="user4">-->
	</div>

</section>



<!-- Home Banner -->
<section class="banner-sec-twelve h-100" style="background: black;padding: 194px 0 100px;height: 100vh !important;" *ngIf="isMarket">
	<div class="section-small-imgs d-none">
		<img src="assets/img/icons/section-small-icon-01.svg" class="small-icon-one" alt="Img">
		<img src="assets/img/icons/section-small-icon-02.svg" class="small-icon-two" alt="Img">
	</div>
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-lg-8">
				<div class="banner-content-twelve" style="padding-top: 70px;">
					<div class="banner-title-twelve aos" data-aos="fade-up" data-aos-delay="400">
						<h1 class="h2" style="    font-size: 37px;">Find the right <i style="font-weight: 400 !important;">trader, crypto influencer</i></h1>
						<h1 style="    font-size: 37px;"><i style="font-weight: 400 !important;">web3 expert, developer and more</i></h1>
					</div>
					<form>
						<div class="input-box-twelve mb-4">
							<div class="input-block w-50">
								<input class="form-control input-home-slider" placeholder="What are you looking for ?" (keyup)="getSearchInput($event)">
							</div>
							<div class="input-block w-50">
								<input class="form-control input-home-slider" placeholder="Where ?">
							</div>

							<div>
								<div class="search-btn-twelve d-flex align-items-center justify-content-center">
									<a href="javascript:void(0);" class="mr-5 bg-white">
										<svg width="30" height="30" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
											
											<g id="🔍-Product-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
												<g id="ic_fluent_my_location_24_filled" fill="#212121" fill-rule="nonzero">
													<path d="M12,2 C12.5128358,2 12.9355072,2.38604019 12.9932723,2.88337887 L13,3 L13.0003445,4.31396524 C16.4808766,4.76250386 19.238071,7.51999063 19.6861644,11.0006622 L21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5128358 21.6139598,12.9355072 21.1166211,12.9932723 L21,13 L19.6860348,13.0003445 C19.2375394,16.480541 16.480541,19.2375394 13.0003445,19.6860348 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4871642,22 11.0644928,21.6139598 11.0067277,21.1166211 L11,21 L11.0006622,19.6861644 C7.51999063,19.238071 4.76250386,16.4808766 4.31396524,13.0003445 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4871642 2.38604019,11.0644928 2.88337887,11.0067277 L3,11 L4.31383558,11.0006622 C4.7619722,7.51965508 7.51965508,4.7619722 11.0006622,4.31383558 L11,3 C11,2.44771525 11.4477153,2 12,2 Z M12,6.25 C8.82436269,6.25 6.25,8.82436269 6.25,12 C6.25,15.1756373 8.82436269,17.75 12,17.75 C15.1756373,17.75 17.75,15.1756373 17.75,12 C17.75,8.82436269 15.1756373,6.25 12,6.25 Z M12,8 C14.209139,8 16,9.790861 16,12 C16,14.209139 14.209139,16 12,16 C9.790861,16 8,14.209139 8,12 C8,9.790861 9.790861,8 12,8 Z" id="🎨-Color">
										
										</path>
												</g>
											</g>
										</svg>
									</a>

									<a href="javascript:void(0);" style="background: black;"  (click)="getSearch()">
										<svg width="30" height="30" viewBox="0 0 24 24" fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M11 6C13.7614 6 16 8.23858 16 11M16.6588 16.6549L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
												stroke="#ffffff" stroke-width="2" stroke-linecap="round"
												stroke-linejoin="round" />
										</svg>
									</a>
								</div>
							</div>

						</div> 
					</form>
					<div class="suggestion-serach-items mb-4">
					 <strong class="mr-5">Popular : </strong>
					 <button>Crypto Consulting</button>
					 <button>Solana</button>
					 <button>Influencer</button>
					 <button>Bitcoin</button>
					</div>
					<div class="banner-counter d-none">
						<div class="row justify-content-center">
							<div class="col-md-4 col-sm-6">
								<div class="counter-twelve bg-purple-color" data-aos="fade-up" data-aos-delay="400">
									<span class="hexagon"></span>
									<h3><span class="counter">9000</span>+</h3>
									<h5>Total Users</h5>
								</div>
							</div>
							<div class="col-md-4 col-sm-6">
								<div class="counter-twelve bg-violet-color" data-aos="fade-up" data-aos-delay="500">
									<span class="hexagon"></span>
									<h3><span class="counter">1</span>M+</h3>
									<h5>Total Visitors</h5>
								</div>
							</div>
							<div class="col-md-4 col-sm-6">
								<div class="counter-twelve bg-blue-color" data-aos="fade-up" data-aos-delay="600">
									<span class="hexagon"></span>
									<h3><span class="counter">1500</span>+</h3>
									<h5>Expert Specialists</h5>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div class="col-lg-4 col-md-6 col-sm-6 col-10">
				<img src="../../assets/PayCall_web3.png">
			</div>
			
		</div>
	</div>
</section>
<!-- /Home Banner -->
