<app-layout-header></app-layout-header>
<!-- Page Content -->
<div class="content top-space">
	<div class="container-fluid">



			

		<section class="middle">
			<div class="container">
				<div class="row align-items-center justify-content-center">
					<div class="col-xl-7 col-lg-6 col-md-12 col-sm-12">
						<div class="border p-3 rounded">
							<!---->
							<div class="row align-items-center">
								<div class="col-3 align-items-start">
									<button class="back-btn" (click)="backTo()">
										<i class="fa fa-angle-left font-size-24 text-dark"></i>
									</button>
								</div>
								<div class="col-6 align-items-start text-center"><img alt="logo de paycall.co"
										height="35" src="../../../assets/img/logos/primary.svg" width="300" ></div>
							</div>
							<hr style="color: #F0F3F8;">
							<div class="px-xl-5 px-lg-4">
								<div class="sec_title position-relative text-start mb-3">
									<h3 class="ft-bold text-login">Login or Register</h3>
								</div>
								<p class="text-login font-weight-500 mb-3">
									Please enter your phone number ☎️ </p>
								<!--<div class="form-group">
									<input placeholder="Mobile number" type="text" required="" class="form-control1">
								</div>-->
								<form [formGroup]="loginForm" (ngSubmit)="phoneAuth()">
								   <div class="d-flex">
											<div class="input-group mb-0">
									

												<div class="form-group div-btn" style="text-align: end;">
														<button class="btn btn-md btn-book text-center" [disabled]="!loginForm.valid">
															<span *ngIf="!loader">Continue<i class="fa fa-angle-right font-size-16 ml-2 d-none"></i></span>
															<span *ngIf="loader"><i class="fa fa-circle-o-notch fa-spin"></i></span>
														</button>
													</div>
			
			
									  </div>
									   
								   </div>

									
									<div class="login-or">
										<span class="or-line"></span>
										<span class="span-or">or</span>
									</div>

									<div class="row social-login">
										<div class="col-6">
										<button type="button" class="btn btn-facebook p-3 w-100"><i class="fa fa-facebook-f me-1"></i> Login</button>
										</div>
										<div class="col-6">
										<button type="button" class="btn btn-google p-3 w-100" (click)="loginWithGoogle()"><i class="fa fa-google me-1"></i> Login</button>
										</div>
										</div>


								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

	</div>

</div>
<!-- /Page Content -->