<app-layout-header></app-layout-header>
<!-- Page Content -->
<div class="content">
    <div class="container">

        <div class="row">
            <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">

                <app-menu-layout></app-menu-layout>

            </div>

            <div class="col-md-7 col-lg-8 col-xl-9">

                <div class="row">
                    <div class="col-lg-5 d-flex">
                        <div class="card flex-fill">
                            <div class="card-header d-flex justify-content-between align-items-center">
                                    <h3 class="card-title strong"><strong>Card</strong></h3>
                                    <a title="Edit Profile" class="btn btn-primary btn-sm"><i
                                        class="fa fa-pencil"></i> Edit Details</a>
                            </div>
                            <div class="card-body p-0">
                                <div class="profile-view-bottom">
                                        <img src="../../assets/creditcardpaycall.png">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 d-flex">
                        <div class="card flex-fill">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-6">
                                    <div class="account-card bg-purple-light">
                                        <span>{{ userWallet.sold | currency:'USD':'symbol':'1.2-2' }}</span>  Balance
                                    </div>

                                    </div>
                                    <div class="col-lg-6">
                                            <div class="account-card bg-purple-light">
                                                <span>{{userWallet.sold }}</span> Credits
                                            </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="account-card bg-success-light">
                                            <span>$0.00</span> Earned
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="account-card bg-warning-light">
                                            <span>$0.00</span> Spent
                                        </div>
                                    </div>
                                    

                                    <div class="col-md-12 text-center">
                                        <a routerLink="/dashboard/wallet/deposit" class="btn btn-primary request_btn"><i class="fa fa-arrow-circle-down"></i>&nbsp;Deposit</a>&nbsp;&nbsp;
                                        <a routerLink="/dashboard/wallet/widhraw" class="btn btn-warning text-white request_btn"><i class="fa fa-arrow-circle-up"></i>&nbsp;Withdraw</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
 
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body pt-0">

                                <!-- Tab Menu -->
                                <nav class="user-tabs mb-4">
                                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="#pat_accounts" data-bs-toggle="tab">Wallet
                                                Transactions</a>
                                        </li>
                                    </ul>
                                </nav>
                                <!-- /Tab Menu -->

                                <!-- Tab Content -->
                                <div class="tab-content pt-0">

                                    <!-- Accounts Tab -->
                                    <div id="pat_accounts" class="tab-pane fade show active">
                                        <div class="card card-table mb-0">
                                            <div class="card-body">
                                                <div class="table-responsive">
                                                    <table class="table table-hover table-center mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>User Name</th>
                                                                <th>Amount</th>
                                                                <th>Transaction</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let transaction of transactions">
                                                                <td>{{transaction.created_at}} <span class="d-block text-info">10.00
                                                                        AM</span></td>
                                                                <td>
                                                                    <h2 class="table-avatar">
                                                                        <a href="patient-profile.html"
                                                                            class="avatar avatar-sm me-2"><img
                                                                                class="avatar-img rounded-circle"
                                                                                src="assets/img/patients/patient.jpg"
                                                                                alt="User Image"></a>
                                                                        <a href="patient-profile.html">{{transaction.full_name}}
                                                                            <span>#PT0016</span></a>
                                                                    </h2>
                                                                </td>
                                                                <td>{{transaction.amount  | currency:"$"}}</td>
                                                                <td><span
                                                                        class="badge rounded-pill bg-success-light">{{transaction.type}}</span>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /Accounts Tab -->


                                </div>
                                <!-- Tab Content -->

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</div>
<!-- /Page Content -->




<!-- Payment Request Moodal -->

<div class="modal fade custom-modal" id="payment_request_modal" role="dialog" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Payment Request</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form id="payment_request_form" method="post">
                    <input type="hidden" name="payment_type" id="payment_type" value="1">
                    <div class="mb-3">
                        <label class="mb-2">Request Amount</label>
                        <input type="text" name="request_amount" id="request_amount" class="form-control" maxlength="6"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                        <span class="help-block"></span>
                    </div>
                    <div class="mb-3">
                        <label class="mb-2">Description (Optional)</label>
                        <textarea class="form-control" name="description" id="description"></textarea>
                        <span class="help-block"></span>
                    </div>
                </form>
            </div>
            <div class="modal-footer text-center">
                <button type="submit" id="request_btn" class="btn btn-primary">Request</button>
            </div>
        </div>
    </div>
</div>

<!-- /Payment Request Moodal -->

<!-- Account Details Modal-->
<div class="modal fade custom-modal" id="account_modal" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Account Details</h3>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form id="accounts_form" method="post">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="mb-3">
                                <label class="control-label mb-2">Bank Name</label>
                                <input type="text" name="bank_name" class="form-control bank_name"
                                    value="Wells Fargo & Co">
                                <span class="help-block"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="mb-3">
                                <label class="control-label mb-2">Branch Name</label>
                                <input type="text" name="branch_name" class="form-control branch_name" value="Lenexa">
                                <span class="help-block"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="mb-3">
                                <label class="control-label mb-2">Account Number</label>
                                <input type="text" name="account_no" class="form-control account_no"
                                    value="5396 5250 1908 3838">
                                <span class="help-block"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="mb-3">
                                <label class="control-label mb-2">Account Name</label>
                                <input type="text" name="account_name" class="form-control acc_name"
                                    value="Dr. Darren Elder">
                                <span class="help-block"></span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer text-center">
                <button type="submit" id="acc_btn" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>
<!-- /Account Details Modal-->