import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SwiperModule } from "swiper/angular";
import { NgxPayPalModule } from 'ngx-paypal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { OtpVerificationComponent } from './pages/auth/otp-verification/otp-verification.component';
import { RegisterComponent } from './pages/auth/register/register.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { ChatComponent } from './pages/chat/chat.component';
import { RingingModelComponent } from './modals/ringing-model/ringing-model.component';
import { GoogleLoginProvider, SocialLoginModule, FacebookLoginProvider } from 'angularx-social-login';
import { AuthGuardService } from './guards/auth-guard.service';
import { UserDashboardComponent } from './pages/user-dashboard/user-dashboard.component';
import { WalletComponent } from './pages/user-dashboard/wallet/wallet.component';
import { PhoneHistoryComponent } from './pages/user-dashboard/phone-history/phone-history.component';
import { FavoritesComponent } from './pages/user-dashboard/favorites/favorites.component';
import { CalendarComponent } from './pages/user-dashboard/calendar/calendar.component';
import { AvailableTimingsComponent } from './pages/user-dashboard/available-timings/available-timings.component';
import { ProfileSettingsComponent } from './pages/user-dashboard/profile-settings/profile-settings.component';
import { SocialMediaComponent } from './pages/user-dashboard/social-media/social-media.component';
import { ReviewsComponent } from './pages/user-dashboard/reviews/reviews.component';
import { MenuLayoutComponent } from './pages/user-dashboard/menu-layout/menu-layout.component';
import { LayoutHeaderComponent } from './layouts/layout-header/layout-header.component';
import { LayoutFooterComponent } from './layouts/layout-footer/layout-footer.component';
import { VideoCallComponent } from './pages/chat/video-call/video-call.component';
import { VoiceCallComponent } from './pages/chat/voice-call/voice-call.component';
import { EndCallComponent } from './pages/chat/end-call/end-call.component';
import { VideoVoiceCallComponent } from './pages/chat/video-voice-call/video-voice-call.component';
import { ConversationPopupComponent } from './modals/conversation-popup/conversation-popup.component';
import { FaqComponent } from './pages/footer/faq/faq.component';
import { ContactComponent } from './pages/footer/contact/contact.component';
import { PolicyComponent } from './pages/footer/policy/policy.component';
import { TermsComponent } from './pages/footer/terms/terms.component';
import { DepositWalletComponent } from './pages/user-dashboard/wallet/deposit-wallet/deposit-wallet.component';
import { PrivacyComponent } from './pages/user-dashboard/privacy/privacy.component';
import { WidhrawWalletComponent } from './pages/user-dashboard/wallet/widhraw-wallet/widhraw-wallet.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { ChatRightComponent } from './pages/chat/chat-right/chat-right.component';
import { ChatLeftComponent } from './pages/chat/chat-left/chat-left.component';
import { PaymentModalComponent } from './pages/user-dashboard/wallet/payment-modal/payment-modal.component';
import { AccessPinComponent } from './security/access-pin/access-pin.component';
import { RefundPolicyComponent } from './pages/footer/refund-policy/refund-policy.component';
import { SuccessWithdrawComponent } from './pages/user-dashboard/wallet/success-withdraw/success-withdraw.component';
import { FilterV2Component } from './pages/filter-v2/filter-v2.component';
import { SocialMediaLoginComponent } from './modals/social-media-login/social-media-login.component';
import { NgxAgoraModule } from 'ngx-agora';
import { AuthGuard } from './services/guards/auth.guard';

import { AuthService } from './services/auth/auth.service';
import { CopyToClipboardDirective } from './services/helpers/copy-to-clipboard.directive';

import { WalletConnectService } from './services/cryptopayment/wallet-connect.service';
import { Web3ModalComponent } from './modals/web3-modal/web3-modal.component';


import { PhantomWalletService } from './services/auth/crypto/phantom-wallet.service';
import { MetaMaskWalletService } from './services/auth/crypto/metamask.service';





@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    OtpVerificationComponent,
    RegisterComponent,
    SearchPageComponent,
    UserProfileComponent,
    RingingModelComponent,
    ChatComponent,
    UserDashboardComponent,
    WalletComponent,
    PhoneHistoryComponent,
    FavoritesComponent,
    CalendarComponent,
    AvailableTimingsComponent,
    ProfileSettingsComponent,
    SocialMediaComponent,
    ReviewsComponent,
    MenuLayoutComponent,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    VideoCallComponent,
    VoiceCallComponent,
    EndCallComponent,
    VideoVoiceCallComponent,
    ConversationPopupComponent,
    FaqComponent,
    ContactComponent,
    PolicyComponent,
    TermsComponent,
    DepositWalletComponent,
    PrivacyComponent,
    WidhrawWalletComponent,
    ChatRightComponent,
    ChatLeftComponent,
    PaymentModalComponent,
    AccessPinComponent,
    RefundPolicyComponent,
    SuccessWithdrawComponent,
    FilterV2Component,
    SocialMediaLoginComponent,
    CopyToClipboardDirective,
    Web3ModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      positionClass: 'toast-bottom-right',   
      preventDuplicates: true,
    }),
    NgxStripeModule.forRoot('pk_test_51OguoNEDNY6qYvAFZzfx5kta7WVxFLvwqun8cEzHrItkLlFD2QS56T4aIVhGZQvAWIrfln4Bo24Y67AY6wyLAPnh00FUBLFie4'),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    MatDialogModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule, 
    FullCalendarModule,
    SocialLoginModule,
    SwiperModule,
    NgxPayPalModule,
    NgxAgoraModule.forRoot({ AppID: environment.agora.appId })
  ],
  providers: [{
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: true,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider('148517665605-jspahbqleats6lvlag9kasc2c11b5g7o.apps.googleusercontent.com')
          //provider: new GoogleLoginProvider('649345664162-8ejrdeielthh4vltud3289vujf1h8t4a.apps.googleusercontent.com')
        }/*,
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider("1449490695638667")
        }*/
      ]
    },

  },
    AuthGuardService,
    WalletConnectService,
    AuthService,
    PhantomWalletService,
    MetaMaskWalletService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
