import {Component, OnInit, Inject, Input} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { NgForm } from "@angular/forms";
import { UserService } from "../../services/apis/users/user.service";

//imports
import { Observable, throwError, ReplaySubject } from "rxjs";
import { retry, catchError } from "rxjs/operators";


@Component({
  selector: 'app-social-media-login',
  templateUrl: './social-media-login.component.html',
  styleUrls: ['./social-media-login.component.scss']
})
export class SocialMediaLoginComponent implements OnInit {

  @Input() userData: any;
  clientId:any = Math.floor(111111112 + Math.random() * 900000000);
  showNameBool:boolean = false;
  showEmailBool:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SocialMediaLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private http: HttpClient,
    private router: Router,
    public UserService: UserService,
  ) { }

  
  ngOnInit() {

  }


  showInput(ShowVar:any){
    
    if(ShowVar == 'showNameBool'){
        
      this.showNameBool = !this.showNameBool;

    }else{

      this.showEmailBool = !this.showEmailBool;

    }

  }

  /**
   * 
   * @param form 
  */

    confirmGoogleData(f:NgForm){

      if (!f.valid) {
  
        return false;
        
      } else {
        console.log(f.value);
        //Get saved list of students
        this.UserService.UsergoogleAuth(f.value).subscribe((response) => {
          
          localStorage.setItem('pay_id', response.pay_call_id);
          localStorage.setItem('pay_user_id', response.id);
          localStorage.setItem('isLoggedIn', 'true');

          window.location.href="/";

        })
        
      }
  
  
  
    }

  async cancelLogin() {
    const close: string = "Modal Removed";
    //await this.modalController.dismiss(close);
  }

}
