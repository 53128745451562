import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FavorieService } from '../../../services/apis/favorites/favorie.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {

  favorites:any = [];

  constructor(
    private router: Router,
    public favorieService: FavorieService,
  ) { }

  ngOnInit(): void {
    this.getAllFavorites();
  }



  getAllFavorites() {
    //Get saved list of students
    this.favorieService.getSingleUserFavory().subscribe(response => {
      console.log(response);
      this.favorites = response;
    })
  }

}
