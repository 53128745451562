<!--
Inspired by https://dribbble.com/supahfunk
-->
<section class="avenue-messenger">
    <button class="close-modal" (click)="closeDialog()">
         <i class="fa fa-close"></i>
    </button>

  <div class="chat">
    <div class="chat-title d-flex align-items-center p-2">
        <img src="{{data.user.thumbnail}}" class="avatar"/>
        <label>{{data.user.full_name}}<p>{{getFirst(data.user.speciality)}}</p></label>
        </div>
        
      
        <div class="messages" #scrollMe>
            <div class="messages-content">
                <div *ngFor="let message of messListe" [class]="message.sender_id == current_user ? 'message new  message-personal' : 'message new'">
                  <label><strong>{{message.message}}</strong><p>{{data.user.text_message_price  | currency:"$"}} - {{message.created_at | date:'HH:mm'}}</p></label>
                </div>
            </div>
          </div>
    <div class="message-box">
      <form  [formGroup]="form">
            <textarea type="text" class="message-input" formControlName="message" (keyup.enter)="startConversationToUser()" placeholder="Type message..."></textarea>
            <button type="submit" class="message-submit" (ckick)="startConversationToUser()">Send</button>
      </form>
    </div>
  </div>
    </section>
  <!--<div class="bg"></div>-->