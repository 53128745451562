import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";


@Injectable({
  providedIn: "root",
})
export class UserService {
  // API path
  base_path = "https://preprod.paycall.co/index.php/api/user";
  //base_path = "http://localhost:8080/api/user";
  current_user = localStorage.getItem("pay_id");
  current_user_id = localStorage.getItem("pay_user_id");

  isLoggedIn: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    if (this.current_user) {
      this.isLoggedIn = true;
    }
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`,
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }

  // Get users list
  getList(): Observable<any> {
    return this.http
      .get<any>(this.base_path)
      .pipe(retry(2), catchError(this.handleError));
  }

  // Get single user

  getSingleUser(): Observable<any> {
    return this.http
      .get<any>(this.base_path + "/" + this.current_user)
      .pipe(retry(2), catchError(this.handleError));
  }

  // create new user if not exist
  createSingleUser(walletId: any): Observable<any> {
    return this.http
      .get<any>(this.base_path + "/create/" + walletId)
      .pipe(retry(2), catchError(this.handleError));
  }


  /**
   * add new link for current user 
   */
  UsergoogleAuth(__form: any) {
      return this.http.post<any>(this.base_path + '/google-auth', __form)
      .pipe(
        catchError(this.handleError)
      );
  }

  // Get single user

  viewSingleUser(user_id: any): Observable<any> {
    return this.http
      .get<any>(this.base_path + "/" + user_id)
      .pipe(retry(2), catchError(this.handleError));
  }

  editSingleUser(__form: any) {
    return this.http
      .post(this.base_path + "/edit/" + this.current_user, __form)
      .subscribe(
        (data) => {
          //console.log(data);
        },
        (error) => {
          //console.log(error);
        },
      );
  }

  // change user status [0, 1]
  changeStaus(status:any): Observable<any> {
    return this.http
      .get<any>(
        this.base_path +
          "/change-visibility/" +
          this.current_user_id +
          "/" +
          status,
      )
      .pipe(retry(2), catchError(this.handleError));
  }


  addVisitorByUser(id:any): Observable<any> {
    return this.http
      .get<any>(
        this.base_path + "/add-profile-visitor/" + id,
      )
      .pipe(retry(2), catchError(this.handleError));
  }

  //get social media links stored in DB
  fetchSocialMediaLinksList(): Observable<any> {
    return this.http
      .get<any>("https://preprod.paycall.co/index.php/api/list-social-media-links")
      .pipe(retry(2), catchError(this.handleError));
  }

  //get social media links related to current user
  fetchUserSocialMediaLinksList(): Observable<any> {
    return this.http
      .get<any>(
        this.base_path + "/list-social-media-links/" + this.current_user_id,
      )
      .pipe(retry(2), catchError(this.handleError));
  }

    //get social media links related to current user
    fetchAccountSocialMediaLinksList(user:any): Observable<any> {
      return this.http
        .get<any>(
          this.base_path + "/list-social-media-links/" + user,
        )
        .pipe(retry(2), catchError(this.handleError));
    }

  /**
   * add new link for current user 
  **/
  createUserSocialLink(__form: any) {
      return this.http.post<any>(this.base_path + '/create-user-social-media-links/' + this.current_user_id, __form)
      .pipe(
        catchError(this.handleError)
      );
  }


  removeLink(link:any){
    return this.http
      .get<any>(
        this.base_path + "/delete-user-social-media-link/" + link,
      )
      .pipe(retry(2), catchError(this.handleError));
  }

  isLoggedin() {
    this.isLoggedIn = true;
  }


  /**
   * Media center Part 
  **/

   //get social media links related to current user
   getUserMediaPictures(userId:any): Observable<any> {
    return this.http
      .get<any>(
        this.base_path + "/get-user-media-pictures/" + userId,
      )
      .pipe(retry(2), catchError(this.handleError));
  }

  addMediaPicture(__formData: any) {
    return this.http.post<any>(this.base_path + '/add-media-picture/' + this.current_user_id, __formData)
    .pipe(
      catchError(this.handleError)
    );
  }

  
  deleteSingleMediaPicture(medId:any): Observable<any> {
  return this.http
    .get<any>(
      this.base_path + "/delete-user-single-media-pictures/" + medId,
    )
    .pipe(retry(2), catchError(this.handleError));
  }


  /**
   * add new link for current user 
  **/
 sendUserVerificationRequest(__form: any) {
  return this.http.post<any>(this.base_path + '/send-verification-request/' + this.current_user_id, __form)
  .pipe(
    catchError(this.handleError)
  );
}


deleteMyAccount(): Observable<any> {
  return this.http
    .get<any>(this.base_path + "/delete-account/" + this.current_user_id)
    .pipe(retry(2), catchError(this.handleError));
}


}
