import { Component, OnInit } from '@angular/core';
import { WalletService } from '../../../services/apis/wallets/wallet.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {

  userWallet:any = [];
  showSearchbar:boolean = false;

  wallet_id!: number;
  transactions:any = [];

  constructor(
    public walletService: WalletService,
  ) { }

  ngOnInit(): void {

    this.getSingleUserWallet();

  }


  getSingleUserWallet() {
    //Get saved list of students
    this.walletService.getSingleUserWallet().subscribe(response => {
      this.getSingleWalletHistory(response['id']);
      this.userWallet = response;
    })
  }

  getSingleWalletHistory(wall_id:any){
    console.log('this is the wallet id ',wall_id);
    this.walletService.getWalletHistory(wall_id).subscribe(response => {
      //console.log('transactions', response);
      this.transactions = response;
    })
  }

}
