import { Component,  HostListener, OnInit} from '@angular/core';
import {SocialAuthService} from 'angularx-social-login';
import {Router} from '@angular/router';

//categories service 
import { CategoryService } from '../../services/apis/categories/category.service';
import { UserService } from '../../services/apis/users/user.service';
import { WalletService } from '../../services/apis/wallets/wallet.service';
import { ChatService } from '../../services/apis/chat/chat.service';

import { AuthService } from '../../services/auth/auth.service';

declare const window: any;
declare const ethereum: any;


import { MatDialog } from '@angular/material/dialog'; //dialogue module
import { Web3ModalComponent } from '../../modals/web3-modal/web3-modal.component';


@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss']
})
export class LayoutHeaderComponent implements OnInit {

  blockNumber:any;
  isLoggedIn:boolean = false;
  currentUser:any = [];

  categories:any = []; //categories table

  target:string = '';

  creditsSold:any;
  nbrUnreadMessages:number = 0;

  SearchShowed:boolean = false;

  constructor(
    private router: Router,
    public categoryService: CategoryService,
    public UserService: UserService,
    public walletService: WalletService,
    public chatService: ChatService,
    private matDialog: MatDialog,
  ) {
    if(localStorage.getItem('isLoggedIn') == 'true'){
          
      this.isLoggedIn = true;
      this.blockNumber = localStorage.getItem('blockNumber');
      this.getSingleUser();

    }else{
  
      this.isLoggedIn = false;
  
    }
  }

  ngOnInit() {
    
  }


  open(){
    //console.log('ola mundo');
    if (typeof window.ethereum !== 'undefined') {
      //console.log('MetaMask is installed!');
      this.connectAccount();
      // this.loadWeb3();
    }
  }

  async loadWeb3() {
    if (window.ethereum) {
      //window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      //window.web3 = new Web3(window.web3.currentProvider);
    } else {
    }
  }

  async connectAccount() {
    //const account = await ethereum.request({ method: 'eth_requestAccounts' });
    const permission = await window.ethereum.request({ method: "wallet_requestPermissions", params: [ { eth_accounts: {} } ]});
    const account = await ethereum.request({ method: 'eth_accounts' });
    //const account = await ethereum.request({ method: 'eth_chainId' });
    this.blockNumber = account[0];
    this.createNewUser(this.blockNumber);
    //localStorage.setItem('isLoggedIn', 'true');
    //localStorage.setItem('blockNumber', this.blockNumber);
    //window.location.reload();

    //const balance = await ethereum.request({ method: 'eth_getBalance', params: [this.blockNumber, 'latest'] });
    //const etherBalance = window.web3.utils.fromWei(balance, 'ether');


    //console.log('readyyyyyyyyyyyyyyyyyyyyyyyy walet account', balance);
    
  }


  async logout() {
      const walletAddress = await window.ethereum.request({
         method: "eth_requestAccounts",
         params: [ 
           {
             eth_accounts: {}
           }
         ]
      });

      const account = await ethereum.request({ method: 'eth_accounts' });
      localStorage.removeItem('pay_id');
      localStorage.removeItem('pay_user_id');
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('blockNumber');
      window.location.href='/';
      //console.log('after logout', account);

      
      
}


createNewUser(walletId:any) {
  this.UserService.createSingleUser(walletId).subscribe(response => {

    localStorage.setItem('pay_id', response.pay_call_id);
    localStorage.setItem('pay_user_id', response.id);
    localStorage.setItem('isLoggedIn', 'true');

    window.location.href="/";

  })
}

 
getAllCategories() {
  //Get saved list of students
  this.categoryService.getList().subscribe(response => {
    this.categories = response;
  })
}

getSingleUser() {
  //Get saved list of students
  this.UserService.getSingleUser().subscribe(response => {
    //console.log('ljfljsdlfjsdfjd',response)
    this.currentUser = response;
  })
}


getSearchInput(event:any){
  //console.log(event.target.value);
  this.target = event.target.value;
}


getSearch(){
  //this.router.navigate(['/search/',this.target]);
  this.router.navigate(['/search/',this.target]).then(() => {
    window.location.reload();
  });
}

getSingleUserWallet() {
  //Get saved list of students
  this.walletService.getSingleUserWallet().subscribe(response => {
    this.creditsSold = response['sold'];
  })
}


filterTop(dataTag:any){     
  //console.log(dataTag);
  this.router.navigate(['/search/all',{ category: dataTag.id }]);
}


//notifications part
messagesNotificationsListe() { 
  //Get saved list of students
  this.chatService.getUserMessagesNotifications().subscribe(response => {
    //this.creditsSold = response['sold'];
    this.nbrUnreadMessages = response.length.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
  })
}
  

showNavSearch(){
  this.SearchShowed = !this.SearchShowed;
}



showWeb3Modal() {
  const dialogRef = this.matDialog.open(Web3ModalComponent, {
    //disableClose: true,
    panelClass: 'dialog-crypto-login-custom',
    maxWidth: '100%',
    maxHeight: '100%',
    height: 'auto',
    width: 'auto',
    //data: { senderDetails: this.senderDetails },
  });
}


}