/*import { Injectable } from '@angular/core';
import { ethers } from 'ethers';

@Injectable({
  providedIn: 'root',
})
export class MetamaskService {
  private ethereum: any;

  constructor() {
    if (typeof window.ethereum !== 'undefined') {
      this.ethereum = window.ethereum;
    } else {
      console.log('Metamask not found');
    }
  }

  async connect(): Promise<void> {
    try {
      await this.ethereum.request({ method: 'eth_requestAccounts' });
      console.log('Connected to Metamask');
    } catch (error) {
      console.error('Error connecting to Metamask:', error);
    }
  }

  async getAccounts(): Promise<string[]> {
    try {
      const accounts = await this.ethereum.request({ method: 'eth_accounts' });
      return accounts;
    } catch (error) {
      console.error('Error fetching accounts:', error);
      return [];
    }
  }

  async disconnect(): Promise<void> {
    if (this.ethereum && this.ethereum.removeListener) {
      this.ethereum.removeAllListeners();
    }
  }
}*/
// metamask.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MetaMaskWalletService {

  constructor() {
    // Check if MetaMask is installed and ethereum is available
    if (typeof window.ethereum === 'undefined') {
      console.error('MetaMask is not installed');
      // Handle the case where MetaMask is not installed
      this.handleMetaMaskNotFound();
    }
  }

  async connectWallet(): Promise<void> {
    if (!this.isMetaMaskInstalled()) {
      console.error('MetaMask is not installed');
      return;
    }

    try {
      // Request account access if needed
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      // You can now use the MetaMask provider
    } catch (error) {
      console.error('MetaMask connection error', error);
    }
  }

  private isMetaMaskInstalled(): boolean {
    return typeof window.ethereum !== 'undefined';
  }

  private handleMetaMaskNotFound(): void {
    if (this.isMobileDevice()) {
      const deepLink = 'https://metamask.app.link/dapp/https://app.paycall.org'; // Replace with your dApp URL
      window.location.href = deepLink;
    } else {
      alert('MetaMask is not found. Please install it from https://metamask.io/');
    }
  }

  private isMobileDevice(): boolean {
    return /Mobi|Android/i.test(navigator.userAgent);
  }
}
