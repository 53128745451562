import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

//tags input 
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { retry, catchError, map, startWith } from 'rxjs/operators';
import { UserService } from '../../services/apis/users/user.service';
import { SearchService } from '../../services/apis/search/search.service';
import { CategoryService } from '../../services/apis/categories/category.service';
import { Router, ActivatedRoute, Route } from "@angular/router";


@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {

  term: string; //search bar input constant
  filteredCount = { count: 0 };

  toppings = new FormControl();
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  users:any [] = [];

  languagesListe: string[] = ["Afrikaans",
  "Arabic",
  "Bengali",
  "Bulgarian",
  "Catalan",
  "Cantonese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Lithuanian",
  "Malay",
  "Malayalam",
  "Panjabi",
  "Tamil",
  "English",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Javanese",
  "Korean",
  "Norwegian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovene",
  "Spanish",
  "Swedish",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
  "Welsh",
  "Sign language",
  "Algerian",
  "Aramaic",
  "Armenian",
  "Berber",
  "Burmese",
  "Bosnian",
  "Brazilian",
  "Bulgarian",
  "Cypriot",
  "Corsica",
  "Creole",
  "Scottish",
  "Egyptian",
  "Esperanto",
  "Estonian",
  "Finn",
  "Flemish",
  "Georgian",
  "Hawaiian",
  "Indonesian",
  "Inuit",
  "Irish",
  "Icelandic",
  "Latin",
  "Mandarin",
  "Nepalese",
  "Sanskrit",
  "Tagalog",
  "Tahitian",
  "Tibetan",
  "Gypsy",
  "Wu"];
  socialLinks: any = [];


  //filter form datas
  categories:any = []; //categories table
  selectedCategory:any;
  subCategories: any = []; //subcategories table



  //tags part
   visible = true;
   selectable = true;
   removable = true;
   separatorKeysCodes: number[] = [ENTER, COMMA];
   fruitCtrl = new FormControl();
   filteredFruits: Observable<string[]>;
   fruits: string[] = [];
   allFruits: string[] = [];
   @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;



   //filter submit form
   filterForm = new FormControl();
   minPrice:string;
   maxPrice:string;
   callType:any;
   selectedSubCategory:any;
   selectedLanguage:any;
   selectedSocialLink:any;



  constructor(
    public UserService: UserService,
    public searchService: SearchService,  
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public categoryService: CategoryService,
  ) { 

    this.filteredFruits = this.fruitCtrl.valueChanges.pipe( startWith(null), map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));

  }

  ngOnInit(): void {

    if (this.activatedRoute.snapshot.paramMap.get("word")) {
      this.term = this.activatedRoute.snapshot.paramMap.get("word");
      this.searchService.searchUsers(this.term).subscribe(response => {
        //console.log(response);
        this.users = response;
      })
    }

    
    if(this.activatedRoute.snapshot.paramMap.get("subcategory")){

      this.selectedSubCategory = this.activatedRoute.snapshot.paramMap.get("subcategory");
      //console.log('reaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaady subcat', this.selectedSubCategory);

    }

    if(this.activatedRoute.snapshot.paramMap.get("category")){

      this.selectedCategory = this.activatedRoute.snapshot.paramMap.get("category");
      this.showSubCategories(this.selectedCategory);

    }



    this.getAllCategories();
    this.getAllTags();
    this.getSocialMediaLinksList();

  }


  getAllUsers() {
    //Get saved list of students
    this.UserService.getList().subscribe(response => {
      //console.log(response);
      this.users = response;
    })
  }


  searchEngine(event:any){
    this.searchService.searchUsers(event.target.value).subscribe(response => {
      //console.log('eaaaaaaaaaaaaaaaaaaaaaaaaaaaaaady typing', response);
      this.users = response;
    })
  }


  getAllCategories() {
    //Get saved list of students
    this.categoryService.getList().subscribe(response => {
      this.categories = response;
    })
  }

  changeCategory()
  {
    //console.log('reaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaady', this.selectedCategory);
    this.showSubCategories(this.selectedCategory);
  }

  showSubCategories(__catId:any){
    
    this.categoryService.getSubCategoriesList(__catId).subscribe(response => {
      this.subCategories = response;
    })


  }


  getAllTags() {
    //Get list of categories
    this.categoryService.getSubCategoriesTags().subscribe(response => {
  
      //this.allFruits = response;
      response.forEach(element => {
        this.allFruits.push(element.tag_label);
      });
      //console.log('all tags', this.allFruits);
    })
  }

   /**
  * Fetch social media links 
  */
 getSocialMediaLinksList() {
  //Get social  media links list
  this.UserService.fetchSocialMediaLinksList().subscribe((response) => {
    //console.log('dfgdgdfgfd', response);
    this.socialLinks = response;
  });
}


/**
 * Tags inputs 
 */
add(event: MatChipInputEvent): void {
  const input = event.input;
  const value = event.value;

  if(this.fruits.length >= 5)
  {
    return;
  }
  // Add our fruit
  if ((value || '').trim()) {
    this.fruits.push(value.trim());
  }
  console.log('selectedValTags', this.fruits);
  // Reset the input value
  if (input) {
    input.value = '';
  }
  this.fruitCtrl.setValue(null);
}
remove(fruit: string): void {
  const index = this.fruits.indexOf(fruit);
  if (index >= 0) {
    this.fruits.splice(index, 1);
  }
}
selected(event: MatAutocompleteSelectedEvent): void {
  this.fruits.push(event.option.viewValue);
  this.fruitInput.nativeElement.value = '';
  this.fruitCtrl.setValue(null);
  this.filterSearch();
}
private _filter(value: string): string[] {
  const filterValue = value.toLowerCase();
  return this.allFruits.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
}






//search users grid part 
getFirst(speciality:any){
  if(speciality){
    let data = JSON.parse(speciality);
    return data[0];
  }

}


filterSearch() {
  const filterParams = {
    min_price: this.minPrice,
    max_price: this.maxPrice,
    //language: this.selectedLanguage,
    //socialLink: this.selectedSocialLink,
    tags: this.fruits
  };

  this.searchService.advancedFilterSearch(filterParams).subscribe((response) => {
    this.users = response;
  });
}


advancedFilterDataStat()
{
  return;
}

  

}
