<div class="call-box incoming-box">
    <div class="call-wrapper">
        <div class="call-inner">
    <img src="{{data.userData.photoUrl}}">
   
    <form #form="ngForm" (ngSubmit)="confirmGoogleData(form)">
   
     <div class="mb-3">
       <input type="text" class="form-control" placeholder="Nick name"  name="display_name" [(ngModel)]="data.userData.name">
     </div>
       
     <div class="mb-3">
       <input type="text" class="form-control" placeholder="Email adress" name="display_email" [(ngModel)]="data.userData.email">
     </div>
   
     <input type="hidden"  placeholder="Email adress" name="display_image" [(ngModel)]="data.userData.photoUrl">
   
     <div class="submit-section submit-btn-bottom">
        <button type="submit" class="btn btn-primary prime-btn w-100">Confirm Account details</button>
     </div>
   
     <div class="submit-section submit-btn-bottom">
        <button type="submit" class="btn btn-primary prime-btn w-100">Cancel</button>
     </div>
   
   
    </form>
   
   
</div>
</div>
</div>
   