// src/app/wallet-connect.service.ts

import { Injectable } from '@angular/core';

declare var WalletConnect: any; // Declare WalletConnect as a global variable

@Injectable({
  providedIn: 'root'
})
export class WalletConnectService {
  private wc: any;

  constructor() {
    if (typeof WalletConnect === 'undefined') {
      throw new Error('WalletConnect is not available. Make sure it is loaded correctly.');
    }
    this.wc = new WalletConnect({
      bridge: 'https://bridge.walletconnect.org' // Replace with your preferred bridge URL
    });
  }

  async connect() {
    try {
      await this.wc.createSession();
      console.log('WalletConnect session created');
      // Handle session creation success
    } catch (error) {
      console.error('Error creating session:', error);
      // Handle session creation error
    }
  }

  async disconnect() {
    if (this.wc && this.wc.connected) {
      this.wc.killSession();
      console.log('WalletConnect session killed');
      // Handle session termination success
    }
  }

  async signTransaction(transaction: any) {
    try {
      const result = await this.wc.signTransaction(transaction);
      console.log('Transaction signed:', result);
      // Handle transaction signing success
    } catch (error) {
      console.error('Error signing transaction:', error);
      // Handle transaction signing error
    }
  }
}
