import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

   // API path
   base_path = 'https://preprod.paycall.co/index.php/api/wallet';
   current_user = localStorage.getItem('pay_id');

   constructor(private http: HttpClient) { }
 
 
   // Handle API errors
   handleError(error: HttpErrorResponse) {
     if (error.error instanceof ErrorEvent) {
       // A client-side or network error occurred. Handle it accordingly.
       console.error('An error occurred:', error.error.message);
     } else {
       // The backend returned an unsuccessful response code.
       // The response body may contain clues as to what went wrong,
       console.error(
         `Backend returned code ${error.status}, ` +
         `body was: ${error.error}`);
     }
     // return an observable with a user-facing error message
     return throwError(
       'Something bad happened; please try again later.');
   };
 
 
   // Get students data
    getSingleUserWallet(): Observable<any> {
      return this.http
        .get<any>(this.base_path +'/'+this.current_user)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    getWalletHistory(wallet_id:any): Observable<any> {
      return this.http
        .get<any>(this.base_path +'/wallet-history/'+wallet_id)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }


    depositToWallet(wallet_id:any, nbrCoins:any): Observable<any> {
      return this.http
        .get<any>(this.base_path +'/add-coins/'+wallet_id)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }



    createTransaction(userId: any, totalPayment: any): Observable<any> {
      return this.http.get<any>(`${this.base_path}/wallet-max/${userId}/${totalPayment}`);
    }

    createTransactionSender(userId: any, totalPayment: any): Observable<any> {
      return this.http.get<any>(`${this.base_path}/wallet-min/${userId}/${totalPayment}`);
    }



       // Get students data
      getCallReceiverWallet(receiver:number): Observable<any> {
        return this.http
          .get<any>(this.base_path +'/get-receiver-wallet'+this.current_user)
          .pipe(
            retry(2),
            catchError(this.handleError)
          )
      }
 


      /*withdraw(amount: number, recipientEmail: string): Observable<any> {
        const formData = new FormData();
        formData.append('amount', amount.toString());
        formData.append('recipientEmail', recipientEmail);

        console.log(formData);
        return this.http.post<any>(`${this.base_path}/withdrawPayPal`, formData);
      }*/
   
      withdraw(amount: number, recipientEmail: string, chainId: string): Observable<any> {

        const formData = new FormData();
        formData.append('amount', amount.toString());
        formData.append('email', recipientEmail);
        formData.append('chainId', chainId);

        //console.log(formData);
        return this.http.post<any>(`${this.base_path}/widhraw-auto-process/${this.current_user}`, formData);

      }
 
}
 