import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router'; //activated route
import { WalletService } from '../../../../services/apis/wallets/wallet.service';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-widhraw-wallet',
  templateUrl: './widhraw-wallet.component.html',
  styleUrls: ['./widhraw-wallet.component.scss']
})
export class WidhrawWalletComponent implements OnInit {
 
  userWallet:any = [];
  showSearchbar:boolean = false;
  wallet_id!: number;
  transactions:any = [];

  
  current_user = localStorage.getItem('pay_id');

  //form inputs
  stripeTest: FormGroup;
  witdhawAmount:number;
  receiveAmount:number;
  walletSold:number;
  serviceFees:number = 17;
  loader:boolean = false;



  //paypal widhraw configuerations
  amount: number;
  recipientEmail: string;
  chainId: string;
  loading: boolean;
  error: string;
  successMessage: string;



  constructor(
    public walletService: WalletService,
    private fb: FormBuilder, 
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getSingleUserWallet();
  }


  getSingleUserWallet() {
    //Get saved list of students
    this.walletService.getSingleUserWallet().subscribe(response => {
      this.userWallet = response;
      this.walletSold = response.sold;
      this.witdhawAmount = response.sold;
      this.receiveAmount = (response.sold / 100) * 83;
      //console.log('solde', this.witdhawAmount);
    })
  }

  calcAmount()
  {
    if(this.witdhawAmount >= this.walletSold){
      this.witdhawAmount = this.walletSold;
      this.receiveAmount = (this.witdhawAmount / 100) * 83;
    }else{
       this.receiveAmount = (this.witdhawAmount / 100) * 83;
    }
  }
  

  widhrawAmount()
  {
    this.loader = true;
    this.WithdrawTransaction(this.current_user, this.witdhawAmount);
    //return;
  }
 


    //create - transaction 
    WithdrawTransaction(userId, totalPayement){

      return this.http
      .get<any>("https://preprod.paycall.co/index.php/api/wallet/wallet-min/" + userId + "/" + totalPayement)
      .subscribe(data => {  
        this.router.navigate(['/dashboard/wallet/widhraw/success']);
       }, error => {
        console.log(error.message);
      });
   
  }





  //here widhraw system will be done !!
  
  /*withdraw() {
    this.loading = true;
    this.error = null;
    this.successMessage = null;
    

    this.walletService.withdraw(this.receiveAmount, this.recipientEmail).subscribe(
      response => {

        console.log(response);
        this.loading = false;
        if (response.success) {
          this.successMessage = response.message;
          this.toastr.success(response.message);
          this.WithdrawTransaction(this.current_user, this.receiveAmount);
        } else {
          this.error = response.message;
          this.toastr.warning(response.message);
        }
      },
      error => {
        this.loading = false;
        this.toastr.warning('Withdrawal failed. Please try again later.');
      }
    );
  }*/

  withdraw() {
    this.loading = true;
    this.error = null;
    this.successMessage = null;

    //console.log(this.receiveAmount, this.recipientEmail, this.chainId);

    // Validate recipientEmail
    if (!this.recipientEmail) {
      this.loading = false;
      this.error = 'Recipient email is required.'; 
      this.toastr.warning('Recipient wallet adress is required.');
      return;
    }

    if (this.witdhawAmount < 20) {
        this.loading = false;
        this.error = 'The amount must be at least 20 $.';
        this.toastr.warning('The amount must be at least 20 $.');
        return;
    }

    this.walletService.withdraw(this.receiveAmount, this.recipientEmail, this.chainId).subscribe(
        response => {
            this.loading = false;
            console.log(response);

            if (response.error) {
                this.error = response.message;
                this.toastr.warning(response.message);
            } else {
                this.successMessage = response.message;
                this.toastr.success(response.message);
                this.WithdrawTransaction(this.current_user, this.witdhawAmount);
            }
        },
        error => {
            this.loading = false;
            this.toastr.warning('Withdrawal failed. Please try again later.');
        }
    );
}



}
