import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSerializer } from '@angular/router';

import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { ChatComponent } from './pages/chat/chat.component';
import { UserDashboardComponent } from './pages/user-dashboard/user-dashboard.component';
import { SocialMediaComponent } from './pages/user-dashboard/social-media/social-media.component';
import { PhoneHistoryComponent } from './pages/user-dashboard/phone-history/phone-history.component';
import { FavoritesComponent } from './pages/user-dashboard/favorites/favorites.component';
import { ProfileSettingsComponent } from './pages/user-dashboard/profile-settings/profile-settings.component';
import { PrivacyComponent } from './pages/user-dashboard/privacy/privacy.component';
import { VideoVoiceCallComponent } from './pages/chat/video-voice-call/video-voice-call.component';
import { EndCallComponent } from './pages/chat/end-call/end-call.component';
import { VideoCallComponent } from './pages/chat/video-call/video-call.component';
import { WalletComponent } from './pages/user-dashboard/wallet/wallet.component';
import { DepositWalletComponent } from './pages/user-dashboard/wallet/deposit-wallet/deposit-wallet.component';
import { WidhrawWalletComponent } from './pages/user-dashboard/wallet/widhraw-wallet/widhraw-wallet.component';
import { SuccessWithdrawComponent } from './pages/user-dashboard/wallet/success-withdraw/success-withdraw.component';
import { FaqComponent } from './pages/footer/faq/faq.component';
import { ContactComponent } from './pages/footer/contact/contact.component';
import { PolicyComponent } from './pages/footer/policy/policy.component';
import { TermsComponent } from './pages/footer/terms/terms.component';
import { RefundPolicyComponent } from './pages/footer/refund-policy/refund-policy.component';
import { AccessPinComponent } from './security/access-pin/access-pin.component';
import { AuthGuard } from './services/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: UserDashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/social-media-links', component: SocialMediaComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/wallet', component: WalletComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/wallet/deposit', component: DepositWalletComponent},
  { path: 'dashboard/wallet/widhraw', component: WidhrawWalletComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/wallet/widhraw/success', component: SuccessWithdrawComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/calls', component: PhoneHistoryComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/favorites', component: FavoritesComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/settings', component: ProfileSettingsComponent, canActivate: [AuthGuard] },
  { path: 'dashboard/privacy', component: PrivacyComponent, canActivate: [AuthGuard] },
  { path: 'search/:word', component: SearchPageComponent },
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard] },
  { path: 'make-call/:userId/:callType', component: VideoVoiceCallComponent, canActivate: [AuthGuard] },
  { path: 'call-end-page/:roomId/:callTime', component: EndCallComponent, canActivate: [AuthGuard] },
  { path: 'faq', component: FaqComponent },
  { path: 'contact-us', component: ContactComponent },
  { path: 'policy', component: PolicyComponent },
  { path: 'term-and-conditions', component: TermsComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
  { path: 'access-pin', component: AccessPinComponent },
  { path: 'user/:id', component: UserProfileComponent },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })], // Use Hash: false for PathLocationStrategy
  exports: [RouterModule],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy } // Provide PathLocationStrategy
  ]
})
export class AppRoutingModule { }
