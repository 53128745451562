import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/apis/users/user.service';
import { WalletService } from '../../services/apis/wallets/wallet.service';
import { ChatService } from '../../services/apis/chat/chat.service';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { PlatformLocation } from '@angular/common';
import {
  getSupportedInputTypes,
  Platform,
  supportsPassiveEventListeners,
  supportsScrollBehavior,
} from '@angular/cdk/platform';


import { PhantomWalletService } from '../../services/auth/crypto/phantom-wallet.service';
import { MetaMaskWalletService } from '../../services/auth/crypto/metamask.service';


declare const window: any;
declare const ethereum: any;

@Component({
  selector: 'app-web3-modal',
  templateUrl: './web3-modal.component.html',
  styleUrls: ['./web3-modal.component.scss']
})
export class Web3ModalComponent implements OnInit {



  supportedInputTypes = Array.from(getSupportedInputTypes()).join(', ');
  supportsPassiveEventListeners = supportsPassiveEventListeners();
  supportsScrollBehavior = supportsScrollBehavior();

  blockNumber: any;
  metamaskInstalled: boolean = false;
  isMobile: boolean = false;





  publicKey: string | null = null;

  constructor(
    public userService: UserService,
    public walletService: WalletService,
    public chatService: ChatService,
    private toastr: ToastrService,
    private platformLocation: PlatformLocation,
    public platform: Platform,
    private phantomWalletService: PhantomWalletService,
    //private metaMaskService: MetaMaskWalletService
  ) { }

  ngOnInit(): void {
    this.checkMetamaskInstalled();
  }

    private isMobileDevice(): boolean {
      return this.platform.ANDROID;
    }

  checkMetamaskInstalled() {
    if (typeof window.ethereum !== 'undefined') {
      this.metamaskInstalled = true;
    } else {
      this.metamaskInstalled = false;
    }
  }
    openMetamask() {
      if (this.isMobileDevice()) { 
        this.redirectToMetamask();
      } else {
        if (this.metamaskInstalled) {
          this.connectAccount();
        } else {
          this.toastr.warning('MetaMask is not installed. Please install MetaMask and try again.');
        }
      }
    }
    

  async connectAccount() {
    try {
      const permission = await window.ethereum.request({ method: "wallet_requestPermissions", params: [{ eth_accounts: {} }] });
      const accounts = await ethereum.request({ method: 'eth_accounts' });
      this.blockNumber = accounts[0];
      this.createNewUser(this.blockNumber);
    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
    }
  }

  redirectToMetamask() {
    const dappUrl = encodeURIComponent(window.location.href); // URL of your dApp
    const metamaskAppDeepLink = `https://metamask.app.link/dapp/${dappUrl}`;
    window.location.href = metamaskAppDeepLink;
    //window.location.href="https://metamask.app.link/dapp/https://app.paycall.org/home";
  }

  createNewUser(walletId: any) {
    this.userService.createSingleUser(walletId).subscribe(response => {
      localStorage.setItem('pay_id', response.pay_call_id);
      localStorage.setItem('pay_user_id', response.id);
      localStorage.setItem('isLoggedIn', 'true');    
      window.location.href = "/"; 
    }, error => {
      console.error('Error creating new user:', error);
    });
  }
   

  async connectPhantomWallet() {
    await this.phantomWalletService.connectWallet();
    this.publicKey = this.phantomWalletService.getPublicKey()?.toString() || null;
    if(this.publicKey != null){
      this.createNewUser(this.publicKey);
    }
  }

  /*async connectToMetaMask(): Promise<void> {
    await this.metaMaskService.connectWallet();
    // Further logic after connecting to MetaMask
  }*/

}
