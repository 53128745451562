<app-layout-header></app-layout-header>

<div class="breadcrumb-bar-two">
    <div class="container">
    <div class="row align-items-center inner-banner">
    <div class="col-md-12 col-12 text-center">
    <h2 class="breadcrumb-title">Terms & Condition</h2>
    <nav aria-label="breadcrumb" class="page-breadcrumb">
    <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="index.html">Home</a></li>
    <li class="breadcrumb-item" aria-current="page">Terms & Condition</li>
    </ol>
    </nav>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="terms-section">
    <div class="container">
    <div class="row">
    <div class="col-md-12">
    <div class="terms-content">
         
                <p>
                    <strong></strong>
                </p>
                <p>
                    <strong>Welcome  to PayCall!</strong>
                </p>
                <p>
                    <a name="_Hlk160322770">
                        PayCall  is owned and operated by PayCall, Inh. Ayari.
                    </a>
                </p>
                <p>
                    These are the terms and conditions  for:
                </p>
                <p>
                    <a name="_Hlk158240321"></a><a name="_Hlk131209605"></a><a name="_Hlk111335702"></a><a name="_Hlk130303528">·
                    <strong>PayCall</strong></a><strong>mobile application</strong><strong>(Available 
                    on Google Play and App Store).</strong> <strong></strong>
                </p>
                <p>
                    <a name="_Hlk160322691">·</a><a href="https://www.paycall.co/"><strong>https://www.paycall.co</strong></a>
                    <strong></strong>
                </p>
                <p>
                    By using the platform, you agree to  these terms and conditions and our
                    privacy policy. In these terms and  conditions, the words  "platform" refers
                    to the PayCall mobile application and the PayCall  website together,  "we",
                    "us", "our", and "PayCall" refers  to PayCall, and "you" and "user", refers
                    to you, the PayCall  user(Specialists and Clients).
                </p>
                <p>
                    The following terms and conditions  apply to your use of the platform. This
                    includes mobile and tablet versions, as  well as any other version of
                    PayCall accessible via desktop, mobile, tablet,  social media or other
                    devices.
                </p>
                <p>
                    PLEASE READ THESE CONDITIONS  CAREFULLY BEFORE DOWNLOADING AND USING THE
                    FUNCTIONALITIES AVAILABLE ON THE  PLATFORM.
                </p>
                <p>
                    <strong>1.  INTRODUCTION</strong>
                </p>
                <p>
                    PayCall Inh. Ayari, registered in  Switzerland under number
                    CH-300.1.021.086-6 and with unique registration code  CHE-495.776.331, has
                    its registered office at Gossauerstrasse, number 25,  Herisau, zip code
                    9100. This legal entity is the owner and developer of the  PayCall online
                    platform, which is available in both the App Store and Google  Play. As a
                    technology developer, PayCall Inh. Ayari does not act as a Specialist  on
                    its platform. Instead, the responsibility for the services provided through
                    the platform lies directly between the Client and the Specialist, with the
                    main  function of the platform being to facilitate Users' access to such
                    services.
                </p>
                <p>
                    PayCall's technology service  offering extends to both Clients and
                    Specialists, providing an online/virtual  environment and technical
                    solutions that facilitate interaction between these  two parties
                </p>
                <p>
                    . With a commitment to providing the  best possible user experience,
                    PayCallensures the use of the latest  technologies to guarantee optimal
                    security and confidentiality conditions. This  includes protecting all
                    communications using the SSL standard, encrypting all  interactions between
                    platform users so that only they can access them, and  conducting online
                    meetings in real time over secure connections, without  PayCall or third
                    parties being able to access or record these meetings.
                </p>
                <p>
                    Users are urged to carefully read  the terms and conditions, which apply to
                    all PayCall Content as defined,  available within the platform. If you do
                    not agree to these terms, it is  recommended that you do not use the
                    platform.
                </p>
                <p>
                    <strong>2. ACCEPTANCE  OF TERMS</strong>
                </p>
                <p>
                    By accessing and using the PayCall  platform, you acknowledge that you have
                    read, understood and agree to be  legally bound by the terms and conditions
                    set forth in this document, which  constitute a binding legal agreement
                    between you and PayCall. This agreement  governs both your access to and use
                    of the PayCall platform and is applicable  to all interactions between users
                    (Clients and Specialists) within the  platform, including, but not limited
                    to, visiting profiles, exchanging  messages, sending files, booking and
                    participating in online meetings or  consultations.
                </p>
                <p>
                    You agree that, by choosing to  interact on the PayCall platform, you submit
                    to the rules described herein. If  you do not agree to these terms, you are
                    prohibited from using the platform and  accessing the information contained
                    therein. Failure to comply with these terms  may result in civil and
                    criminal penalties.
                </p>
                <p>
                    The PayCall platform facilitates an  online connection where Specialists can
                    offer and Clients can consume and pay  for services via online video/audio
                    calls. It is important to recognize that  PayCall:
                </p>
                <p>
                    ·          Is not a party to the agreement between Clients  and Specialists;
                </p>
                <ul>
                    <li>
                        Does not provide services on behalf of PayCall;
                    </li>
                </ul>
                <p>
                    ·          Has no control over the services provided by  Specialists to
                    users; Does not provide services on behalf of PayCall; Has no  control over
                    the services provided by Specialists to users;
                </p>
                <p>
                    ·          Does not monitor the behavior of Specialists,  Clients or other
                    users within the platform.
                </p>
                <p>
                    Given the above, PayCall disclaims  any liability, to the maximum extent
                    permitted by law. By providing services on  PayCall, you identify yourself
                    as an independent user, not as an employee,  agent or associate of PayCall,
                    acting on your own behalf and for your own  benefit, without implying any
                    association or certification by PayCall. You  agree not to give a false
                    impression of being certified or associated with  PayCall, respecting its
                    intellectual property.
                </p>
                <p>
                    You may only present yourself as a  verified specialist on the platform if
                    you so request and if you are accredited  by the competent authorities to
                    legally offer such a service. Your use of the  platform, including
                    accessing, downloading, publishing or consuming content,  indicates your
                    acceptance of these terms and conditions, this being the only  way to
                    benefit from PayCall's services, whether you have registered an account  or
                    not. If you do not agree, you are not entitled to use the PayCall platform
                    or its content.
                </p>
                <p>
                    <strong>3.  ELIGIBILITY</strong>
                </p>
                <p>
                    By accessing and using the PayCall  platform, you affirm and warrant that
                    you have reached the age of majority, set  at 18 years of age, thus
                    confirming your eligibility to use the services  offered. The use of PayCall
                    by individuals under the age of 18 is expressly  prohibited and subject to
                    strict restrictions.
                </p>
                <p>
                    PayCall reserves the right, to the  extent permitted by applicable law in
                    each country, state or territory, to  collect, verify and use information
                    obtained from public sources regarding the  criminal history, convictions
                    and other criminal records of PayCall users. This  includes, but is not
                    limited to, crimes of any nature, including sexual  offenses.
                </p>
                <p>
                    You grant PayCall permission to use  your personal information, such as
                    first and last names, for the purpose of  acquiring this information, either
                    directly by PayCall or through its vendors.  This clause underlines the
                    importance of maintaining a safe and secure  environment for all users of
                    the platform.
                </p>
                <p>
                    <strong>4.  NOTIFICATIONS</strong>
                </p>
                <p>
                    By providing your email address and  phone number to PayCall, you agree that
                    we may use your email address and phone  number to send you notifications
                    and other messages, such as changes to service  features, news and special
                    content via email and SMS messages. If you do not  wish to receive these
                    emails or SMS messages, you may opt-out of receiving them  by submitting
                    your unsubscribe request through the contact information or by  using the
                    "unsubscribe" option included in the emails. Unsubscribing  may prevent you
                    from receiving notifications about updates, news or special  content related
                    to PayCall.
                </p>
                <p>
                    <a name="_Hlk23344345"></a>
                </p>
                <p>
                    <strong>5.SPECIALISTS</strong>
                </p>
                <p>
                    <a name="_Hlk79451410"><strong>5.1. User  Profile</strong></a>
                </p>
                <p>
                    You agree to provide accurate,  current and complete information when
                    registering as a Specialist on the  PayCall platform and agree to keep such
                    information up to date at all times.  The registration process involves
                    providing details such as your  specialization, the per-minute rate for
                    audio and audio-video sessions, and you  agree to keep these rates
                    consistent with those you apply in similar situations  outside the platform.
                </p>
                <p>
                    You understand that it is your  responsibility to ensure that the rates
                    reported do not differ from your usual  rates and acknowledge that PayCall
                    has the right to verify the accuracy of  these rates under the terms and
                    conditions stipulated herein. You further agree  that PayCall users may
                    report any discrepancies in your rates, and that PayCall  has the authority
                    to suspend or delete your account without prior notice if  irregularities
                    are detected.
                </p>
                <p>
                    You are solely responsible for the  content you choose to share on PayCall
                    and the services you offer. PayCall  reserves the right to verify the
                    authenticity and legality of your account and  services at any time, which
                    includes ensuring that your specialization is not  prohibited by local law,
                    that your profile information is correct and truthful,  and that your
                    profile picture is an accurate portrayal of the Specialist.
                </p>
                <p>
                    In case PayCall detects any anomaly  or non-compliance with the established
                    recommendations, it may deactivate your  Specialist profile without the need
                    to provide additional explanations. If you  would like PayCall to verify the
                    legality of your activity according to local  legislation, you can request
                    it through the contact section of the platform.  However, it is important to
                    note that not all services offered on PayCall need  to be regulated by the
                    legislation of the place where they are provided, and  therefore some
                    verification requests may be unnecessary.
                </p>
                <p>
                    <strong>5.2. Account</strong>
                </p>
                <p>
                    When choosing to register with PayCall as a Specialist, the user must
                    complete a registration process and fill out a specific form provided on our
                    platform. During registration, users have the option to log in using their
                    phone number, Google or Facebook account, which facilitates access and use
                    of  our services.
                </p>
                <p>
                    Once registration is completed, the user will be assigned a unique  PayCall
                    account along with a personal ID, consisting of a random 9-digit  sequence,
                    e.g. 100,100,100, intended to securely identify the user within our
                    platform.
                </p>
                <p>
                    The security and confidentiality of this account rests solely in the  hands
                    of the user. It is your responsibility to protect your login information
                    and to monitor any activity that takes place under your account. If any
                    unauthorized use or breach of security is detected, you agree to notify
                    PayCall  immediately and to log off at the end of each use to prevent
                    unauthorized  access. The use of third party accounts without PayCall's
                    express authorization  is strictly prohibited and may result in corrective
                    action.
                </p>
                <p>
                    Users are free to cancel their accounts at any time, for any reason,  either
                    through the account settings or by direct request to our customer  service.
                    Account cancellation results in the removal of all personal data  associated
                    with the account from our records.
                </p>
                <p>
                    PayCall implements a policy of purging inactive accounts, proceeding to
                    delete those that have not registered activity for a continuous period of
                    one  year. In such cases, any remaining balance in the account will be
                    acquired by  PayCall.
                </p>
                <p>
                    We reserve the right to terminate access or cancel the account of any  user,
                    immediately and without prior notice, in the event that it is determined
                    that there have been violations of the terms and conditions of use or that
                    false or misleading information has been provided to PayCall. This measure
                    seeks to preserve the integrity and security of our platform and its users.
                </p>
                <p>
                    It is important to remember that maintaining the quality and security of
                    PayCall is a shared effort. As such, we urge all of our users to employ
                    secure  account management practices, such as regularly updating passwords
                    and  verifying active sessions. In addition, PayCall is committed to using
                    the  information provided by users in a responsible manner, ensuring their
                    privacy  and protection of personal data, in accordance with applicable
                    privacy and data  protection regulations.
                </p>
                <p>
                    <strong>5.3. Services</strong>
                </p>
                <p>
                    Once you have completed the process of creating your Specialist profile,
                    your account will be activated and online, allowing you to receive calls
                    from  Clients. You will have the freedom to be available online or offline
                    according  to your preference. Being offline means that you will not be able
                    to be  contacted by Clients, although they will still be able to access your
                    information on the platform. In addition, upon completion of your profile
                    creation, it will be made public and will be visible in the search lists of
                    all  clients.
                </p>
                <p>
                    However, you have the option to set your account as private. By doing  so,
                    your profile will only be visible to those Clients who have added you using
                    a unique code assigned by the platform and that you have shared with them.
                    This  Specialist code can be found in the "My Profile" section, under the
                    private account option, and can be in the form of a text string or QR code,
                    randomly generated by the platform.
                </p>
                <p>
                    It is possible to switch between a public and private account at any  time.
                    It is important that you understand and accept that the way your profile
                    and services are displayed in Clients' search results may be influenced by a
                    number of factors. These include Client preferences, reviews and ratings
                    received, your online availability at the time of the Client's search, your
                    history and frequency of online presence, as well as the number of meetings
                    or  calls made through the platform.
                </p>
                <p>
                    <strong>5.4. Commissions</strong>
                </p>
                <p>
                    In the context of the operations and services offered through the  PayCall
                    platform, it is important that all Specialists are aware of the  applicable
                    commissions for the use of the platform. PayCall establishes a 17%
                    commission on the revenues generated by Specialists as a result of the
                    services  provided through the platform. This commission is deducted as
                    compensation for  the use of technology, infrastructure, access to clientele
                    and support provided  by PayCall.
                </p>
                <p>
                    In addition to the aforementioned commission, PayCall reserves the right  to
                    apply additional fees or taxes, such as Value Added Tax (VAT), when required
                    by applicable laws and regulations. This is done to ensure full compliance
                    with  relevant tax obligations, both locally and internationally, depending
                    on the  jurisdiction of the services provided.
                </p>
                <p>
                    The PayCall platform is committed to maintaining full transparency
                    regarding commissions, discounts and any other applicable fees. All
                    Specialists  will have access to detailed and up-to-date information on
                    these fees through  their account on the online platform, allowing for
                    efficient management and  proper financial planning of their activities
                    within PayCall.
                </p>
                <p>
                    It is critical for PayCall to ensure that this commission structure is
                    clearly understood by Specialists, so that they can make informed decisions
                    about their participation in the platform. PayCall encourages all
                    Specialists  to periodically review the commission-related terms and
                    conditions, as well as  any updates that may arise, to ensure a successful
                    and mutually beneficial  collaboration.
                </p>
                <p>
                    PayCall's goal in establishing these commissions is to foster an  equitable
                    and sustainable environment that benefits both Specialists and  Clients by
                    providing a platform that facilitates high quality professional  encounters.
                    PayCall strives to reinvest in the continuous improvement and  development
                    of the platform in order to provide the best possible experience  for all
                    its users. The acceptance of these commissions and fees is an essential
                    component of the agreement between PayCall and the Specialists who choose to
                    use our platform to offer their services.
                </p>
                <p>
                    <strong>5.5. Billing and withdrawals</strong>
                </p>
                <p>
                    In the PayCall platform, each transaction performed by Specialists for
                    services rendered to Clients is meticulously documented, ensuring
                    transparent  and efficient financial management. At the end of each meeting,
                    a fiscal  invoice is automatically issued within the platform, reflecting
                    the total amount  owed by the Client to the Specialist for the services
                    provided. This invoice,  in addition to detailing the services, will include
                    applicable taxes, such as  Value Added Tax (VAT), in accordance with current
                    tax legislation.
                </p>
                <p>
                    The duration of each meeting is meticulously recorded in the platform,
                    indicating the total and partial time elapsed, from the moment of its start
                    to  its conclusion. The platform also accurately documents the start and end
                    date  and time of each meeting, thus providing a clear reference for
                    invoicing and  subsequent payment.
                </p>
                <p>
                    Specialists have access to a detailed history of each meeting, where  they
                    can view essential information such as start time, duration, cost of
                    services rendered and the current status of any withdrawal requests. In
                    addition,  to ensure effective communication and full transparency, invoices
                    generated at  the end of each meeting are automatically emailed to both
                    parties involved.
                </p>
                <p>
                    Once the Client makes payment for the services received, PayCall  proceeds
                    to credit the corresponding amount to the Specialist's account, minus
                    PayCall's applicable commission. Specialists are free to review the
                    accumulated  balance in their account at any time and request the withdrawal
                    of funds to  their personal bank account as they wish.
                </p>
                <p>
                    PayCall is committed to processing these withdrawal requests in an
                    expeditious manner, ensuring that payment of the credited balance is made
                    within a maximum of two business days of the withdrawal request. The
                    transfer  of funds to the Specialists will be made through Stripe, a trusted
                    and  internationally recognized payment solution, thus ensuring a secure and
                    efficient payment process.
                </p>
                <p>
                    Importantly, PayCall strives to provide a safe and reliable working
                    environment for Specialists, promoting fair and transparent practices in all
                    financial transactions. The platform strictly adheres to applicable
                    financial  and tax regulations, facilitating compliance with the tax and
                    financial  obligations of Specialists, who must be equally committed to
                    meeting their  individual tax responsibilities.
                </p>
                <p>
                    PayCall, through its collaboration with Stripe and the use of advanced
                    payment management technologies, positions itself as a strategic ally for
                    Specialists, allowing them to focus on delivering high quality services
                    while  efficiently managing their finances and meeting their tax
                    obligations.
                </p>
                <p>
                    <strong>5.6. Taxes</strong>
                </p>
                <p>
                    Registered and active PayCall Specialists have sole responsibility for
                    managing, reporting and paying taxes on income earned through the provision
                    of  their services on the platform.
                </p>
                <p>
                    This responsibility includes, but is not limited to, knowledge of the
                    applicable tax laws in their country of residence or in any jurisdiction
                    where  Specialists are required to comply with tax obligations. Specialists
                    must  ensure that all of their transactions and earnings through PayCall are
                    properly  reported to the appropriate tax authorities in accordance with
                    applicable laws  and regulations.
                </p>
                <p>
                    PayCall assumes no responsibility for the tax management of Specialists,
                    nor will it provide direct tax advice. The platform is not responsible for
                    withholding, reporting or paying taxes on behalf of the Specialists.
                    Therefore,  it is imperative that each Specialist take the necessary steps
                    to understand  their tax obligations and act accordingly, seeking
                    professional advice if  necessary.
                </p>
                <p>
                    Any questions or concerns relating to tax management should be directed  to
                    a qualified tax advisor or the relevant tax authorities. Specialists are
                    encouraged to maintain accurate and detailed records of all transactions and
                    services provided through PayCall to facilitate compliance with their tax
                    responsibilities.
                </p>
                <p>
                    By registering and offering services on PayCall, Specialists acknowledge
                    and accept this responsibility, and agree to act in a manner compliant with
                    all  applicable tax laws. PayCall reiterates its commitment to operate in a
                    transparent and legally compliant manner, providing a secure and reliable
                    platform for all its users.
                </p>
                <p>
                    <strong>5.7. User Content</strong>
                </p>
                <p>
                    Specialists may provide and upload content and information through the
                    platform. Specialists retain any copyright or other rights they may have in
                    the  content and information they provide through the platform. PayCall is
                    not  responsible for the accuracy, safety or legality of content posted on
                    the  platform by Specialists. The Specialist is solely and exclusively
                    responsible  for its content and the consequences of its publication. By
                    providing content  and information through the platform, the Specialist
                    grants PayCall a  worldwide, non-exclusive, royalty-free, fully paid-up
                    right and license to host,  store, transfer, perform, reproduce and modify
                    the content, for the sole  purpose of using and displaying it within the
                    platform and to the general  public.
                </p>
                <p>
                    By providing and uploading content through the platform, you affirm,
                    represent and warrant that:
                </p>
                <p>
                    a)      You own the  content you provide through the platform, or have the
                    necessary licenses,  rights, consents and permissions to authorize PayCall
                    to publish and display  your content through the platform.
                </p>
                <p>
                    b)      Your content  does not infringe, violate or misappropriate any third
                    party right, including  any copyright, trademark, patent, trade secret,
                    moral right, right of privacy,  right of publicity or any other intellectual
                    property or proprietary right.
                </p>
                <p>
                    c)       Your content  does not defame, libel, slander, libel or invade the
                    right of privacy,  publicity or other proprietary rights of any other
                    person.
                </p>
                <p>
                    d)      Your content  may not be considered by a reasonable person to be
                    objectionable, profane,  indecent, pornographic, harassing, threatening,
                    embarrassing, hateful or  otherwise inappropriate.
                </p>
                <p>
                    e)      Your content  does not and will not contain content that incites
                    hatred, threats of physical  harm or harassment.
                </p>
                <p>
                    <strong>5.8. Monitoring of Content</strong>
                </p>
                <p>
                    PayCall may, at any time and without prior notice, filter, remove, edit  or
                    block any Specialist content that, in our sole judgment, violates these
                    Terms or is otherwise objectionable. If any user or third party notifies us
                    that Specialist content allegedly does not conform to these terms, we may
                    investigate the allegation and determine in our sole discretion whether to
                    remove the relevant Specialist content, which we reserve the right to do at
                    any  time without notice. Specialist acknowledges and agrees that PayCall
                    reserves  the right to, and may from time to time, monitor all information
                    transmitted or  received through the platform for operational and other
                    purposes.
                </p>
                <p>
                    <strong>6. CLIENTS</strong>
                </p>
                <p>
                    <strong>6.1.  Account</strong>
                </p>
                <p>
                    When registering with PayCall as a Client, you will be asked to complete  a
                    registration form and follow an established process to create your personal
                    account on the platform. This account allows Clients to access the services
                    offered by the Specialists through PayCall, using their phone number, Google
                    or  Facebook account, thus facilitating a convenient and secure method of
                    access  and use.
                </p>
                <p>
                    Each Client, upon completion of registration, will receive a unique  PayCall
                    ID, consisting of a randomly generated 9-digit ID, e.g.  100,100,100,100.
                    This ID is personal and non-transferable, serving as a means  of
                    identification within our platform.
                </p>
                <p>
                    It is of utmost importance that Clients maintain the confidentiality of
                    their account and are responsible for any activity performed under their
                    account. In this regard, the Client is expected to (a) immediately notify
                    PayCall of any unauthorized use of their account or any other breach of
                    security, and (b) ensure that they log off at the end of each use to protect
                    their privacy. Use of third party accounts without PayCall's express
                    authorization is strictly prohibited.
                </p>
                <p>
                    Clients may request cancellation of their account at any time and for  any
                    reason, either through their account settings on the platform or by sending
                    a direct request through PayCall's contact channels. Account termination
                    will  result in the permanent deletion of all personal data associated with
                    the  Client's account from PayCall's records.
                </p>
                <p>
                    PayCall adopts a policy of deleting inactive accounts, proceeding to  delete
                    those accounts that show no activity for a continuous period of one  year.
                    Any outstanding balance on the account at the time of deletion will be
                    acquired by PayCall.
                </p>
                <p>
                    We reserve the right to terminate or suspend access to a Client's  account,
                    with or without notice, and without liability to the Client, in the  event
                    that a violation of the terms of use is identified or the provision of
                    false or misleading information to the platform is detected.
                </p>
                <p>
                    In addition, PayCall is committed to protecting the privacy and security  of
                    Customers' personal data, applying secure practices in the handling and
                    storage of this information and complying with current data protection
                    legislation.
                </p>
                <p>
                    <strong>6.2. Buying meetings</strong>
                </p>
                <p>
                    At PayCall, we offer our Clients the ability to connect with and benefit
                    from the knowledge and skills of a wide range of Specialists through our
                    platform. Clients have the freedom to purchase and schedule calls, video
                    calls,  as well as send text and voice messages to Specialists, allowing for
                    flexible  interaction tailored to their specific needs.
                </p>
                <p>
                    When browsing PayCall, Clients can explore in detail the profiles of
                    available Specialists, which include valuable information such as their
                    areas  of expertise, the services they offer, their qualifications and
                    reviews left by  other users. This information is designed to help Clients
                    make informed decisions  by selecting the Specialist that best suits their
                    requirements based on  experience, expertise and quality of service.
                </p>
                <p>
                    Once a Client chooses a Specialist, they can proceed to purchase the
                    desired services, including scheduling a session at a time that best suits
                    both  parties. PayCall facilitates this process, ensuring a smooth
                    transaction and  providing a secure space for both Clients and Specialists
                    to communicate  effectively.
                </p>
                <p>
                    It is important to note that PayCall strives to maintain a professional  and
                    respectful environment. Therefore, we expect all Clients to adhere to our
                    standards of conduct, showing respect and courtesy towards Specialists
                    during  all interactions. Any behavior that violates our policies of mutual
                    respect may  result in suspension or termination of access to platform
                    services.
                </p>
                <p>
                    In addition, PayCall provides tools and functionalities that allow  Clients
                    to leave reviews and rate the quality of services received from
                    Specialists. These reviews are crucial, as they contribute to the
                    transparency  of the platform and help other Clients in their
                    decision-making processes. We  encourage Clients to share their experiences
                    in a constructive manner, thus  helping to maintain and improve the quality
                    of services offered on PayCall.
                </p>
                <p>
                    PayCall is committed to providing an exceptional experience to all its
                    users, facilitating access to quality services and promoting valuable
                    interactions between Clients and Specialists. Our goal is for the PayCall
                    platform to be a space where personal growth, learning and professional
                    development are encouraged, benefiting all members of our community.
                </p>
                <p>
                    <strong>6.3. Credits and Payments</strong>
                </p>
                <p>
                    At PayCall, we provide our Clients with a flexible and convenient credit
                    system to facilitate payment for services received from Specialists. When
                    credits  are purchased, they will be added to your account and will be
                    available to  cover the cost of meetings. It is important to note that
                    credits purchased are  non-refundable and that there is no limit to the
                    number of credits a Client can  purchase, thus providing ample flexibility
                    in the use of our services.
                </p>
                <p>
                    As part of our commitment to an efficient user experience, payments to
                    Specialists for services rendered will be made automatically by debiting the
                    corresponding credits from their account balance immediately after the
                    conclusion of each meeting. This operation guarantees a smooth and
                    uninterrupted transaction, allowing Clients and Specialists to fully
                    concentrate on the value of the professional interaction.
                </p>
                <p>
                    The total amount payable for each meeting may be influenced by several
                    factors, including, but not limited to, the applicable Value Added Tax
                    depending on the nature of the service received and the tax status of the
                    Client, whether as an individual or a legal entity and its VAT status. These
                    elements are considered to ensure that the invoicing process complies with
                    current tax regulations and accurately reflects the cost of the service.
                </p>
                <p>
                    Upon completion and successful payment of a meeting, PayCall will send  the
                    Client an email notification, detailing all relevant information about the
                    completed session, including, but not limited to, the time spent and the
                    total  cost of the service. In addition, Clients will have access to a
                    meeting  history, where they will be able to view details of each session,
                    and will  receive invoices for meetings via email, ensuring transparency and
                    easy access  to financial information.
                </p>
                <p>
                    In exceptional cases, where a refund is required in accordance with
                    PayCall's Terms and Conditions, we will automatically credit credits
                    equivalent  to the full value of the Specialist's invoice back to the
                    Client's account.  This process ensures that any necessary adjustments are
                    handled efficiently,  safeguarding the interests of both Clients and
                    Specialists.
                </p>
                <p>
                    PayCall is committed to providing a secure and reliable environment for  all
                    financial transactions, using state-of-the-art payment systems to protect
                    our users' information and funds. Our goal is to ensure that the process of
                    purchasing credits, paying for services and, where applicable, refunding
                    credits, is conducted with maximum security, efficiency and compliance with
                    applicable tax regulations. Clients and Specialists can rely on PayCall to
                    handle their financial transactions with the utmost diligence and
                    professionalism, always maintaining clarity and transparency in all
                    operations.
                </p>
                <p>
                    <strong>6.4. Billing</strong>
                </p>
                <p>
                    On the PayCall platform, the commitment to transparency and fairness in
                    transactions between Clients and Specialists is a priority. To this end, it
                    is  important to note how the billing of the services offered is structured,
                    in  particular calls and video calls.
                </p>
                <p>
                    Although the price of these services is presented and displayed in terms  of
                    cost per minute to make it easier for users to understand, the calculation
                    of the invoice is done more precisely, based on the exact duration of each
                    call  or video call, measured in seconds. This means that the Client will
                    pay the  cost corresponding to each second of service used, ensuring a fair
                    billing  proportional to the actual time of use.
                </p>
                <p>
                    This billing methodology in seconds allows for greater flexibility and
                    adaptability, avoiding rounding to the nearest full minute and, therefore,
                    possible excessive charges for unused time. In this way, PayCall ensures a
                    more  accurate match between the service received and the amount billed,
                    accurately  reflecting the value of the services provided.
                </p>
                <p>
                    It is the Client's responsibility to review and understand the pricing
                    structure prior to using any service on PayCall, including how the bill is
                    calculated. PayCall is committed to providing all necessary information in a
                    clear and accessible manner to ensure that Clients are fully informed about
                    billing conditions.
                </p>
                <p>
                    <strong>7. TARIFFS</strong>
                </p>
                <p>
                    In the PayCall platform, each account is associated with a pricing
                    structure determined in US dollars, which facilitates a clear understanding
                    of  the rates applicable to the different services offered. Within this
                    structure,  the following standard rates are established for interactions
                    between Clients  and Specialists:
                </p>
                <p>
                    ·          For each minute of call, a rate of US$0.20 will  be charged.
                </p>
                <p>
                    ·          Video calls will cost $0.50 for each minute of  duration.
                </p>
                <p>
                    ·          Sending messages will have a unit cost of 0.05  dollars.
                </p>
                <p>
                    ·          For each voice message sent, a fee of 0.10  dollars will be
                    applied.
                </p>
                <p>
                    It is important to note that both the specified price and currency are
                    subject to change according to market fluctuations and PayCall's internal
                    policies. This flexibility allows Specialists the freedom to set their own
                    rates for the services they offer through the platform, and may even choose
                    to  offer services for free if they wish.
                </p>
                <p>
                    PayCall is committed to keeping all users informed of any changes in  rates
                    or currency used, ensuring total transparency in the transactions made
                    within the platform. Changes to the pricing structure will be communicated
                    reasonably in advance, allowing users to adjust their pricing strategies or
                    make informed decisions regarding the use of PayCall's services.
                </p>
                <p>
                    In addition, PayCall provides Specialists with tools and options to  manage
                    their rates efficiently, including the ability to apply discounts,
                    promotions or special rates for certain services or time periods. This not
                    only  increases flexibility for Specialists in terms of revenue management,
                    but also  enriches the Customer experience by providing them with a greater
                    variety of options  tailored to their needs and financial possibilities.
                </p>
                <p>
                    Finally, it is critical that both Clients and Specialists periodically
                    review PayCall's rate policies and stay abreast of any updates or changes
                    that  may affect their activities within the platform. PayCall strives to
                    provide a  fair, competitive and transparent environment, promoting valuable
                    and  satisfying interactions between all members of its community.
                </p>
                <p>
                    <strong>8. MEETINGS</strong>
                </p>
                <p>
                    <strong>8.1. Before the Meeting</strong>
                </p>
                <p>
                    Prior to the meeting, it is crucial to understand and accept the  conditions
                    required for online sessions in PayCall, where audio and audio-video
                    interactions between Spatialist and Client are fundamental. Therefore, you
                    are  expected to:
                </p>
                <p>
                    ·          Have a mobile device with the necessary  hardware, compatible
                    with PayCall for streaming audio-video content;
                </p>
                <p>
                    ·          Have a stable, high-speed Internet connection  suitable for
                    handling video and audio. Check with your Internet provider that  your
                    network is capable of supporting this type of content;
                </p>
                <p>
                    ·          In case of using mobile data, make sure that  your 4G connection
                    is stable and fast enough by checking with your provider the  limits and
                    capabilities of your data plan;
                </p>
                <p>
                    ·          The responsibility for ensuring the proper  functioning of the
                    equipment needed to optimally conduct meetings rests with  you. This
                    includes verifying compatibility and Internet connection prior to the
                    meeting;
                </p>
                <p>
                    ·          If technical problems arise that prevent you  from starting,
                    participating in or continuing with a meeting on PayCall, the
                    responsibility rests solely with you, and PayCall Inh. Ayari is released
                    from  any liability in this regard;
                </p>
                <p>
                    ·          Please note that in the event of a malfunction,  payment to the
                    Specialist may not be made, or the full amount invoiced to the  Client may
                    be automatically refunded.
                </p>
                <p>
                    <strong>8.2. Initiation</strong>
                </p>
                <p>
                    During your online availability as a Specialist, you are open to receive
                    calls from Clients to start Meetings at any time. Once a Meeting is started
                    with two participants, it is considered active. It is important that you
                    understand, acknowledge and accept the following:
                </p>
                <p>
                    ·          Only connected participants in the Meeting can  view and listen
                    to the video and audio stream, excluding third parties from  accessing it;
                </p>
                <p>
                    ·          Both Specialists and Clients must make every  effort and take the
                    necessary steps to fulfill the purpose of the Meeting,  ensuring its success
                    and justifying the payment made for it;
                </p>
                <p>
                    ·          The online sessions are not recorded either by  PayCall or by any
                    of its partners, facilitating only direct communication  between the Users'
                    devices. Therefore, the quality of the video and audio  depends exclusively
                    on the equipment used by the User and PayCall assumes no  responsibility for
                    this quality. The devices used to access the Meeting  directly influence the
                    quality of the content transmitted;
                </p>
                <p>
                    ·          Abuse in the use of the functions to disable  the video or
                    microphone is prohibited. Meetings are expected to run with a  continuous
                    transmission of video and audio to achieve the stated purpose,  without
                    interruption;
                </p>
                <p>
                    ·          Disruptive, abusive or harassing behavior  during the use of the
                    PayCall platform, as well as in meetings, whether online  or face-to-face,
                    is prohibited;
                </p>
                <p>
                    ·          Commits not to share, publish or transmit audio  or video content
                    that violates intellectual property rights, promotes  discrimination,
                    racism, hatred, contains defamatory, obscene or pornographic  material, or
                    encourages illegal activities. In addition, unauthorized  recording, storage
                    or distribution of Meeting content in any format is strictly  prohibited.
                </p>
                <p>
                    <strong>8.3. Completion</strong>
                </p>
                <p>
                    The end of a Meeting within the <a name="_Hlk160502779">PayCall</a>platform
                    is considered effective when the purpose for which it was organized has been
                    fulfilled, thus ensuring that both Specialists and Clients are committed to
                    participate for the time necessary to achieve that purpose. It is essential
                    to  understand and accept that:
                </p>
                <p>
                    ·          Both Specialists and Clients are expected to  make every effort
                    and take whatever action is necessary to achieve the  objective of the
                    Meeting.
                </p>
                <p>
                    ·          A Meeting is concluded when either party  presses the end button,
                    indicating that its purpose has been fulfilled and  justifying the
                    corresponding payment.
                </p>
                <p>
                    ·          In the event that the Meeting is terminated  inadvertently or
                    intentionally by the user when pressing the end button, or due  to platform
                    errors, it will be recorded and subject to audit.
                </p>
                <p>
                    ·          Interruptions of the online session due to causes  beyond the
                    user's control (such as power outages, internet problems, natural
                    disasters, etc.) are treated in the same way as accidental or intentional
                    closures, with no liability on the part of PayCall nor compensation to the
                    parties involved.
                </p>
                <p>
                    PayCall, therefore, is not responsible for interruptions caused by  external
                    factors and handles all cases of termination of Meetings in a uniform
                    manner with respect to the determination of their conclusion and the
                    settlement  of payments to Specialists. No compensation will be awarded in
                    connection with  the termination of the Meeting.
                </p>
                <p>
                    In addition, in the event of a disconnection during an online Meeting,  the
                    user is expected to act diligently to reconnect as soon as possible,
                    identify and resolve the reason for the disconnection to avoid future
                    occurrences. All incidents related to disconnections and reconnections will
                    be  documented and available for consultation in the Meeting History, thus
                    ensuring  transparency and traceability of all actions taken during the
                    Meeting.
                </p>
                <p>
                    <strong>9. CODE OF CONDUCT</strong>
                </p>
                <p>
                    At PayCall, we value the creation of a safe, respectful and professional
                    environment for all our users, both Clients and Specialists. To this end, we
                    establish the following Code of Conduct, which must be strictly followed
                    during  all interactions and meetings within our platform.
                </p>
                <ul>
                    <li>
                        <strong>Mutual Respect</strong>: All users must treat each other with
                        dignity, respect and courtesy at  all times. Offensive, abusive,
                        discriminatory or harassing behavior will not be  tolerated under any
                        circumstances.
                    </li>
                    <li>
                        <strong>Professionalism</strong>: Interactions should be kept in a
                        professional tone and context.  Specialists are expected to provide
                        services in accordance with their  advertised skills and expertise, and
                        Clients are expected to approach  Specialists in a clear and accurate
                        manner regarding their needs.
                    </li>
                    <li>
                        <strong>Privacy and Confidentiality</strong>: Information shared during
                        meetings should be treated with the utmost  confidentiality. Users must
                        not disclose, share or misuse personal or sensitive  information
                        obtained through PayCall.
                    </li>
                    <li>
                        <strong>Compliance with Agreements</strong>: Clients and Specialists
                        must adhere to the pre-established terms,  conditions and agreements for
                        each meeting or service. This includes, but is  not limited to, the
                        duration of the meeting, the scope of the service, and the  agreed upon
                        compensation.
                    </li>
                    <li>
                        <strong>Proper Use of the Platform</strong>: The platform must not be
                        used for illegal, unethical or unauthorized  purposes. The promotion of
                        illegal activities or the sale of services and  products prohibited by
                        law is prohibited.
                    </li>
                    <li>
                        <strong>Punctuality</strong>: Both Clients and Specialists are expected
                        to be punctual for their  meetings. If a meeting needs to be delayed or
                        cancelled, the other party should  be notified well in advance.
                    </li>
                    <li>
                        <strong>Constructive Feedback</strong>: Upon completion of services,
                        users are encouraged to provide honest  and constructive feedback.
                        Criticism should focus on objective aspects and seek  to foster growth
                        and continuous improvement.
                    </li>
                    <li>
                        <strong>Conflict Resolution</strong>: In the event of disagreements or
                        conflicts, users are encouraged to  resolve their differences in an
                        amicable and professional manner. PayCall may  intervene as a mediator
                        if requested and deemed necessary.
                    </li>
                </ul>
                <p>
                    Failure to comply with this Code of Conduct may result in temporary
                    suspension or permanent termination of access to PayCall services. We
                    reserve  the right to take appropriate corrective action to protect the
                    integrity and  safety of our community.
                </p>
                <p>
                    PayCall is committed to providing a platform that promotes positive and
                    enriching interactions, and expects all users to contribute to this goal by
                    respecting and following the Code of Conduct presented herein..
                </p>
                <p>
                    <strong>10. LIMITATION OF LIABILITY</strong>
                </p>
                <p>
                    PayCall, in its commitment to facilitate a space for interaction between
                    Clients and Specialists through its platform, establishes the following
                    provisions regarding the limitation of responsibilities:
                </p>
                <p>
                    PayCall acts exclusively as an intermediary, providing a technological
                    platform that allows Specialists to offer and Clients to receive various
                    services. Given this role, PayCall is not responsible for the quality,
                    security, legality, or any other aspect of the services provided by
                    Specialists, nor for the conduct of Clients or Specialists on or off the
                    platform.
                </p>
                <p>
                    PayCall's liability is limited to facilitating the availability of its
                    platform and does not include any warranties related to the services offered
                    by  the Specialists, the Client's satisfaction, the accuracy of the
                    information  provided by the users, or the results expected to be obtained
                    from the use of  the platform.
                </p>
                <p>
                    PayCall shall not be liable for direct, indirect, incidental, special,
                    consequential or exemplary damages, including but not limited to, damages
                    for  loss of profits, goodwill, use, data or other intangible losses,
                    resulting  from: (i) the use or inability to use the platform; (ii) the cost
                    of  procurement of substitute services; (iii) unauthorized access to or
                    alteration  of users' transmissions or data; (iv) the statements or conduct
                    of any third  party on the platform; or (v) any other matter relating to
                    PayCall's services.
                </p>
                <p>
                    While PayCall strives to ensure the functionality and security of its
                    platform, it cannot guarantee that the service will be uninterrupted,
                    timely,  secure or error-free, nor can it ensure that defects in the service
                    will be  corrected. Further, PayCall does not guarantee the accuracy or
                    reliability of any  information or content transmitted through its platform.
                </p>
                <p>
                    Users agree to indemnify and hold PayCall, its directors, employees and
                    agents, harmless from any claim, demand, loss, damage, cost or liability
                    (including reasonable legal fees) that may arise from use of the platform,
                    violation of these terms, or violation of the rights of third parties.
                </p>
                <p>
                    This limitation of liability clause shall be enforceable to the fullest
                    extent permitted by law in the relevant jurisdiction and shall remain in
                    effect  even if this agreement is terminated or expires for any reason.
                    Acceptance of  these terms constitutes an agreement between you and PayCall
                    regarding  limitation of liability and is an essential element of the basis
                    of the bargain  between PayCall and you.
                </p>
                <p>
                    <strong></strong>
                </p>
                <p>
                    <strong>11. CHAT AND MESSAGING</strong>
                </p>
                <p>
                    The platform contains chat areas or messaging services to enable
                    communication between users. The user may only use the chat or messaging
                    service to send and receive messages that are relevant and specific to the
                    purposes of the platform. The user may NOT use the chat or messaging service
                    available on the platform to perform any of the following actions:
                </p>
                <p>
                    ·          Send messages that are defamatory, violent,  profane, harassing,
                    obscene, threatening or messages that otherwise violate the  legal rights of
                    users or third parties, including PayCall staff.
                </p>
                <p>
                    ·          Send messages of a sexual or pornographic  nature.
                </p>
                <ul>
                    <li>
                        Send messages that promote illegal activities.
                    </li>
                </ul>
                <p>
                    ·          Conduct or forward surveys, contests, pyramid  schemes or chain
                    letters.
                </p>
                <p>
                    ·          Impersonate another person or user or allow any  other person or
                    entity to use your account to send messages or otherwise use  your account.
                </p>
                <p>
                    ·          Repeatedly send the same message. Spamming is  strictly
                    prohibited.
                </p>
                <p>
                    ·          Imply or assert that any statement you make is  endorsed by
                    PayCall, without PayCall's prior written consent.
                </p>
                <p>
                    ·          Post offensive and/or harmful messages,  including, but not
                    limited to, content that advocates, supports, condones or  promotes racism,
                    bigotry, hatred or physical harm of any kind against any  individual or
                    group of individuals.
                </p>
                <p>
                    ·          Use the platform to collect personal data from  users.
                </p>
                <p>
                    Any breach or violation of the provisions contained in these terms may
                    result in the suspension of the offending user's account, with or without
                    notice and without the right to compensation.
                </p>
                <p>
                    <strong>12. FEEDBACK SYSTEM</strong>
                </p>
                <p>
                    At PayCall, we strongly believe in the importance of maintaining an
                    environment of transparency and trust, essential for the development and
                    strengthening of our community of Clients and Specialists. To foster this
                    environment, we implemented a rating and feedback system that allows users
                    to  rate each other and share their experiences after the conclusion of each
                    meeting.
                </p>
                <p>
                    At the end of each completed meeting, both Clients and Specialists will
                    have the opportunity to rate the session on a predefined scale and leave
                    comments on the service received or provided. This feedback system aims to
                    provide a clear and honest view on the quality of services, professionalism
                    and  conduct of users within the platform.
                </p>
                <p>
                    It is essential that all ratings and comments are provided in a  respectful,
                    constructive and factual manner, avoiding the use of offensive,  defamatory
                    or inappropriate language. PayCall reserves the right to review and
                    moderate ratings and comments to ensure that they comply with our usage
                    policies and do not infringe on the rights of other users or third parties.
                </p>
                <p>
                    This feedback system not only serves as a guide for future users seeking
                    services within the platform, but also provides Specialists with valuable
                    information that can be used to improve the quality of their services. It
                    also  allows Clients to make informed decisions based on the previous
                    experiences of  other users.
                </p>
                <p>
                    PayCall stresses that the integrity and authenticity of ratings and
                    comments are critical to its effectiveness and reliability. Therefore,
                    manipulating the ratings system through dishonest practices, such as
                    creating  fake profiles or exchanging undeserved positive ratings, is
                    strictly prohibited  and may result in penalties, including account
                    suspension or termination.
                </p>
                <p>
                    We encourage all users to actively participate in this rating and  feedback
                    system, thus contributing to the growth of a strong, informed and
                    respectful PayCall community. Your participation is key to maintaining
                    quality  and trust in the services offered through our platform.
                </p>
                <p>
                    <strong>13. LICENSE TO USE  THE PLATFORM</strong>
                </p>
                <p>
                    <a name="_Hlk23344316">
                        PayCall grants you a personal, worldwide, royalty-free, non-assignable,
                        non-exclusive license to use the PayCall platform and downloadable via
                        Google  Play and App Store free of charge, including but not limited to
                        modified  versions, updates, upgrades, enhancements, improvements,
                        additions, additions  and copies, if any. This license is for the sole
                        purpose of allowing you to use  the features available on the Platform
                        in the manner permitted by these terms.  You may not copy, modify,
                        distribute, sell or lease any part of our platform or  the included
                        software, nor may you reverse engineer or attempt to extract the  source
                        code of such software, unless such restrictions are prohibited by law,
                        or unless you have our written permission
                    </a>
                    .
                </p>
                <p>
                    The user agrees not to use the platform and the  Services in a negligent,
                    fraudulent or unlawful manner. It also undertakes not  to carry out any
                    conduct or action that could damage the image, interests or  rights of
                    PayCall or third parties.
                </p>
                <p>
                    PayCall reserves the right to terminate the user's  access immediately, with
                    or without notice, and without liability to the user,  if PayCall considers
                    that the user has violated any of these terms.
                </p>
                <p>
                    <a name="_Hlk23344251"><strong>14. COPYRIGHT</strong></a>
                </p>
                <p>
                    All materials on PayCall, including, without limitation, names, logos,
                    trademarks, games, images, text, columns, graphics, videos, photographs,
                    illustrations, software and other elements are protected by copyrights,
                    trademarks and/or other intellectual property rights owned and controlled by
                    PayCall  or by third parties that have licensed or otherwise provided their
                    material to  the platform. You acknowledge and agree that all Materials on
                    PayCall are made  available for limited, non-commercial, personal use only.
                    Except as  specifically provided herein. No material may be copied,
                    reproduced, republished,  sold, downloaded, posted, transmitted, or
                    distributed in any way, or otherwise  used for any purpose, by any person or
                    entity, without PayCall prior express  written permission. You may not add,
                    delete, distort, or otherwise modify the material.  Any unauthorized attempt
                    to modify any material, to defeat or circumvent any  security features, or
                    to utilizePayCall or any part of the material for any  purpose other than
                    its intended purposes is strictly prohibited.
                </p>
                <p>
                    <strong>15. COPYRIGHT INFRINGEMENT</strong>
                </p>
                <p>
                    PayCall will respond to all inquiries, complaints and claims related to
                    alleged infringement by breach or violation of the provisions contained in
                    European and international copyright and intellectual property laws and
                    regulations. PayCall respects the intellectual property of others and
                    expects  users to do the same. If you believe, in good faith, that any
                    material provided  on the website infringes your copyright or other
                    intellectual property rights,  please submit your request using our contact
                    information, with the following  information
                </p>
                <p>
                    ·          Identification of the intellectual property  right that is
                    allegedly infringed. All relevant registration numbers or a  statement
                    regarding ownership of the work should be included.
                </p>
                <p>
                    ·          A statement that specifically identifies the  location of the
                    infringing material, in sufficient detail so that PayCall can  find it on
                    the platform.
                </p>
                <p>
                    ·          Your name, address, telephone number and email  address.
                </p>
                <p>
                    ·          A statement by you that you have a good faith  belief that the
                    use of the allegedly infringing material is not authorized by  the copyright
                    owner, or its agents, or by law.
                </p>
                <p>
                    ·          A statement by you, made under penalty of  perjury, that the
                    information in your notification is accurate, and that you  are the
                    copyright owner or authorized to act on its behalf.
                </p>
                <p>
                    ·          An electronic or physical signature of the  copyright owner or of
                    the person authorized to act on the copyright owner's  behalf.
                </p>
                <p>
                    <a name="_Hlk23344109"><strong>16. PROHIBITED ACTIVITIES</strong></a>
                </p>
                <p>
                    The following activities are prohibited:
                </p>
                <p>
                    ·          Access, monitor, reproduce, distribute,  transmit, broadcast,
                    display, sell, license, copy or otherwise exploit any content  of the
                    platform, including but not limited to, using any robot, spider, scraper  or
                    other automated means or any manual process for any purpose not in
                    accordance with this agreement or without our express written permission.
                </p>
                <p>
                    ·          Violate the restrictions in any robot exclusion  headers on the
                    platform or bypass or circumvent other measures employed to  prevent or
                    limit access to the platform.
                </p>
                <p>
                    ·          Take any action that imposes, or may impose, in  our discretion,
                    an unreasonable or disproportionately large load on our  infrastructure.
                </p>
                <p>
                    ·          Deep-link to any portion of the platform for  any purpose without
                    our express written permission.
                </p>
                <p>
                    ·          "Frame", "mirror" or otherwise  incorporate any part of the
                    platform into any other platforms or Service  without our prior written
                    authorization.
                </p>
                <p>
                    ·          Attempt to modify, translate, adapt, edit,  decompile,
                    disassemble, or reverse engineer any software programs used by PayCall.
                </p>
                <p>
                    ·          Circumvent, disable or otherwise interfere with  security-related
                    features of the platform or features that prevent or restrict  use or
                    copying of any content.
                </p>
                <p>
                    <strong>17. DISCLAIMER OF  WARRANTIES</strong>
                </p>
                <p>
                    Because of the nature of the Internet PayCall  provides and maintains the
                    platformon an "as is", "as  available" basis and makes no promise that use
                    of the platformwill be  uninterrupted or entirely error free. We are not
                    responsible to you if we are  unable to provide our Internet Services for
                    any reason beyond our control.
                </p>
                <p>
                    Except as provided above we can give no other  warranties, conditions or
                    other terms, express or implied, statutory or  otherwise and all such terms
                    are hereby excluded to the maximum extent  permitted by law.
                </p>
                <p>
                    You will be responsible for any breach of these  terms by you and if you use
                    <strong>the</strong>platformin breach of these terms you will be liable  to
                    and will reimburse PayCallfor any loss or damage caused as a result.
                </p>
                <p>
                    PayCall shall not be liable for any amount for failure  to perform any
                    obligation under this Agreement if such failure is due to the  occurrence of
                    any unforeseen event beyond its reasonable control, including,  without
                    limitation, Internet outages, communications outages, fire, flood, or  any
                    uncontrollable act of nature.
                </p>
                <p>
                    These terms do not affect your statutory rights  as a consumer which are
                    available to you.
                </p>
                <p>
                    Subject as aforesaid, to the maximum extent  permitted by law,
                    PayCallexcludes liability for any loss or damage of any  kind howsoever
                    arising, including without limitation any direct, indirect or  consequential
                    loss whether or not such arises out of any problem you notify to PayCalland
                    PayCallshall  have no liability to pay any money by way of compensation,
                    including without  limitation all liability in relation to:
                </p>
                <p>
                    ·          Any incorrect or inaccurate  information on the platform.
                </p>
                <p>
                    ·          The infringement by  any person of any Intellectual Property
                    Rights of any third party caused by  their use of
                    <strong>the</strong>platform.
                </p>
                <p>
                    ·          Any loss or damage  resulting from your use or the inability to
                    use the platform or resulting  from unauthorized access to, or alteration of
                    your transmissions or data in  circumstances which are beyond our control.
                </p>
                <p>
                    ·          Any loss of profit,  wasted expenditure, corruption or
                    destruction of data or any other loss which  does not directly result from
                    something we have done wrong.
                </p>
                <p>
                    ·          Any amount or kind  of loss or damage due to viruses or other
                    malicious software that may infect a  user's computer equipment, software,
                    data or other property caused by persons  accessing or using content from
                    <strong>the</strong>platformor from transmissions via emails or  attachments
                    received from PayCall.
                </p>
                <p>
                    ·          All representations,  warranties, conditions and other terms
                    which but for this notice would have  effect.
                </p>
                <p>
                    <strong></strong>
                </p>
                <p>
                    <strong>18. ELECTRONIC COMMUNICATIONS</strong>
                </p>
                <p>
                    PayCall will not accept any  responsibility for failed, partial or garbled
                    computer transmissions, for any  breakdown, failure, connection or
                    availability of computer, telephone, network,  electronic or Internet
                    hardware or software, for Internet accessibility or availability  or traffic
                    congestion or for any unauthorized human acts, including any errors  or
                    mistakes.
                </p>
                <p>
                    <strong></strong>
                </p>
                <p>
                    <strong>19.  INDEMNIFICATION</strong> <a name="OLE_LINK15"></a>
                </p>
                <p>
                    You agree to indemnify, defend and  hold harmless the platform, its
                    officers, employees, affiliates and Specialists  against any claims, losses,
                    damages, costs and expenses (including reasonable  legal fees) resulting
                    from or related to your misuse of the platform or  violation of this clause.
                </p>
                <p>
                    <strong>20.  CHANGES</strong>
                </p>
                <p>
                    We may change the platformand these  terms at any time, in our sole
                    discretion and without notice to you. You are  responsible for remaining
                    knowledgeable about these terms.Your continued use of  the
                    platformconstitutes your acceptance of any changes to these terms and any
                    changes will supersede all previous versions of the terms. Unless otherwise
                    specified herein, all changes to these terms apply to all users take effect.
                </p>
                <p>
                    <strong>21. INTEGRATION  CLAUSE</strong>
                </p>
                <p>
                    This agreement together with  the
                    <a href="https://www.doyouyoga.com/privacy/">
                        privacy policy
                    </a>
                    and any other legal notices published by PayCall,  shall constitute the
                    entire agreement between you and PayCallconcerning and  governs your use of
                    the platform.
                </p>
                <p>
                    <strong>22.  SEVERABILITY</strong>
                </p>
                <p>
                    If any section of these terms is  held invalid, illegal or unenforceable,
                    the validity, legality and  enforceability of the remaining provisions shall
                    not in any way be affected or  impaired. Our failure to enforce or delay in
                    enforcing any provision of these  terms at any time does not waive our right
                    to enforce the same or any other  provision in the future.
                </p>
                <p>
                    <strong>23.  FORCE MAJEURE</strong>
                </p>
                <p>
                    PayCall shall not be liable for any  failure to perform due to causes beyond
                    its reasonable control, including,  without limitation, fortuitous events,
                    acts of civil authorities, acts of  military authorities, riots, embargoes,
                    acts of nature and natural disasters,  and other acts that may be due to
                    unforeseen circumstances.
                </p>
                <p>
                    <strong>24.  TERMINATION</strong>
                </p>
                <p>
                    Both the user and PayCall  acknowledge that failure to comply with these
                    terms and conditions may result  in termination of the agreement between
                    both parties.
                </p>
                <p>
                    <strong>In case of non-compliance by the  user</strong>: If the user  fails
                    to comply with any of the terms set forth in these terms and conditions,
                    PayCall  reserves the right to unilaterally terminate the user's access to
                    and use of  the platform. PayCall may take this action without prior notice,
                    and the User  will automatically lose all rights to access and use the
                    platform. Furthermore,  PayCall shall not be liable for any loss of data or
                    information associated with  the user's account.
                </p>
                <p>
                    <strong>In case of non-compliance by PayCall</strong>: If PayCall fails to
                    comply with  its obligations under the agreed terms and conditions, the User
                    shall be  entitled to terminate the agreement and stop using the platform.
                    The user shall  notify PayCall in writing of the breach and allow a
                    reasonable time for PayCall  to remedy the situation. If PayCall does not
                    remedy the breach within the set  period, the user may proceed with the
                    termination of the agreement.
                </p>
                <p>
                    Termination of the agreement shall  not release either party from any
                    obligations and liabilities that have arisen  prior to the date of
                    termination.
                </p>
                <p>
                    Continued use of the platform after  termination of the agreement by either
                    party shall imply acceptance of the  conditions set forth in the terms and
                    conditions in effect at that time.
                </p>
                <p>
                    <strong>25.  ARBITRATION</strong>
                </p>
                <p>
                    You acknowledge and agree that any  dispute, claim or controversy arising
                    out of or relating to these terms and  conditions, whether for breach,
                    termination, enforcement, interpretation or  validity, as well as any aspect
                    of your use of our platform and services, shall  preferably be resolved by
                    binding arbitration between you and PayCall.  Notwithstanding the foregoing,
                    the right of both parties to bring individual  claims in a court of
                    competent jurisdiction if they deem it necessary remains.
                </p>
                <p>
                    In the event of any dispute arising  in connection with your use of our
                    platform and services or breach of these  terms and conditions, both parties
                    agree to seek resolution through an  arbitration process. This process will
                    be conducted before a recognized and  respected arbitration entity, selected
                    by mutual agreement of both parties and  following applicable commercial
                    arbitration rules.
                </p>
                <p>
                    In addition, the user agrees, to the  extent permitted by applicable law,
                    not to initiate, join or participate in  class action lawsuits associated
                    with any claim, dispute or controversy that  may arise in connection with
                    the use of our platform and services. This  commitment seeks to promote a
                    more agile and personalized resolution of any  conflict, favoring
                    arbitration and individual legal actions over class actions.
                </p>
                <p>
                    <strong>26.  APPLICABLE LAW AND JURISDICTION</strong>
                </p>
                <p>
                    <a name="_Hlk156169049">
                        These  terms and conditions shall be governed by and construed in
                        accordance with the  laws of Switzerland. Any dispute relating to these
                        terms shall be subject to  the exclusive jurisdiction of the courts of
                        Switzerland. This applies unless  binding arbitration is agreed to in
                        the relevant section.
                    </a>
                </p>
                <p>
                    <strong>27.  FINAL PROVISIONS</strong>
                </p>
                <p>
                    The use of our platform and the  services offered on our platform is
                    conditioned upon acceptance of and  compliance with all terms and conditions
                    set forth. This authorization to use  our services does not extend to
                    jurisdictions where these provisions are not  respected or applied.
                </p>
                <p>
                    Our commitment to compliance with  these terms is strictly governed by
                    applicable laws and legal process. Importantly,  these terms do not restrict
                    our ability to comply with legal or governmental  requirements, including
                    but not limited to those related to law enforcement and  the use of our
                    platform. Information provided or collected in connection with  the use of
                    our platform will be subject to these requirements.
                </p>
                <p>
                    In the event that any provision of  these terms and conditions shall be
                    declared invalid, illegal or unenforceable  by any court or competent
                    authority, such decision shall not affect the  validity or enforceability of
                    the remaining provisions. The failure or delay in  enforcing any of these
                    terms and conditions by us at any time shall not  constitute a waiver of our
                    rights to enforce such provision, or any other  provision, in the future.
                </p>
                <p>
                    We reserve all rights not expressly  granted herein, while at all times
                    maintaining the protection of and respect  for our intellectual property
                    rights and prerogatives.
                </p>
                <p>
                    <strong>28. CONTACT INFORMATION</strong>
                </p>
                <p>
                    <a name="_Hlk33880204">If you have questions or concerns about these</a><a name="_Hlk37278048">terms,</a>please 
                    contact us through our contact  forms or by using the following contact
                    information:
                </p>
                <p>
                    <a name="_Hlk130303845"><strong>PayCall, Inh. Ayari - PayCall.</strong></a><a name="_Hlk134908600"></a>
                    <strong></strong>
                </p>
                <p>
                    <a href="mailto:contact@paycall.co">
                        <strong>contact@paycall.co</strong>
                    </a>
                    <strong></strong>
                </p>
                <p>
                    <strong></strong>
                </p>
                
    </div>
    <div class="terms-btn">
    <a href="javascript:void(0);" class="btn btn-right-now">Not right now...</a>
    <a href="javascript:void(0);" class="btn btn-primary prime-btn">I agree with terms</a>
    </div>
    </div>
    </div>
    </div>
    </div>


<app-layout-footer></app-layout-footer>