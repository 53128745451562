<app-layout-header></app-layout-header>
<!-- Page Content -->
<div class="content top-space">
        <div class="container-fluid">
    
            <section class="middle">
                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-xl-7 col-lg-6 col-md-12 col-sm-12">
                            <div class="border p-3 rounded">
                                <!---->
                                <div class="row align-items-center">
                                    <div class="col-3 align-items-start"><button class="back-btn"><i
                                                class="fa fa-angle-left font-size-24 text-dark"></i></button>
                                    </div>
                                    <div class="col-6 align-items-start text-center"><img alt="logo de preprod.paycall.co"
                                            height="35" src="../../../assets/img/logos/primary.svg" width="300"></div>
                                </div>
                                <hr style="color: #F0F3F8;">
                                <div class="px-xl-5 px-lg-4">
                                    <div class="sec_title position-relative text-start mb-3">
                                        <h3 class="ft-bold text-login">Check my number</h3>
                                    </div> 
                                    <p class="text-login font-weight-500 mb-3">
                                            Please enter the code received by SMS ☎️ </p>
                                   
                                    <form novalidate="" class="ng-untouched ng-pristine ng-valid">
                                        <div class="d-flex">
                                            <div class="form-group" style="width: 68%;margin-right: 15px;">
                                                    <input placeholder="Received Code" type="number" required="" class="form-control1">
                                                </div>
                                                <div class="form-group" style="text-align: end;">
                                                    <button class="btn btn-md btn-book">
                                                        Continue
                                                    </button>
                                            </div>
                                        </div>
                                        <hr style="color: #F0F3F8;">
                                        <div class="row align-items-center">
                                            <div class="col-lg-2 col-xl-2 align-items-start"></div>
                                            <div class="col-lg-8 col-xl-8 col-12 align-items-start text-center">
                                                <p class="text-login font-weight-500 mb-2 text-center">
                                                        By logging in, you agree to our
                                                    <a class="btn-link text-decoration-none" href="/ConditionDutilisation"
                                                        target="_blank">Terms &amp; conditions</a> &amp; 
                                                    <a href="/Privacy&amp;Policy" class="btn-link text-decoration-none" target="_blank"> our privacy policy
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
        </div>
    
    </div>
    <!-- /Page Content -->