<app-layout-header></app-layout-header>
			
			<div class="content">
				<div class="container">

						<button class="w-100 btn btn-primary d-lg-none mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#filterForm" aria-expanded="false" aria-controls="filterForm">
								Filter <i class="fa fa-filter"></i>
							</button>
						
					<div class="row">
						<div class="col-xl-12 col-lg-12">
							<!-- Add a button to toggle the filter form -->
							
							<div class="row">
                                <div class="col-lg-3 theiaStickySidebar">
                                    <div class="filter-contents">

										 
											<div class="collapse d-lg-block" id="filterForm">

                                        <div class="filter-header d-flex justify-content-between align-items-center">
											<h4 class="filter-title"><i class="fa fa-filter mr-5"></i>Filter</h4>
											<a href="javascript:void(0);">Clean</a>
                                        </div>
                                        <div class="filter-details h-100">
                                
                                            <div class="filter-grid pb-0">
                                 
												
												
												<div class="form-group has-search">
														<span class="fa fa-search form-control-feedback"></span>
														<input type="search" [(ngModel)]="term" (keyup)="searchEngine($event)" class="form-control mb-2 bg-search" placeholder="Search On PayCall">
													  </div>
                                
                                                <div class="form-group mb-2">
													<mat-form-field style="width: 100%;">
														<mat-label>Categories</mat-label>
														<mat-select [(ngModel)]="selectedCategory" name="category_t1" (selectionChange)="changeCategory()">
																<mat-option *ngFor="let category of categories" [value]="category.id">
																	{{ category.label }}
																</mat-option>
														</mat-select>
													</mat-form-field>
                                                </div>
                                                

											<div class="form-group mb-2">
												<mat-form-field style="width: 100%;">
													<mat-label>Subcategories</mat-label>
													<mat-select [(ngModel)]="selectedSubCategory"  name="subCategory_t1" multiple>
															<mat-option *ngFor="let category of subCategories" [value]="category.id">
																{{ category.label }}
															</mat-option>
													</mat-select>
												</mat-form-field>
										</div>

										<div class="form-group mb-2">
											<mat-form-field class="example-chip-list" style="    width: 100%;">
												<mat-label>Tags</mat-label>
												<mat-chip-list #chipList aria-label="Fruit selection">
												   <mat-chip *ngFor="let fruit of fruits" [selectable]="selectable"
													  [removable]="removable" (removed)="remove(fruit)">
													  {{fruit}}
													  <mat-icon matChipRemove *ngIf="removable">
														 <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
															   d="M9.17065 4C9.58249 2.83481 10.6937 2 11.9999 2C13.3062 2 14.4174 2.83481 14.8292 4"
															   stroke="black" stroke-width="1.5" stroke-linecap="round" />
															<path d="M20.5 6H3.49988" stroke="black" stroke-width="1.5"
															   stroke-linecap="round" />
															<path
															   d="M18.3735 15.3991C18.1965 18.054 18.108 19.3815 17.243 20.1907C16.378 21 15.0476 21 12.3868 21H11.6134C8.9526 21 7.6222 21 6.75719 20.1907C5.89218 19.3815 5.80368 18.054 5.62669 15.3991L5.16675 8.5M18.8334 8.5L18.6334 11.5"
															   stroke="black" stroke-width="1.5" stroke-linecap="round" />
															<path d="M9.5 11L10 16" stroke="black" stroke-width="1.5"
															   stroke-linecap="round" />
															<path d="M14.5 11L14 16" stroke="black" stroke-width="1.5"
															   stroke-linecap="round" />
														 </svg>
													  </mat-icon>
												   </mat-chip>
												   <input placeholder="New tag..." #fruitInput [formControl]="fruitCtrl"
													  [matAutocomplete]="auto" [matChipInputFor]="chipList"
													  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
													  (matChipInputTokenEnd)="add($event)" [hidden]="fruits.length >= 5">
												</mat-chip-list>
												<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
												   <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
													  {{fruit}}
												   </mat-option>
												</mat-autocomplete>
											 </mat-form-field>
									</div> 
                                 
                                                <div class="row">
                                                    <div class="col-5 pr-0">
                                                        <select class="form-select form-control" id="floatingSelect" 
                                                            aria-label="Floating label select example">
                                                            <option value="call" selected><i class="fa fa-filter"></i>Call</option>
                                                            <option value="video">Video</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-2 p-0">
                                                        <input type="number" min="1" max="60" class="form-control mb-2" value="1">
                                                    </div>
                                                    <div class="col-5 pl-0">
                                                        <select class="form-select form-control" id="floatingSelect"
                                                            aria-label="Floating label select example">
                                                            <option selected><i class="fa fa-filter"></i>Min</option>
                                                            <option value="1">Hour</option>
                                                        </select>
                                                    </div>
                                                </div>
                                
                                                <div class="row">
                                                    <div class="col-6 ">
                                                        <input type="text" [(ngModel)]="minPrice" class="form-control mb-2" placeholder="Minimum" (keyup)="filterSearch()">
                                                    </div>
                                                    <div class="col-6">
                                                        <input type="text" [(ngModel)]="maxPrice" class="form-control mb-2" placeholder="Maximum" (keyup)="filterSearch()">
                                                    </div>
                                                </div>
                                
                                                <div class="form-group mb-2">
														<mat-form-field style="width: 100%;">
															<mat-label>Language</mat-label>
															<mat-select [(ngModel)]="selectedLanguage" name="language_t1" (selectionChange)="filterSearch()">
																	<mat-option *ngFor="let language of languagesListe" [value]="language" >
																		{{ language }}
																	</mat-option>
															</mat-select>
														</mat-form-field>
												</div>
                                
                                                <div class="form-group mb-0">
														<mat-form-field style="width: 100%;">
															<mat-label>Social Links</mat-label>
															<mat-select [(ngModel)]="selectedSocialLink" name="social_lins_t1" (selectionChange)="filterSearch()">
																	<mat-option *ngFor="let link of socialLinks" [value]="link.id" >
																		{{ link.label }}
																	</mat-option>
															</mat-select>
														</mat-form-field>
												</div> 
                                
                                            </div>
                                
                                
										</div>
									</div>
                                    </div>
								</div>
								

								<div class="col-lg-9" *ngIf="users.length > 0">
									<div class="doctor-filter-info pb-2 pt-2">
										<div class="doctor-filter-inner">
											<div>
												<div class="doctors-found mb-3">
													<p><span>{{users.length}} Users found for:</span> {{term}}</p>
												</div>
												<div class="d-flex justify-content-around align-items-center doctor-filter-availability mb-2" style="float: inline-start;">
													<p>Verified</p>
													<div class="status-toggle status-tog">
														<input type="checkbox" id="verified_6" class="check">
														<label for="verified_6" class="checktoggle">checkbox</label>
													</div>
                                                </div>
                                                <div class="d-flex justify-content-around align-items-center doctor-filter-availability mb-2">
													<p>Online Now</p>
													<div class="status-toggle status-tog">
														<input type="checkbox" id="status_6" class="check">
														<label for="status_6" class="checktoggle">checkbox</label>
													</div>
												</div>
											</div>
											<div class="doctor-filter-option">
												<div class="doctor-filter-sort">
													<p>Sort</p>
													<div class="doctor-filter-select">
														<select class="select form-control">
															<option>A to Z</option>
															<option>B to Z</option>
															<option>C to Z</option>
															<option>D to Z</option>
															<option>E to Z</option>
														</select>
													</div>
												</div>
												
												<div class="doctor-filter-sort">
													<ul class="nav">
														<li>
															<a href="javascript:void(0);" class="active">
																<i class="fa fa-th"></i>
															</a>
														</li>
														<li>
															<a href="javascript:void(0);" >
																<i class="fa fa-th-list"></i>
															</a>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div class="row">

										<div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let user of users | filter:term:filteredCount ">
											<div class="doctor-profile-widget doc-grid">
												<div class="doc-pro-img">
													<a [routerLink]="['/user',user.pay_call_id]"> 
														<div class="doctor-profile-img">
															<img *ngIf="user.thumbnail" src="{{user.thumbnail}}" class="img-fluid" alt="PayCall user - {{user.full_name}}" style="height: 13rem;object-fit: contain;">
															<img *ngIf="!user.thumbnail" src="https://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png" class="img-fluid" alt="PayCall user - {{user.full_name}}" style="height: 13rem;object-fit: contain;">
														</div>
													</a>
													<div class="reviews-ratings">
														<p>
															<span><i class="fa fa-star"></i> 0</span>
														</p>
													</div>
													<div class="favourite-btn">
														<a href="javascript:void(0)" class="favourite-icon">
															<i class="fa fa-heart"></i>
														</a>
													</div>
												</div>
												<div class="doc-content">
													<div class="doc-pro-info text-center">
														<div class="doc-pro-name">
															<h4><a [routerLink]="['/user',user.pay_call_id]">{{user.full_name}}</a><i class="fa fa-check-circle"></i></h4>
															<p class="d-flex justify-content-center align-items-center">
                                                                <span class="btn btn-outline-primary btn-sm">{{getFirst(user.speciality)}}</span>
                                                                <span class="btn btn-outline-primary btn-sm d-none">Cardiology</span>
														</div>
														<div class="review-price d-none">
															<p>$1100.00<span>/hr</span></p>
														</div>
													</div>
													<div class="doc-pro-location action-profile-btns d-flex align-items-center justify-content-between">
															<a href="#0">
																	<label><i class="fa fa-phone"></i>
																		<p style="margin-top: 0;display: inline-block;"><em style="font-weight: 600;"> {{user.voice_call_price| currency:"$"}}</em><br><strong> per min</strong></p>
																	</label>
																</a>
																<a href="#0"><label><i class="fa fa-video-camera"></i><p style="margin-top: 0;display: inline-block;" ><em style="font-weight: 600;"> {{user.video_call_price| currency:"$"}}</em><br><strong> per min</strong></p></label></a>
																<a href="#0"><label><i class="fa fa-comment"></i><p style="margin-top: 0;display: inline-block;" ><em style="font-weight: 600;">{{user.text_message_price  | currency:"$"}}</em><br> <strong> per msg</strong></p></label></a>
																<a href="#0"><label><i class="fa fa-microphone"></i><p style="margin-top: 0;display: inline-block;" ><em style="font-weight: 600;">{{user.text_message_price  | currency:"$"}}</em><br> <strong> per msg</strong></p></label></a>
															</div>
												</div>
											</div>
										</div>
										
									</div>
									<div class="row d-none">
										<div class="col-sm-12">
											<div class="blog-pagination rev-page">
												<nav>
													<ul class="pagination justify-content-center">
														<li class="page-item disabled">
															<a class="page-link page-prev" href="#" tabindex="-1"><i class="feather-chevrons-left me-1"></i> PREV</a>
														</li>
														<li class="page-item active">
															<a class="page-link" href="#">1</a>
														</li>
														<li class="page-item">
															<a class="page-link" href="#">2</a>
														</li>
														<li class="page-item">
															<a class="page-link" href="#">...</a>
														</li>
														<li class="page-item">
															<a class="page-link" href="#">10</a>
														</li>
														<li class="page-item">
															<a class="page-link page-next" href="#">NEXT <i class="feather-chevrons-right ms-1"></i></a>
														</li>
													</ul>
												</nav>
											</div>
										</div>
									</div>
								</div>


								<div class="col-lg-9" *ngIf="users.length == 0">
										<div class="doctor-filter-info pb-2 pt-2">
											<div class="doctor-filter-inner">
												<div>
													<div class="doctors-found mb-3">
														<p><span>No result found for :</span> {{term}}</p>
													</div>
												</div>
											</div>
										</div>
										<div class="row">
	
											
										</div>
										
									</div>


							</div>
						</div>
						<div class="col-xl-3 col-lg-12 theiaStickySidebar map-right">
							<div id="map" class="map-listing"></div>
						</div>
					</div>

				</div>
			</div>			