import { Component, OnInit, ViewChild } from '@angular/core';

import {Router} from '@angular/router';

@Component({
  selector: 'app-access-pin',
  templateUrl: './access-pin.component.html',
  styleUrls: ['./access-pin.component.scss']
})
export class AccessPinComponent implements OnInit {

  otp: string;
  showOtpComponent = true;
  @ViewChild('ngOtpInput', { static: false}) ngOtpInput: any;
  config = {
    allowNumbersOnly: false,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '70px',
      'height': '70px'
    }
  };

  constructor(private router: Router){}

  ngOnInit(): void {
    
    localStorage.removeItem('pay_call_pin_lock_txt2_is_active');

  }


  onOtpChange(otp) {
    this.otp = otp;
    if(this.otp == 'Swiss')
    {
      localStorage.setItem('pay_call_pin_lock_txt2_is_active', 'Swiss')
      this.router.navigate(['/']);

    }

  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  toggleDisable(){
    if(this.ngOtpInput.otpForm){
      if(this.ngOtpInput.otpForm.disabled){
        this.ngOtpInput.otpForm.enable();
      }else{
        this.ngOtpInput.otpForm.disable();
      }
    }
  }

  onConfigChange() {
    this.showOtpComponent = false;
    this.otp = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

  

}
