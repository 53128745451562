<app-layout-header></app-layout-header>
<!-- Page Content -->
<div class="content-chat top-space-chat">
    <div class="container-fluid">
        <div class="row chat-window">
            <div class="col-xl-12">
                <div class="chat-window">

                    <!-- Chat Left -->
                    <div class="chat-cont-left">
                        <div class="chat-header">
                            <span>PayCall Messenger</span>
                            <a href="javascript:void(0)" class="chat-compose">
                                <i class="fa fa-plus"></i>
                            </a>
                        </div>
                        <form class="chat-search">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <i class="fa fa-search"></i>
                                </div>
                                <input type="text" class="form-control rounded-pill" placeholder="Search">
                            </div>
                        </form>
                        <div class="chat-users-list">
                            <div class="chat-scroll">

                                <a class="notify-block d-flex open-chat" *ngFor="let conversation of conversations"
                                    (click)="showSingleChat(conversation)">
                                    <div class="media-img-wrap flex-shrink-0">
                                        <div class="{{ conversation.user.is_online == 1 ? 'avatar avatar-online' : 'avatar avatar-offline'}}">
                                            <img src="{{conversation.user.thumbnail}}" alt="User Image"
                                                class="avatar-img rounded-circle">
                                        </div>
                                    </div>
                                    <div class="media-body chat-custom flex-grow-1">
                                        <div>
                                            <div class="user-name">{{conversation.user.full_name}}</div>
                                            <div class="user-last-chat" *ngIf="conversation.lastMessage.aff_type == 'voice_message'"><i class="fa fa-microphone text-primary mr-2"></i>Voice message </div>
                                            <div class="user-last-chat" *ngIf="conversation.lastMessage.aff_type == 'test_message'">{{conversation.lastMessage.message}}</div>
                                        </div>
                                        <div>
                                            <div class="last-chat-time block">
                                                <!--{{conversation.lastMessage.created_at | date:'HH:mm'}}-->
                                                {{conversation.lastMessage.created_at | date: 'EEE , HH:mm'}}
                                            </div>
                                            <div class="badge badge-success rounded-pill d-none">15</div>
                                        </div>
                                    </div>
                                </a>

                            </div>
                        </div>
                    </div>
                    <!-- /Chat Left -->


                    <div class="chat-cont-right" *ngIf="ConversationUser.length != 0">
                        <div class="chat-header">
                            <a id="back_user_list" href="javascript:void(0)" class="back-user-list">
                                <i class="material-icons">chevron_left</i>
                            </a>
                            <a [routerLink]="['/user', ConversationUser.pay_call_id]" class="notify-block d-flex">
                                <div class="media-img-wrap flex-shrink-0">
                                    <div
                                        class="{{ ConversationUser.is_online == 1 ? 'avatar avatar-online' : 'avatar avatar-offline'}}">
                                        <img src="{{ConversationUser.thumbnail}}" alt="User Image" 
                                            class="avatar-img rounded-circle">
                                    </div>
                                </div>
                                <div class="media-body flex-grow-1">
                                    <div class="user-name">{{ConversationUser.full_name}}</div>
                                    <div class="user-status" *ngIf="ConversationUser.is_online == 0">Offline</div>
                                    <div class="user-status" *ngIf="ConversationUser.is_online == 1">Online</div>
                                </div>
                            </a>
                            <div class="chat-options">
                                <a [routerLink]="['/make-call', ConversationUser.pay_call_id, 'audio']" >
                                    <i class="fa fa-phone"></i>
                                </a>
                                <a [routerLink]="['/make-call', ConversationUser.pay_call_id, 'video']">
                                    <i class="fa fa-video-camera"></i>
                                </a>
                                <a>
                                    <i class="fa fa-ellipsis-v"></i>
                                </a>

                                


                                <!--
                                    <li class="nav-item dropdown dropdown-action">
														<a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-cog"></i></a>
														<div class="dropdown-menu dropdown-menu-end">
															<a href="javascript:void(0)" class="dropdown-item">Settings</a>
														</div>
													</li>
                                -->
                            </div>
                        </div>
                        <div class="chat-body">
                            <div #chatConvContent class="chat-scroll" >
                                <ul class="list-unstyled">


                                    <li *ngFor="let message of ConversationMessages"
                                        [class]="message.sender_id == current_user ? 'notify-block sent d-flex' : 'notify-block received d-flex'">

                                        <div class="media-body flex-grow-1">
                                            <div class="msg-box">
                                                <div
                                                    [class]="message.sender_id == current_user ? 'bg-paycall text-white' : ''">
                                                      <div *ngIf="message.aff_type == 'voice_message'">
                                                        <audio controls controlsList="nodownload">
                                                            <source [src]="sanitizer.bypassSecurityTrustResourceUrl(message.message)" />
                                                        </audio>
                                                      </div>
                                                      <div *ngIf="message.aff_type == 'test_message'">
                                                        <p [class]="message.sender_id == current_user ? 'text-white' : ''">
                                                            {{message.message}}
                                                        </p>
                                                      </div>
                                                    <ul class="chat-msg-info">
                                                        <li>
                                                            <div class="chat-time">
                                                                <span
                                                                    [class]="message.sender_id == current_user ? 'text-white' : ''">{{message.created_at | date: 'EEE , HH:mm'}}</span>
                                                            </div> <!--{{message.created_at | date: 'EEE, dd-MM-yyyy HH:mm'}}-->
                                                        </li>
                                                        <li>
                                                            <i *ngIf="message.sender_id == current_user">
                                                                <em *ngIf="message.message_type === 'message'">Cost -
                                                                    {{ConversationUser.text_message_price}}$</em>
                                                                <em *ngIf="message.message_type === 'replay'">Free</em>
                                                            </i>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>



                                    <li class="notify-block received d-flex d-none">
                                        <div class="avatar flex-shrink-0">
                                            <img src="assets/img/doctors/doctor-thumb-02.jpg" alt="User Image"
                                                class="avatar-img rounded-circle">
                                        </div>
                                        <div class="media-body flex-grow-1">
                                            <div class="msg-box">
                                                <div>
                                                    <div class="msg-typing">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="chat-footer" style="bottom: 1rem;">
                            <div class="input-group">
                                <div class="btn-file btn d-none">
                                    <i class="fa fa-paperclip"></i>
                                    <input type="file">
                                </div>
                                <form [formGroup]="form" class="w-100 d-flex" *ngIf="!isRecording">
                                    <input type="text" class="input-msg-send form-control rounded-pill" (keyup.enter)="sendMessage()" [placeholder]="lastMessage.sender_id != current_user ? 'Replay Now (Free)' : 'Type a message ...'" formControlName="message" [(ngModel)]="inputMess" >
                                    <button type="button" class="btn msg-send-btn rounded-pill ms-2" (click)="sendMessage()" *ngIf="form.valid"><i class="fa fa-telegram"></i></button>

                                    <button type="button" (click)="recordStream()" class="btn msg-send-btn rounded-pill ms-2" *ngIf="!form.valid" ><i class="fa fa-microphone"></i></button>

                                </form>
                                <form [formGroup]="form" class="w-100 d-flex" *ngIf="isRecording">
                                    
                                        <!--<div style="text-align:center;margin-top: 200px;" >
                                                <button (click)="initiateRecording()" class="btn btn-primary" *ngIf="!recording" style="cursor: pointer;background-color: green;color: white;font-size: 40px;"> Start Recording </button>
                                                <button (click)="stopRecording()" class="btn btn-danger" *ngIf="recording" style="cursor: pointer;background-color: red;color: white;font-size: 40px;"> Stop Recording </button>
                                                <audio controls="" *ngIf="url">
                                                <source [src]="sanitize(url)" type="audio/wav">
                                                </audio>
                                                </div>-->

                                                <div class="boxContainer">
                                                        <div class="box box1"></div>
                                                        <div class="box box2"></div>
                                                        <div class="box box3"></div>
                                                        <div class="box box4"></div>
                                                        <div class="box box5"></div>
                                                        <div class="box box3"></div>
                                                        <div class="box box4"></div>
                                                        <div class="box box5"></div>
                                                        <div class="box box1"></div>
                                                        <div class="box box2"></div>
                                                        <div class="box box3"></div>
                                                        <div class="box box4"></div>
                                                        <div class="box box5"></div>
                                                        <div class="box box3"></div>
                                                        <div class="box box4"></div>
                                                        <div class="box box5"></div>
                                                        <div class="box box1"></div>
                                                        <div class="box box2"></div>
                                                        <div class="box box3"></div>
                                                        <div class="box box4"></div>
                                                        <div class="box box5"></div>
                                                        <div class="box box3"></div>
                                                        <div class="box box4"></div>
                                                        <div class="box box5"></div>
                                                        <div class="box box1"></div>
                                                        <div class="box box2"></div>
                                                        <div class="box box3"></div>
                                                        <div class="box box4"></div>
                                                        <div class="box box5"></div>
                                                        <div class="box box3"></div>
                                                        <div class="box box4"></div>
                                                        <div class="box box5"></div>
                                                        <div class="box box1"></div>
                                                        <div class="box box2"></div>
                                                        <div class="box box3"></div>
                                                        <div class="box box4"></div>
                                                        <div class="box box5"></div>
                                                        <div class="box box3"></div>
                                                        <div class="box box4"></div>
                                                        <div class="box box5"></div>
                                                        <div class="box box1"></div>
                                                        <div class="box box2"></div>
                                                        <div class="box box3"></div>
                                                        <div class="box box4"></div>
                                                        <div class="box box5"></div>
                                                        <div class="box box3"></div>
                                                        <div class="box box4"></div>
                                                        <div class="box box5"></div>
                                                      </div>

                                    <button type="button" class="btn msg-send-btn rounded-pill ms-2" (click)="stopRecording()"><i class="fa fa-telegram"></i></button>
                                    <button type="button" class="btn msg-send-btn rounded-pill ms-2"><i class="fa fa-close"></i></button>


                                    <!--<div style="text-align:center;margin-top: 200px;" >
                                            <button (click)="initiateRecording()" class="btn btn-primary" *ngIf="!recording" style="cursor: pointer;background-color: green;color: white;font-size: 40px;"> Start Recording </button>
                                            <button (click)="stopRecording()" class="btn btn-danger" *ngIf="recording" style="cursor: pointer;background-color: red;color: white;font-size: 40px;"> Stop Recording </button>
                                            <audio controls="" *ngIf="url">
                                            <source [src]="sanitize(url)" type="audio/wav">
                                            </audio>
                                            </div>-->

                                </form>
                            </div>
                        </div>
                    </div>



                    <div class="chat-cont-right" *ngIf="ConversationUser.length == 0">
                        <div class="empty_state">
                            <svg width="200" height="200" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.08 20.49C11.6861 20.4801 10.3147 20.1372 9.08001 19.49C8.90826 19.3965 8.77917 19.2404 8.71957 19.0541C8.65997 18.8679 8.67445 18.6658 8.76001 18.49C8.85166 18.3164 9.00771 18.1857 9.19467 18.1258C9.38164 18.066 9.58461 18.0819 9.76001 18.17C11.2009 18.9109 12.8466 19.1535 14.44 18.86C15.7143 18.6085 16.8977 18.0193 17.8663 17.154C18.835 16.2887 19.5534 15.179 19.9464 13.941C20.3395 12.703 20.3927 11.3822 20.1006 10.1166C19.8084 8.85094 19.1817 7.68706 18.2858 6.74659C17.3899 5.80612 16.2578 5.1236 15.0078 4.77039C13.7579 4.41718 12.436 4.40625 11.1804 4.73875C9.92481 5.07125 8.78159 5.73496 7.87028 6.6605C6.95896 7.58603 6.31303 8.7394 6.00001 10C5.54983 11.8706 5.85524 13.8432 6.85001 15.49C6.94403 15.6571 6.97052 15.8538 6.92402 16.0398C6.87753 16.2257 6.76159 16.3868 6.60001 16.49C6.43295 16.584 6.23623 16.6105 6.05026 16.564C5.86428 16.5175 5.70317 16.4016 5.60001 16.24C4.86968 15.0324 4.44298 13.6658 4.35648 12.2573C4.26997 10.8487 4.52623 9.44013 5.1033 8.15228C5.68038 6.86443 6.56106 5.73568 7.6699 4.86273C8.77874 3.98978 10.0827 3.39865 11.47 3.14C13.7522 2.71433 16.11 3.21269 18.0248 4.52544C19.9395 5.83819 21.2543 7.85781 21.68 10.14C22.1057 12.4222 21.6073 14.78 20.2946 16.6947C18.9818 18.6095 16.9622 19.9243 14.68 20.35C14.1515 20.4412 13.6163 20.4881 13.08 20.49Z"
                                    fill="#000000" />
                                <path
                                    d="M5.33001 21C5.09027 21.0057 4.85298 20.9507 4.64026 20.8399C4.42755 20.7292 4.24635 20.5664 4.11356 20.3667C3.98076 20.167 3.90071 19.937 3.88085 19.698C3.861 19.459 3.90199 19.2189 4.00001 19L5.53001 15.58C5.56892 15.4879 5.62609 15.4047 5.69807 15.3353C5.77005 15.2659 5.85534 15.2118 5.9488 15.1763C6.04226 15.1408 6.14194 15.1246 6.24183 15.1286C6.34173 15.1327 6.43976 15.157 6.53001 15.2C6.71236 15.283 6.85459 15.4346 6.92575 15.6218C6.99691 15.8091 6.99126 16.0169 6.91001 16.2L5.48001 19.45L9.15001 18.14C9.24441 18.0986 9.34633 18.077 9.44942 18.0768C9.5525 18.0765 9.65454 18.0975 9.74916 18.1384C9.84377 18.1793 9.92894 18.2393 9.99933 18.3146C10.0697 18.3899 10.1238 18.4789 10.1583 18.5761C10.1927 18.6733 10.2068 18.7765 10.1996 18.8793C10.1924 18.9822 10.164 19.0824 10.1163 19.1738C10.0686 19.2652 10.0026 19.3457 9.92236 19.4105C9.84213 19.4752 9.74942 19.5227 9.65001 19.55L5.80001 20.92C5.64956 20.9752 5.49025 21.0023 5.33001 21Z"
                                    fill="#000000" />
                                <path
                                    d="M16 10.75H10C9.80109 10.75 9.61032 10.671 9.46967 10.5303C9.32902 10.3897 9.25 10.1989 9.25 10C9.25 9.80109 9.32902 9.61032 9.46967 9.46967C9.61032 9.32902 9.80109 9.25 10 9.25H16C16.1989 9.25 16.3897 9.32902 16.5303 9.46967C16.671 9.61032 16.75 9.80109 16.75 10C16.75 10.1989 16.671 10.3897 16.5303 10.5303C16.3897 10.671 16.1989 10.75 16 10.75Z"
                                    fill="#000000" />
                                <path
                                    d="M16 14.75H10C9.80109 14.75 9.61032 14.671 9.46967 14.5303C9.32902 14.3897 9.25 14.1989 9.25 14C9.25 13.8011 9.32902 13.6103 9.46967 13.4697C9.61032 13.329 9.80109 13.25 10 13.25H16C16.1989 13.25 16.3897 13.329 16.5303 13.4697C16.671 13.6103 16.75 13.8011 16.75 14C16.75 14.1989 16.671 14.3897 16.5303 14.5303C16.3897 14.671 16.1989 14.75 16 14.75Z"
                                    fill="#000000" />
                            </svg>
                            <p>Please select a chat to Start messaging.</p>
                            <button>Start a conversation</button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!-- /Row -->

    </div>

</div>
<!-- /Page Content -->