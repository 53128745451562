<app-layout-header></app-layout-header>


<!-- Breadcrumb -->
<div class="breadcrumb-bar-two">
    <div class="container">
        <div class="row align-items-center inner-banner">
            <div class="col-md-12 col-12 text-center">
                <h2 class="breadcrumb-title">Privacy Policy</h2>
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                        <li class="breadcrumb-item" aria-current="page">Privacy Policy</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- /Breadcrumb -->


	<!-- Privacy Policy -->
    <div class="terms-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="terms-content pb-0">
                            <p>
                                    <strong>PRIVACY  POLICY</strong>
                                </p>
                                <p>
                                    <strong>Welcome  to PayCall!</strong>
                                </p>
                                <p>
                                    PayCall is owned and operated by  PayCall, Inh. Ayari.
                                </p>
                                <p>
                                    PayCall values your privacy and the  protection of your personal data. This
                                    privacy policy describes what  information we collect from you, how we
                                    collect it, how we use it, how we  obtain your consent, how long we keep it
                                    in our databases and, if necessary,  with whom we share it.
                                </p>
                                <p>
                                    By using the platform and services,  you are accepting the practices
                                    described in this privacy policy. Your use of  the platform is also subject
                                    to our terms andconditions.In this privacy policy,
                                    <a name="_Hlk157294070">
                                        the words "platform" refers to the PayCall  mobile application and the
                                        PayCall website together, "we",  "us", "our", and "PayCall" refers to
                                        PayCall, and  "you" and "user", refers to you, the PayCall user
                                    </a>
                                    (Specialists and Clients).
                                </p>
                                <p>
                                    This privacy policy may change from  time to time. Your continued use of the
                                    platform and services after we make  changes to this privacy policy will be
                                    deemed acceptance of those changes, so  please check this policy
                                    periodically for updates. This privacy policy has been  developed and is
                                    maintained in accordance with all applicable national and  international
                                    laws and regulations and in particular with the General Data  Protection
                                    Regulation (GDPR - European regulation), the revised Swiss Federal  Act on
                                    Data Protection (revFADP) and  The DSGVO (Datenschutz-Grundverordnung).
                                </p>
                                <p>
                                    <strong>1.  GENERAL INFORMATION</strong>
                                </p>
                                <p>
                                    The personal data of users that are  collected and processed through:
                                </p>
                                <p>
                                    <a name="_Hlk131209605"></a><a name="_Hlk111335702"></a><a name="_Hlk130303528">·
                                    <strong>PayCallmobile application</strong></a><strong>(Available on Google
                                    Play and App  Store).</strong> <strong></strong>
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://www.paycall.co/">
                                            <strong>https://www.paycall.co</strong>
                                        </a>
                                        <strong></strong>
                                    </li>
                                </ul>
                                <p>
                                    Will be under the responsibility and  in charge of:
                                </p>
                                <ul>
                                    <li>
                                        <strong>
                                            PayCall, Inh. Ayari. <a name="_Hlk130303555"></a>
                                        </strong>
                                    </li>
                                    <li>
                                        <a href="mailto:contact@paycall.co">
                                            <strong>contact@paycall.co</strong>
                                        </a>
                                        <strong></strong>
                                    </li>
                                </ul>
                                <p>
                                    <strong>2.  HOW WE OBTAIN YOUR CONSENT</strong>
                                </p>
                                <p>
                                    When you use the platform, register  as a user (Specialists and Clients),
                                    publish your profile and services  (Specialists), make an appointment on a
                                    specialist's profile (Clients), provide  calls or video calls (Specialists),
                                    purchase credits (Clients), request a  withdrawal (Specialists), use the
                                    functionalities available on the platform,  use the feedback system, contact
                                    us through our contact details and provide us  with personal information to
                                    contact you, you consent to our collection,  storage and use of your
                                    information under the terms set out in this privacy  policy.
                                </p>
                                <p>
                                    <strong></strong>
                                </p>
                                <p>
                                    <strong>3.  TYPES OF INFORMATION COLLECTED</strong>
                                </p>
                                <p>
                                    The information we collect from our  users helps us to continually improve
                                    the user experience on the platform.  These are the types of information we
                                    collect:
                                </p>
                                <p>
                                    <strong>Information you provide to us.</strong>You provide  information when
                                    you use the platform, register as a user (Specialists and  Clients), publish
                                    your profile and services (Specialists), make an appointment  on a
                                    specialist's profile (Clients), provide calls or video calls  (Specialists),
                                    purchase credits (Clients), request a cancellation  (Specialists), use the
                                    functionalities available on the platform, use the  comment system, contact
                                    us through our contact details and provide us with  personal information to
                                    contact you. As a result of those actions, you may provide us with the
                                    following  information:
                                </p>
                                <ul>
                                    <li>
                                        Full name
                                    </li>
                                    <li>
                                        Profile picture
                                    </li>
                                    <li>
                                        Date and place of birth
                                    </li>
                                    <li>
                                        Password
                                    </li>
                                    <li>
                                        Email
                                    </li>
                                    <li>
                                        Phone number
                                    </li>
                                    <li>
                                        Address
                                    </li>
                                    <li>
                                        Professional information
                                    </li>
                                    <li>
                                        Payment Information
                                    </li>
                                    <li>
                                        Banking information
                                    </li>
                                </ul>
                                <p>
                                    <strong>Information Collected Automatically  (website):</strong>As you
                                    browse our website, certain usage data is automatically collected. These
                                    include technical information such as the IP address of your device, the
                                    type  and version of your browser, the specific pages you visit on our
                                    website, the  date and time of your access, the amount of time you spend on
                                    those pages, and  other diagnostically relevant data.
                                </p>
                                <p>
                                    In the case of access via mobile  devices, we also collect information
                                    specific to these devices. This may  include, but is not limited to, the
                                    type of mobile device used, its unique  identifier, the IP address of the
                                    device, the mobile operating system and the type  of mobile browser, along
                                    with other identifiers and diagnostic data.
                                </p>
                                <p>
                                    In addition, we collect information  that your browser automatically
                                    provides each time you visit our website or  access our website using a
                                    mobile device.
                                </p>
                                <p>
                                    <strong>Information Collected Automatically  (application):</strong>PayCall
                                    automatically collects certain types of information during users' use of the
                                    application. This collection includes technical data such as device type,
                                    operating system, device settings, application usage and interaction
                                    preferences.  This information is essential to improve the functionality and
                                    performance of  the application, as well as to customize the user experience
                                    and ensure system  compatibility. All data collected is handled in
                                    accordance with our privacy  policy, ensuring its protection and
                                    confidentiality.
                                </p>
                                <p>
                                    <strong>Login with Facebook and Google</strong>: When registering or logging
                                    into  our platform using Facebook or Google, users allow us to access their
                                    basic  profile information, such as name, email and profile picture. This
                                    process is  governed by the privacy policies of Facebook and Google. We do
                                    not collect or  store passwords for these accounts. By using this login
                                    method, users consent  to the sharing of this information between our
                                    platform and external services.  This consent can be revoked at any time by
                                    adjusting the user's privacy  preferences.
                                </p>
                                <p>
                                    <strong>User  Content:</strong>PayCall collects and processes user content,
                                    which includes data and  information entered on our platform, solely for the
                                    provision and improvement  of our services. We are committed to maintaining
                                    the confidentiality and  security of this content, and will not share it
                                    with third parties except as  required by law or for the operation of the
                                    service. Users have the right to  access, modify and request deletion of
                                    their information, in accordance with  our data retention policies and
                                    applicable law. Use of our platform implies  consent to the processing of
                                    this data as described in this policy.
                                </p>
                                <p>
                                    <strong>Payment  information:</strong>The user's payment information will be
                                    processed by  the payment processors available on the platform (Stripe),
                                    which will process  and store your data securely and for the sole purpose of
                                    processing the  purchase of credits (Clients) and withdrawals of funds
                                    (Specialists). PayCall  reserves the right to contract any payment processor
                                    available.
                                </p>
                                <p>
                                    See Stripe's  privacy policy here:
                                </p>
                                <ul>
                                    <li>
                                        <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>
                                    </li>
                                </ul>
                                <p>
                                    <strong>Social Media</strong>: On our  platform you will find links and
                                    functions linked to different social networks,  where you can share your
                                    information. It is advisable to consult the privacy  and data protection
                                    policy of each social network used on our platform.
                                </p>
                                <ul>
                                    <li>
                                        Facebook:
                                        <a href="https://www.facebook.com/privacy/explanation">
                                            https://www.facebook.com/privacy/explanation
                                        </a>
                                    </li>
                                    <li>
                                        Instagram:
                                        <a href="http://instagram.com/about/legal/privacy/">
                                            http://instagram.com/about/legal/privacy/
                                        </a>
                                    </li>
                                    <li>
                                        X (Twitter)<strong>:</strong><a href="https://twitter.com/privacy">https://twitter.com/privacy</a>
                                        <strong></strong>
                                    </li>
                                    <li>
                                        Linkedin:
                                        <a href="https://www.linkedin.com/legal/privacy-policy">
                                            https://www.linkedin.com/legal/privacy-policy
                                        </a>
                                    </li>
                                </ul>
                                <p>
                                    <strong>Contact  information</strong>: We may access some personal
                                    information about the  user, such as name and email address, when the user
                                    or any third party  communicates with us through our contact information.
                                    Personal information  provided through our contact information is not stored
                                    on any PayCall server  and will be stored on the respective server of our
                                    email service.
                                </p>
                                <p>
                                    <strong>4.  HOW LONG WE KEEP YOUR DATA</strong>
                                </p>
                                <p>
                                    Personal data provided by users  through the platform will be retained for
                                    the time necessary to provide the  functionalities available on the
                                    platform, fulfill the legitimate purposes  described in section 5 or until
                                    the user closes the user account or requests  the deletion of their data.
                                    PayCall may retain personal data for a longer  period provided that the user
                                    has consented to such processing, as long as such  consent is not withdrawn.
                                    In addition, PayCall may be obliged to retain  personal data for a longer
                                    period provided that this is required for compliance  with a legal
                                    obligation or by order of an authority. Once the retention period  has
                                    expired, the personal data will be deleted. Therefore, the right of access,
                                    the right of deletion, the right of rectification and the right to data
                                    portability cannot be asserted after the retention period has expired.
                                </p>
                                <p>
                                    <strong>5.  HOW WE USE YOUR INFORMATION (LEGITIMATE PURPOSES)</strong>
                                </p>
                                <p>
                                    In general, we use the information  we collect primarily to provide,
                                    maintain, protect and improve our platform and  to provide the
                                    functionalities available on the platform appropriately. We use  personal
                                    information collected through our platformas described below:
                                </p> 
                                <p>
                                    ·          Provide the mobile  application (Available on Google Play and App
                                    Store).
                                </p>
                                <p>
                                    ·          Provide the system to make calls, video calls,  text messages and
                                    voice messages.
                                </p>
                                <p>
                                    ·          Registration and  creation of user profiles.
                                </p>
                                <p>
                                    ·          Provide the functionalities available on the  platform.
                                </p>
                                <ul>
                                    <li>
                                        Provide our credit system.
                                    </li>
                                    <li>
                                        Facilitate the purchase of credits.
                                    </li>
                                    <li>
                                        Process credit payments.
                                    </li>
                                    <li>
                                        Process withdrawals requested by Specialists.
                                    </li>
                                    <li>
                                        Facilitate communication between users.
                                    </li>
                                    <li>
                                        Provide our feedback system.
                                    </li>
                                </ul>
                                <p>
                                    ·          Send notifications to users by mail, email or  SMS related to the
                                    use of the platform.
                                </p>
                                <p>
                                    ·          Understand and improve your experience using  our platform.
                                </p>
                                <p>
                                    ·          Respond to your comments or questions through  our support team.
                                </p>
                                <p>
                                    ·          Send you related information, including  confirmations, invoices,
                                    technical notices, updates, security alerts, and  support and administrative
                                    messages.
                                </p>
                                <ul>
                                    <li>
                                        Send you relevant information about PayCall.
                                    </li>
                                    <li>
                                        PayCall marketing purposes.
                                    </li>
                                </ul>
                                <p>
                                    ·          Process user requests related to the exercise  of their rights
                                    over their personal data.
                                </p>
                                <p>
                                    ·          Link or combine your information with other  data we obtain from
                                    third parties to help us understand your needs and provide  you with better
                                    service.
                                </p>
                                <p>
                                    ·          Protect, investigate and deter fraudulent, unauthorized  or
                                    illegal activities.
                                </p>
                                <p>
                                    <strong>6.  HOW WE SHARE INFORMATION</strong>
                                </p>
                                <p>
                                    Information about our users is an  important part of our business, and we
                                    are not in the business of selling it to  third parties. We share customer
                                    information only as described below.
                                </p>
                                <p>
                                    <strong>Third-Party Service Providers.</strong>We use third party services
                                    to  perform certain functions on our platform. Some of these functions and
                                    services  include: website creation and hosting, payment processing
                                    (Stripe), sending  emails, sending SMS messages, chat services, messaging
                                    services, and data  tracking and analysis services on the platform.
                                </p>
                                <p>
                                    These third-party services and tools  may have access to personal
                                    information needed to perform their functions, but  may not use that
                                    information for other purposes. Information shared with these third-party
                                    services will be treated and stored in accordance with their respective
                                    privacy  policies and our privacy policy.
                                </p>
                                <p>
                                    <strong>Marketing</strong>: By using our services and  platform, you consent
                                    to the use of your personal information, including name  and email address,
                                    for the sole purpose of receiving marketing communications  about our
                                    platform and services. PayCall respects your privacy and does not  share
                                    this information with unaffiliated third parties. If you wish to stop
                                    receiving marketing communications, you can unsubscribe at any time by using
                                    the "unsubscribe" option available in the same marketing emails or by
                                    sending your request through our contact information.
                                </p>
                                <p>
                                    <strong>SMS text messages.</strong>By using our platform and the  services
                                    available on the platform, you consent to the sending of text messages
                                    (SMS) to the phone number provided by the user during registration. These
                                    messages will be sent by us or through third party services, and will
                                    include  relevant information about the services and functionalities
                                    available on the  platform. We undertake to use your telephone number
                                    exclusively for these  purposes and will not share it with other entities
                                    for promotional activities  without your explicit consent. You may
                                    unsubscribe from receiving these  messages at any time by following the
                                    instructions provided in the messages you  receive.
                                </p>
                                <p>
                                    <strong>Business Transfers.</strong> In the event PayCall creates,  merges
                                    with, or is acquired by another entity, your information will likely be
                                    transferred. PayCall will send you an email or post a prominent notice on
                                    our  platform before your information becomes subject to another privacy
                                    policy.
                                </p>
                                <p>
                                    <strong>Protection of PayCall and Others</strong>. We release personal
                                    information  when we believe release is appropriate to comply with the law,
                                    enforce or apply  our terms and conditions and other agreements, or protect
                                    the rights, property,  or safety of PayCall, our users, or others. This
                                    includes exchanging  information with other companies and organizations for
                                    fraud protection and  credit risk reduction.
                                </p>
                                <p>
                                    <strong>Anonymous Information.</strong>PayCall uses anonymous browsing
                                    information collected automatically by our servers primarily to help us
                                    administer and improve the platform. We may also use aggregated anonymous
                                    information to provide information about the platform to potential business
                                    partners and other unaffiliated entities. This information is not personally
                                    identifiable.
                                </p>
                                <p>
                                    <strong>7.  DATA BREACH NOTIFICATIONS</strong>
                                </p>
                                <p>
                                    In the event of a security breach  that compromises the confidentiality of
                                    the personal data of our users, PayCall  undertakes to notify those affected
                                    in a timely manner. This notification will  be made through the means of
                                    contact that have been provided by the user on our  platform. We will take
                                    all reasonable measures to protect the information and  remedy any situation
                                    that compromises the security of your data.
                                </p>
                                <p>
                                    <strong>8.  INTERNATIONAL DATA TRANSFER</strong>
                                </p>
                                <p>
                                    By using our platform, you agree  that your personal data may be transferred
                                    and processed outside the European  Economic Area, where data protection
                                    laws may differ. PayCall is committed to  taking the necessary steps to
                                    ensure that your data is treated in accordance  with applicable privacy
                                    standards and is adequately protected during any  international transfer.
                                </p>
                                <p>
                                    <strong>9.  PROTECTION OF YOUR INFORMATION</strong>
                                </p>
                                <p>
                                    We grant access to your personal information  only to those outside persons
                                    or services that have a legitimate need to know  it and in accordance with
                                    our privacy policy. We adhere to industry-recognized  security standards to
                                    protect your personal information, both during  transmission and in storage.
                                    However, it is important to note that no method of  transmission over the
                                    Internet or electronic storage is foolproof and 100%  secure. Therefore,
                                    while we at PayCall strive to implement commercially viable  data protection
                                    methods, we cannot ensure absolute security of your personal  information.
                                    We undertake not to sell, distribute or transfer your personal  data to
                                    unauthorized third parties, unless we have your explicit consent or are
                                    required by law to do so.
                                </p>
                                <p>
                                    <strong>10.  RIGHTS</strong>
                                </p>
                                <p>
                                    Users who provide information  through our platform, as data subjects, have
                                    the right to access, rectify,  download or delete their information, as well
                                    as to restrict and oppose certain  processing of their information. While
                                    some of these rights apply generally,  others only apply in certain limited
                                    circumstances. These rights are described  below:
                                </p>
                                <ul>
                                    <li>
                                        <strong>Access and portability:</strong> to access and know what
                                        information  is stored on our servers, you can send us your request
                                        through our contact  information.
                                    </li>
                                    <li>
                                        <strong>Rectification, restriction, limitation and  deletion:</strong>
                                        You may  also rectify, restrict, limit or delete much of your
                                        information.
                                    </li>
                                    <li>
                                        <strong>Right to be informed:</strong> Users of our platform will be
                                        informed, upon request, about what data we collect, how it is used, how
                                        long it  is kept and whether it is shared with third parties.
                                    </li>
                                    <li>
                                        <strong>Object:</strong> Where we process your data based on our
                                        legitimate interests, as explained above, or in the public interest, you
                                        may  object to this processing in certain circumstances. In such cases,
                                        we will stop  processing your information unless we have legitimate
                                        grounds to continue  processing it or where necessary for legal reasons.
                                    </li>
                                    <li>
                                        <strong>Withdraw consent</strong>: Where you have previously given your
                                        consent,  for example to allow us to process and store your personal
                                        information, you  have the right to withdraw your consent to the
                                        processing and storage of your  information at any time. For example,
                                        you can withdraw your consent by updating  your settings. In certain
                                        cases, we may continue to process your information  after you have
                                        withdrawn your consent if we have a lawful basis for doing so or  if
                                        your withdrawal of consent was limited to certain processing activities.
                                    </li>
                                    <li>
                                        <strong>Complaint:</strong> If you wish to lodge a complaint about our
                                        use  of your information (and without prejudice to any other rights you
                                        may have),  you have the right to do so with your local supervisory
                                        authority. Users may  exercise all of these rights by contacting us via
                                        the contact information or  contact page.
                                    </li>
                                    <li>
                                        <strong>
                                            Rights related to automated decision-making,  including profiling:
                                        </strong>
                                        Users may request that we provide them with a copy of the automated
                                        processing  activities we conduct if they believe that data is being
                                        unlawfully processed.
                                    </li>
                                </ul>
                                <p>
                                    Users or holders of personal data  provided through the platform may
                                    exercise these rights over their personal  data at any time and without
                                    limitation by sending their request through our  contact information. The
                                    request to exercise their rights will be attended and  answered within a
                                    maximum period of 10 working days.
                                </p>
                                <p>
                                    <strong>11.  RIGHT OF EDITING AND DELETION</strong>
                                </p>
                                <p>
                                    We are committed to ensuring that  our users maintain full control over
                                    their data. We therefore grant users the  explicit right to modify or delete
                                    any information they have provided to the  platform.
                                </p>
                                <p>
                                    Users can exercise this right  directly through their user account, by
                                    facilitating the editing or deletion of  data such as their personal
                                    information, profile information, services, etc. In  the event that certain
                                    requests cannot be handled through the user's account,  users may contact us
                                    through our contact information to make the necessary  edits or deletions.
                                </p>
                                <p>
                                    Our approach is designed to ensure  that users have constant and effective
                                    control over their data, allowing them  to manage their information in a way
                                    that protects their privacy and autonomy.
                                </p>
                                <p>
                                    <strong>12.  PROTECTION OF CHILDREN'S ONLINE PRIVACY</strong>
                                </p>
                                <p>
                                    We comply with national and  international data protection regulations
                                    regarding the protection of  children's personal data. Our platform and
                                    services are intended for persons  over the age of 16, so we do not collect
                                    any information from persons under the  age of 16. If we become aware that a
                                    child under the age of 16 has provided us  with personal information, we
                                    will take steps to delete such information.
                                </p>
                                <p>
                                    <strong>13.  THIRD PARTIES</strong>
                                </p>
                                <p>
                                    Except as expressly included in this  privacy policy, this document
                                    addresses only the use and disclosure of  information PayCall collects from
                                    you. If you disclose your information to  others, whether other users or
                                    PayCall vendors, different rules may apply to  their use or disclosure of
                                    the information you disclose to them. PayCall does  not control the privacy
                                    policies of third parties, and you are subject to the  privacy policies of
                                    those third parties where applicable. PayCall is not  responsible for the
                                    privacy or security practices of another Internet platform,  including those
                                    linked to or from the PayCallplatform. Please review the  privacy policies
                                    of third-party websites or services that you access through  the PayCall
                                    platform.
                                </p>
                                <p>
                                    <strong>14.  CHANGES TO PRIVACY POLICY</strong>
                                </p>
                                <p>
                                    We reserve the right to change our  privacy policy at any time. Changes will
                                    be promptly notified to our users and  posted on the website. Your continued
                                    use of our website and services following  these changes constitutes
                                    acceptance of the changes.
                                </p>
                                <p>
                                    <strong>15.  CONTACT INFORMATION</strong>
                                </p>
                                <p>
                                    If you have questions or concerns  about this privacy policy and the
                                    treatment and security of your data, please  contact us through our contact
                                    forms or by using the following contact  information: <strong></strong>
                                </p>
                                <p>
                                    <strong>PayCall, Inh. Ayari - PayCall.</strong>
                                </p>
                                <p>
                                    <a href="mailto:contact@paycall.co">
                                        <strong>contact@paycall.co</strong>
                                    </a>
                                    <strong></strong>
                                </p>
                                <p>
                                    <strong></strong>
                                </p>
                                
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Privacy Policy -->	


<app-layout-footer></app-layout-footer>