/*import {Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
//call service 
import { CallService } from '../../services/apis/calls/call.service';

@Component({
  selector: 'app-ringing-model',
  templateUrl: './ringing-model.component.html',
  styleUrls: ['./ringing-model.component.scss']
})
export class RingingModelComponent implements OnInit {

  audio =  new Audio();

  constructor(   
    private router: Router,
    public callService: CallService,
    public dialogRef: MatDialogRef<RingingModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {

      this.audio.src = '../../../assets/tones/ringSender.mp3';
      this.audio.load();
      this.audio.play();

      setTimeout(() => {

        this.audio.pause();
        this.closeDialog();

       }, 30000);

       //this.closeDialog();

  }

  closeDialog() {

    this.dialogRef.close();

  }


  async acceptCall(){  
    this.audio.pause();   
    this.dialogRef.close();
    this.router.navigate(['/make-call/', this.data.senderDetails.sender, this.data.senderDetails.call_type, { roomId: this.data.senderDetails.room_id }]);
  }

}*/



import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CallService } from '../../services/apis/calls/call.service';

@Component({
  selector: 'app-ringing-model',
  templateUrl: './ringing-model.component.html',
  styleUrls: ['./ringing-model.component.scss']
})
export class RingingModelComponent implements OnInit, OnDestroy {

  audio = new Audio();

  constructor(
    private router: Router,
    public callService: CallService,
    public dialogRef: MatDialogRef<RingingModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.playRingtone();
  }

  ngOnDestroy(): void {
    this.stopRingtone();
  }

  playRingtone(): void {
    this.audio.src = '../../../assets/tones/ringSender.mp3';
    this.audio.load();
    this.audio.play();
  }

  stopRingtone(): void {
    this.audio.pause();
    this.audio.currentTime = 0;
  }

  closeDialog(): void {
    this.stopRingtone();
    this.dialogRef.close();
  }

  async acceptCall(): Promise<void> {
    this.stopRingtone();
    this.dialogRef.close();
    this.router.navigate(['/make-call/', this.data.senderDetails.sender, this.data.senderDetails.call_type, { roomId: this.data.senderDetails.room_id }]);
  }

}
