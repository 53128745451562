import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

//full calendar
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

//materials
import { MatDialog } from '@angular/material/dialog'; //dialogue module
import { RingingModelComponent } from '../../modals/ringing-model/ringing-model.component';

import { ConversationPopupComponent } from '../../modals/conversation-popup/conversation-popup.component';

//router
import { Router, ActivatedRoute, Route } from "@angular/router";

//user service
import { UserService } from "../../services/apis/users/user.service";
import { FavorieService } from "../../services/apis/favorites/favorie.service";
import { WalletService } from "../../services/apis/wallets/wallet.service";


import { ToastrService } from 'ngx-toastr';

import { Fancybox } from '@fancyapps/ui';

import { SwiperComponent } from 'swiper/angular';

// import Swiper core and required components
import SwiperCore , {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
  SwiperOptions 
} from 'swiper';
import { Subscription, timer, interval, BehaviorSubject } from 'rxjs';

import * as swiper from "swiper/types/swiper-class";

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);



@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  pay_call_id:any = localStorage.getItem("pay_id");

  user_id: string;
  singleUser: any = [];
  mediaPictures: any = [];
  userSocialLinks:any = [];
  dateTimeNow: any;

  tagsListe = [];
  languages = [];

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    weekends: false,
    events: [
      { title: 'Meeting', start: new Date() }
    ] 
  };

  canCall:boolean = true;


  @ViewChild('swiperRef') swiperRef?: SwiperComponent;

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: this.userSocialLinks.length >= 10,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 3.5,   
        spaceBetween: 2
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 3,
        spaceBetween: 2
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 12,
        spaceBetween: 2
      }
    }    
  };




  constructor(
    private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public UserService: UserService,
    public favorieService: FavorieService,
    private toastr: ToastrService,
    private elRef: ElementRef,
    public walletService: WalletService,
  ) {
    if (this.activatedRoute.snapshot.paramMap.get("id")) {
      this.user_id = this.activatedRoute.snapshot.paramMap.get("id");
      //console.log(this.user_id);
    }
   }

  ngOnInit(): void {

    this.getSingleUser(this.user_id);
    Fancybox.bind(this.elRef.nativeElement, '[data-fancybox]', {
      // Custom options
    });
    this.getSingleUserWallet();
    
  }

  getSingleUser(user_id: any) {
    //Get saved list of students
    this.UserService.viewSingleUser(user_id).subscribe((response) => {
      this.singleUser = response;
      this.getUserMediaPictures(response.id);
      this.getUserSocialMediaLinksList(response.id);
      this.addVisitorToUser(response.id);
      console.log(response);
      //this.output = response.speciality.split(",");
      this.tagsListe = JSON.parse(response.speciality);
      this.languages = JSON.parse(response.profilelanguages);
    });
  }


  public conversationModel(): void {

    const dialogRef = this.matDialog.open(ConversationPopupComponent, {
      panelClass: 'dialog-conversation-popup',
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'auto',
      width: 'auto',
      data: {user: this.singleUser},
    });

  }

  /**
   * add visitor to profile +1
  */

  addVisitorToUser(id:any){
    this.UserService.addVisitorByUser(id).subscribe();
  }

  /**
  * Fetch user social media links 
  */
 getUserSocialMediaLinksList(id:any) {
  //Get social  media links list
  this.UserService.fetchAccountSocialMediaLinksList(id).subscribe((response) => {
    console.log('readyyyyyyyyyyyyyyyyyyyyyyyyyyyyy', response);
    this.userSocialLinks = response;
  });
}

showSocialLink(link: any, event: any) {
  //console.log('https://www.instagram.com/'+event);
  (window as any).open(link + event, "_blank");
}

  /**
  * Fetch User media pictures 
  **/
 getUserMediaPictures(id:any) {
  //Get social  media links list
  this.UserService.getUserMediaPictures(id).subscribe((response) => {
    //console.log('dfgdgdfgfd', response);
    this.mediaPictures = response;
  });
}


getSingleUserWallet() {
  //Get saved list of students
  this.walletService.getSingleUserWallet().subscribe((response) => {
    //console.log(" check my wallet ", response);
    //this.userWallet = response;
    //this.canCall = (response.sold > 0) ? true : false;
    
  });
}

 noCallPossibleAlert() {
  this.toastr.warning('No balance');
}



stringPoint(id:any){
  let parts = id.match(/.{1,3}/g);
  return parts.join(".");
}

getFirst(speciality:any){
  if(speciality){
    let data = JSON.parse(speciality);
    return data[0];
  }

}


add_bookmark(user_id: any) {
  this.favorieService.addUserToBookmark(user_id).subscribe((response) => {
    //this.presentToast("user successfuly saved to bookmark");
    this.toastr.success('user successfuly saved to bookmark');
  });
}


block_user(user_id: any) {
  this.favorieService.addUserToBlackListe(user_id).subscribe((response) => {
    //this.presentToast("user successfuly saved to bookmark");
    this.toastr.success('user successfuly added to black liste');
  });
}


ngOnDestroy() {
  Fancybox.unbind(this.elRef.nativeElement);
  Fancybox.close();
}



}
