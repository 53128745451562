<app-layout-header></app-layout-header>
<!-- Page Content -->
<div class="content">
   <div class="container">
      <div class="row">
         <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <app-menu-layout></app-menu-layout>
         </div>
         <div class="col-md-7 col-lg-8 col-xl-9">
            <!-- Basic Information -->
            <div class="card">
               <div class="card-body">
                  <h4 class="card-title">Basic Information</h4>
                  <div class="row">
                     <div class="col-md-12">
                        <div class="mb-3">
                           <div class="change-avatar">
                              <div class="profile-img">
                                 <img src="{{currentUser.thumbnail}}" alt="User Image">
                              </div>
                              <div class="upload-img">
                                 <div class="change-photo-btn">
                                    <span><i class="fa fa-upload"></i> Upload Photo</span>
                                    <input type="file" class="upload" (change)="onFileSelected($event)" id="uploader">
                                 </div>
                                 <small class="form-text text-muted">Allowed JPG, GIF or PNG. Max size of
                                    2MB</small><br>
                                    <span class="text-danger d-none">{{ipAddress}}</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <form #form="ngForm" (ngSubmit)="submitProfile(form)">
                     <div class="row">
                        <div class="col-md-6">
                           <div class="mb-3">
                              <label class="mb-2">Username <span class="text-danger">*</span></label>
                              <input type="text" [(ngModel)]="currentUser.full_name" #full_name="ngModel"
                                 name="full_name" class="form-control">
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="mb-3">
                              <label class="mb-2">Email <span class="text-danger">*</span></label>
                              <input type="email" [(ngModel)]="currentUser.email" #email="ngModel" name="email"
                                 class="form-control">
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="mb-3">
                              <label class="mb-2">Phone Number</label>
                              <input type="text" value="{{currentUser.phone}}" class="form-control" disabled>
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="mb-0">
                              <label class="mb-2">Date of Birth</label>
                              <input type="date" type="date" [(ngModel)]="currentUser.birth_date" #birth_date="ngModel"
                                 name="birth_date" class="form-control">
                           </div>
                        </div>

                        <div class="col-md-6">
                           <div class="mb-3" *ngIf="currentUser.is_verified == 1">
                              <label class="mb-2">Date of Birth</label>
                              <select class="form-control" aria-label="Select Specialities (0/5)"
                                 placeholder="Select Specialities (0/5)" (change)="specChange($event)">
                                 <option value="Developer">Developer</option>
                                 <option value="Graphic">Graphic</option>
                                 <option value="Designer">Designer</option>
                                 <option value="Producter">Producter</option>
                                 <option value="speciality-5">Speciality 5</option>
                              </select>
                           </div>
                        </div>

                        <div class="col-md-6">
                           <div class="mb-3" *ngIf="currentUser.is_verified == 1">
                              <label class="mb-2">Date of Birth</label>
                              <select class="form-control" aria-label="Select profiles languages (0/5)"
                                 placeholder="Select profiles languages (0/5)"
                                 (change)="profileLanguageHandler($event)">
                                 <option value="English">English</option>
                                 <option value="Frensh">Frensh</option>
                                 <option value="German">German</option>
                                 <option value="Arabic">Arabic</option>
                                 <option value="Spanish">Spanish</option>
                              </select>
                           </div>
                        </div>



                        <div class="col-md-6">
                           <div class="mb-3">
                              <mat-form-field class="example-chip-list" style="    width: 100%;">
                                 <mat-label>Tags</mat-label>
                                 <mat-chip-list #chipList aria-label="Fruit selection">
                                    <mat-chip *ngFor="let fruit of fruits" [selectable]="selectable"
                                       [removable]="removable" (removed)="remove(fruit)">
                                       {{fruit}}
                                       <mat-icon matChipRemove *ngIf="removable">
                                          <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                             <path
                                                d="M9.17065 4C9.58249 2.83481 10.6937 2 11.9999 2C13.3062 2 14.4174 2.83481 14.8292 4"
                                                stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                             <path d="M20.5 6H3.49988" stroke="black" stroke-width="1.5"
                                                stroke-linecap="round" />
                                             <path
                                                d="M18.3735 15.3991C18.1965 18.054 18.108 19.3815 17.243 20.1907C16.378 21 15.0476 21 12.3868 21H11.6134C8.9526 21 7.6222 21 6.75719 20.1907C5.89218 19.3815 5.80368 18.054 5.62669 15.3991L5.16675 8.5M18.8334 8.5L18.6334 11.5"
                                                stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                             <path d="M9.5 11L10 16" stroke="black" stroke-width="1.5"
                                                stroke-linecap="round" />
                                             <path d="M14.5 11L14 16" stroke="black" stroke-width="1.5"
                                                stroke-linecap="round" />
                                          </svg>
                                       </mat-icon>
                                    </mat-chip>
                                    <input placeholder="New tag..." #fruitInput [formControl]="fruitCtrl"
                                       [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                       (matChipInputTokenEnd)="add($event)" [hidden]="fruits.length >= 5">
                                 </mat-chip-list>
                                 <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                    <mat-option *ngFor="let fruit of filteredFruits | async" [value]="fruit">
                                       {{fruit}}
                                    </mat-option>
                                 </mat-autocomplete>
                              </mat-form-field>
                           </div>
                        </div>

                        <div class="col-md-6">
                           <div class="mb-3">
                              <mat-form-field style="    width: 100%;">
                                 <mat-label>Languages</mat-label>
                                 <mat-select [formControl]="toppings" multiple>
                                    <mat-select-trigger>
                                       {{toppings.value ? toppings.value[0] : ''}}
                                       <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                                          (+{{toppings.value.length - 1}}
                                          {{toppings.value?.length === 2 ? 'other' : 'others'}})
                                       </span>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>
                        </div>

                        <div class="col-md-12">
                           <div class="mb-3">
                              <mat-form-field style="width: 100%;">
                                 <mat-label>Countries</mat-label>
                                 <mat-select [(ngModel)]="currentUser.country" #country="ngModel" name="country">
                                    <mat-option *ngFor="let country of countriesListe" [value]="country">
                                       {{ country }}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>
                        </div>


                        <div class="mb-3">
                           <label class="mb-2">More Informations</label>
                           <!--<textarea class="form-control mb-3" rows="5" [(ngModel)]="currentUser.about_me"
                              #about_me="ngModel" name="about_me" style=" max-height: 150px !important;"  #aboutMeTextArea></textarea>-->
                              <textarea class="form-control mb-3" rows="5" name="about_me" #aboutMeTextArea name="about_me"></textarea>
                        </div>

                        
                        <div class="submit-section submit-btn-bottom">
                           <button type="submit" class="btn btn-primary prime-btn">Save Changes</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
            <!-- /Basic Information -->
            <!-- Clinic Info -->
            <div class="card">
               <div class="card-body">
                  <h4 class="card-title">Profile Media Center</h4>
                  <div class="row">
                     <div class="col-md-12">
                        <div class="mb-3" *ngIf="mediaBase64Output == ''">
                           <div class="dropzone-media"
                              onclick="document.querySelector('#dedia-center-uploader').click()">
                              <h3>Click to select media picture</h3>
                              <input type="file" (change)="onMediaFileSelected($event)" id="dedia-center-uploader"
                                 accept="image/png, image/jpg, image/jpeg" hidden />
                           </div>
                        </div>
                        <div class="mb-3" *ngIf="mediaBase64Output != ''">
                           <div class="dropzone-media">
                              <img *ngIf="mediaBase64Output != ''" src="{{mediaBase64Output}}"
                                 style="width: 100%;height: 100%;object-fit: contain;">
                              <div *ngIf="mediaBase64Output != ''" class="media-add-btns">
                                 <a (click)="cancelPicture()" class="mr-5 bg-danger">
                                    <svg width="30" height="30" viewBox="0 0 24 24" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                       <path
                                          d="M9.17065 4C9.58249 2.83481 10.6937 2 11.9999 2C13.3062 2 14.4174 2.83481 14.8292 4"
                                          stroke="white" stroke-width="1.5" stroke-linecap="round" />
                                       <path d="M20.5 6H3.49988" stroke="white" stroke-width="1.5"
                                          stroke-linecap="round" />
                                       <path
                                          d="M18.3735 15.3991C18.1965 18.054 18.108 19.3815 17.243 20.1907C16.378 21 15.0476 21 12.3868 21H11.6134C8.9526 21 7.6222 21 6.75719 20.1907C5.89218 19.3815 5.80368 18.054 5.62669 15.3991L5.16675 8.5M18.8334 8.5L18.6334 11.5"
                                          stroke="white" stroke-width="1.5" stroke-linecap="round" />
                                       <path d="M9.5 11L10 16" stroke="white" stroke-width="1.5"
                                          stroke-linecap="round" />
                                       <path d="M14.5 11L14 16" stroke="white" stroke-width="1.5"
                                          stroke-linecap="round" />
                                    </svg>
                                 </a>
                                 <a (click)="saveMediaPicture()" class="bg-success">
                                    <svg fill="white" width="30" height="30" viewBox="0 0 96 96"
                                       xmlns="http://www.w3.org/2000/svg">
                                       <g>
                                          <path
                                             d="M58.3945,32.1563,42.9961,50.625l-5.3906-6.4629a5.995,5.995,0,1,0-9.211,7.6758l9.9961,12a5.9914,5.9914,0,0,0,9.211.0059l20.0039-24a5.9988,5.9988,0,1,0-9.211-7.6875Z" />
                                          <path
                                             d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z" />
                                       </g>
                                    </svg>
                                 </a>
                              </div>
                           </div>
                        </div>
                        <div class="upload-wrap">
                           <div class="upload-images" *ngFor="let picture of mediaPictures">
                              <img src="{{picture.picture}}" alt="Upload Image">
                              <a href="javascript:void(0);" (click)="deleteUserMediaPictures(picture.id)"
                                 class="btn btn-icon btn-danger btn-sm">
                                 <svg width="30" height="30" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                       d="M9.17065 4C9.58249 2.83481 10.6937 2 11.9999 2C13.3062 2 14.4174 2.83481 14.8292 4"
                                       stroke="white" stroke-width="1.5" stroke-linecap="round" />
                                    <path d="M20.5 6H3.49988" stroke="white" stroke-width="1.5"
                                       stroke-linecap="round" />
                                    <path
                                       d="M18.3735 15.3991C18.1965 18.054 18.108 19.3815 17.243 20.1907C16.378 21 15.0476 21 12.3868 21H11.6134C8.9526 21 7.6222 21 6.75719 20.1907C5.89218 19.3815 5.80368 18.054 5.62669 15.3991L5.16675 8.5M18.8334 8.5L18.6334 11.5"
                                       stroke="white" stroke-width="1.5" stroke-linecap="round" />
                                    <path d="M9.5 11L10 16" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                                    <path d="M14.5 11L14 16" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                                 </svg>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- /Clinic Info -->
            <!-- Pricing -->
            <div class="card">
               <div class="card-body">
                  <form #pricingForm="ngForm" (ngSubmit)="submitPayementChange(pricingForm)">
                     <h4 class="card-title">Pricing</h4>
                     <div class="row mb-3">
                        <div class="col-lg-6 col-md-6 col-6 col-sm-6">
                           <div class="mb-2 d-flex align-items-center justify-content-between">
                              <label for="hue">Voice call</label>
                              <div class="on-off d-flex align-items-center">
                                    <input type="checkbox" id="switchVoiceCall" [checked]="currentUser.voice_call_active == 1" (change)="onOffPricing($event, 'voice_call_active')" />
                                    <label for="switchVoiceCall">Toggle</label>
                              </div>
                           </div>
                           <div class="number-input-container mb-3">
                              <button type="button" class="button-decrement"></button>
                              <div class="number-input">
                                 <input type="number" name="voice_call_price" class="form-control input-price"
                                    [(ngModel)]="currentUser.voice_call_price" #voice_call_price="ngModel" />
                              </div>
                              <button type="button" class="button-increment">
                              </button>
                           </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-6 col-sm-6">
                           <div class="mb-2 d-flex align-items-center justify-content-between">
                              <label for="hue">Video call</label>
                              <div class="on-off d-flex align-items-center">
                                 <input type="checkbox" id="switchVideoCall"  [checked]="currentUser.video_call_active == 1" (change)="onOffPricing($event, 'video_call_active')"/><label for="switchVideoCall">Toggle</label>
                              </div>
                           </div>
                           <div class="number-input-container mb-3">
                              <button type="button" class="button-decrement"></button>
                              <div class="number-input">
                                 <input type="number" name="video_call_price" class="form-control input-price"
                                    [(ngModel)]="currentUser.video_call_price" #video_call_price="ngModel" />
                              </div>
                              <button type="button" class="button-increment">
                              </button>
                           </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-6 col-sm-6">
                           <div class="mb-2 d-flex align-items-center justify-content-between">
                              <label for="hue">Text Message</label>
                              <div class="on-off d-flex align-items-center">
                                    <input type="checkbox" id="switchTextMessage"  [checked]="currentUser.text_message_active == 1" (change)="onOffPricing($event, 'text_message_active')"/><label for="switchTextMessage">Toggle</label>
                              </div>
                           </div>
                           <div class="number-input-container">
                              <button type="button" class="button-decrement"></button>
                              <div class="number-input">
                                 <input type="number" name="text_message_price" class="form-control input-price"
                                    [(ngModel)]="currentUser.text_message_price" #text_message_price="ngModel" />
                              </div>
                              <button type="button" class="button-increment">
                              </button>
                           </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-6 col-sm-6">
                           <div class="mb-2 d-flex align-items-center justify-content-between">
                              <label for="hue">Voice Message</label>
                              <div class="on-off d-flex align-items-center">
                                    <input type="checkbox" id="switchVoiceMessage"  [checked]="currentUser.voice_message_active == 1" (change)="onOffPricing($event, 'voice_message_active')"/><label for="switchVoiceMessage">Toggle</label>
                              </div>
                           </div>
                           <div class="number-input-container">
                              <button type="button" class="button-decrement"></button>
                              <div class="number-input">
                                 <input type="number" name="voice_message_price" class="form-control input-price"
                                    [(ngModel)]="currentUser.voice_message_price" #voice_message_price="ngModel" />
                              </div>
                              <button type="button" class="button-increment">
                              </button>
                           </div>
                        </div>
                     </div>

                     <div class="submit-section submit-btn-bottom">
                        <button type="submit" class="btn btn-primary prime-btn">Save Changes</button>
                     </div>


                  </form>
               </div>
            </div>
            <!-- /Pricing -->
         </div>
      </div>
   </div>
</div>
<!-- /Page Content -->