<app-layout-header></app-layout-header>

<!-- Breadcrumb -->
<div class="breadcrumb-bar-two">
    <div class="container">
       <div class="row align-items-center inner-banner">
          <div class="col-md-12 col-12 text-center">
             <h2 class="breadcrumb-title">FAQ </h2>
             <nav aria-label="breadcrumb" class="page-breadcrumb">
                <ol class="breadcrumb">
                   <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
                   <li class="breadcrumb-item" aria-current="page">FAQ</li>
                </ol>
             </nav>
          </div>
       </div>
    </div>
 </div>
 <!-- /Breadcrumb -->
 <!-- Privacy Policy -->
 <div class="terms-section">
    <div class="container">
       <div class="row">
          <div class="col-md-12">
             <div class="terms-content pb-0">
                <div class="col-lg-12">
                   <div class="product-grid container">
                     
                      <div class="accordion border-0 " id="accordionPanelsStayOpenExample">

                          <h1 style="color:#0e82fd;font-size: 32px;">For Providers</h1>


                         <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-heading1">
                               <button class="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse1" aria-expanded="false" aria-controls="panelsStayOpen-collapse1">
                                How Does It Work?
                               </button>
                            </h2>
                            <div id="panelsStayOpen-collapse1" class="accordion-collapse collapse border-0" aria-labelledby="panelsStayOpen-heading1">
                               <div class="accordion-body">
                                  <p>Step 1: As a provider, sign up for an account on our platform using our web version or iOS/Android apps. If you want to manage a team, you can do so on the web version, which includes access to a team interface. If you’re an individual, the following steps apply:</p>

                                      <p>Step 2: Set up your fees for instant audio and video calls (per minute),  your schedule, and information about your services.</p>

                                          <p>Step 3: Promote your PayCall profile by sharing the link provided on your account’s homepage. You can share it on your website or on social media, groups, forums, or directly with clients.</p>

                                              <p>Step 4: Clients can use the link to access your profile and instantly call you (charged per minute). They’ll be prompted to deposit funds into they PayCall Wallet when attempting to contact you.</p>

                                                  <p>NOTE: The platform works via Internet, not via GSM (phone numbers). For both instant calls and appointments only the clients can initiate the call. There’s no need for you to install any other software. Just make sure you are logged in in your PayCall account either in browser (make sure you keep the PayCall page open when you expect calls) or in the iOS/Android app (the phone will ring even if the app is closed as long as you are logged in).</p>
                               </div>
                            </div>
                         </div>
                         <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-heading2">
                               <button class="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse2" aria-expanded="false" aria-controls="panelsStayOpen-collapse2">
                               Will My Clients Need To Download The App ?
                               </button>
                            </h2>
                            <div id="panelsStayOpen-collapse2" class="accordion-collapse collapse border-0" aria-labelledby="panelsStayOpen-heading2">
                               <div class="accordion-body">
                                  <p>Not necessarily! The platform can be used both as apps or in browser (both mobile and desktop).</p>
                               </div>
                            </div>
                         </div>
                         <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-heading3">
                               <button class="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse3" aria-expanded="false" aria-controls="panelsStayOpen-collapse3">
                               Can i take call on my desktop
                               </button>
                            </h2>
                            <div id="panelsStayOpen-collapse3" class="accordion-collapse collapse border-0" aria-labelledby="panelsStayOpen-heading3">
                               <div class="accordion-body">
                                  <p>Yes, you can take calls on your desktop computer using the web version.
                                    </p>
                               </div>
                            </div>
                         </div>
                         <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-heading4">
                               <button class="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse4" aria-expanded="false" aria-controls="panelsStayOpen-collapse4">
                                  How Much Does The Platform Take?
                               </button>
                            </h2>
                            <div id="panelsStayOpen-collapse4" class="accordion-collapse collapse border-0" aria-labelledby="panelsStayOpen-heading4">
                               <div class="accordion-body">
                                  <p>PayCall retains a 17% commission from all transactions.</p>
                               </div>
                            </div>
                         </div>
                         <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-heading5">
                               <button class="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse5" aria-expanded="false" aria-controls="panelsStayOpen-collapse5">
                                  Are There Any Other Fees Or Taxes I Need To Pay?
                               </button>
                            </h2>
                            <div id="panelsStayOpen-collapse5" class="accordion-collapse collapse border-0" aria-labelledby="panelsStayOpen-heading5">
                               <div class="accordion-body">
                                  <p>NO. The 17% commission covers payment processing and the PayCall service as well.

                                    </p>
                               </div>
                            </div>
                         </div>
                         <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-heading6">
                               <button class="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse6" aria-expanded="false" aria-controls="panelsStayOpen-collapse6">
                                  When Will I Receive Payment?
                               </button>
                            </h2>
                            <div id="panelsStayOpen-collapse6" class="accordion-collapse collapse border-0" aria-labelledby="panelsStayOpen-heading6">
                               <div class="accordion-body">
                                  <p>
                                      Your earnings from a call are added to your balance immediately after the call ends. You can view your balance at any time on the Home screen in the upper left corner. You can withdraw your balance to your bank account or PayPal as long as the balance is at least $20.
                                  </p>
                               </div>
                            </div>
                         </div>
                         <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-heading7">
                               <button class="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse7" aria-expanded="false" aria-controls="panelsStayOpen-collapse7">
                                  When Can I Receive Calls?
                               </button>
                            </h2>
                            <div id="panelsStayOpen-collapse7" class="accordion-collapse collapse border-0" aria-labelledby="panelsStayOpen-heading7">
                               <div class="accordion-body">
                                  <p>
                                      You can receive calls as soon as you create your account and are online. You can check/set your online status in your Home Screen. If using the mobile app, you can receive calls even if the app is closed or running in the background, as long as you are logged in and online. If using the web version you need to have the PayCall website open and be logged in.


                                  </p>
                               </div>
                            </div>
                         </div>

                         
                         <h1 style="color:#0e82fd;font-size: 32px;margin-top: 30px;">For Callers</h1>

                         <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                               <button class="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                  How Am I Being Charged For A Call?
                               </button>
                            </h2>
                            <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse show border-0" aria-labelledby="panelsStayOpen-headingFive">
                               <div class="accordion-body">
                                  <p>
                                      For instant calls, the platform displays the cost of your call in real-time, which includes:

                                      
                                  </p>
                                  <p>The provider’s per-minute fee multiplied by the duration of the call, starting from the second minute.</p>
                               </div>
                            </div>
                         </div>

                         <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                               <button class="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                  Besides What I Pay To The Provider, Are There Any Other Fees Or Taxes?

                               </button>
                            </h2>
                            <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse show border-0" aria-labelledby="panelsStayOpen-headingFive">
                               <div class="accordion-body">
                                  <p>
                                      You can receive calls as soon as you create your account and are online. You can check/set your online status in your Home Screen. If using the mobile app, you can receive calls even if the app is closed or running in the background, as long as you are logged in and online. If using the web version you need to have the PayCall website open and be logged in.


                                  </p>
                               </div>
                            </div>
                         </div>

                         <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                               <button class="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                  When And How Is The Payment Made?
                               </button>
                            </h2>
                            <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse show border-0" aria-labelledby="panelsStayOpen-headingFive">
                               <div class="accordion-body">
                                  <p>
                                      For instant calls, your wallet will be charged automatically at the end of the call.

                                  </p>
                               </div>
                            </div>
                         </div>

                         <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                               <button class="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                  Is The Platform Responsible For The Quality Of The Provided Services?
                               </button>
                            </h2>
                            <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse show border-0" aria-labelledby="panelsStayOpen-headingFive">
                               <div class="accordion-body">
                                  <p>
                                      No, PayCall is a marketplace that connects clients with provider for online services. We do not assume responsibility for the quality of services or the accuracy of consultant profiles and marketing information. Please refer to our Terms and Conditions for more information.
                                  </p>
                               </div>
                            </div>
                         </div>

                         <div class="accordion-item">
                            <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                               <button class="accordion-button collapsed border-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                  Can A Call Be Recorded Or Accessed By A Third Party?
                               </button>
                            </h2>
                            <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse show border-0" aria-labelledby="panelsStayOpen-headingFive">
                               <div class="accordion-body">
                                  <p>
                                      The sessions between the consultant and the client are completely private.


                                  </p>
                               </div>
                            </div>
                         </div>


                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
 <!-- /Privacy Policy -->


<app-layout-footer></app-layout-footer>