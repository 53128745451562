import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

    // API path
    base_path = 'https://preprod.paycall.co/index.php/api/chat';
    //base_path = 'http://localhost:8080/api/chat';
    current_user = localStorage.getItem('pay_id');

    constructor(private http: HttpClient) { }
  
  
    // Handle API errors
    handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {  
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError(
        'Something bad happened; please try again later.');
    };
  
  
    // Get users lis
    getConversations(): Observable<any> {
      return this.http
        .get<any>(this.base_path+'/'+this.current_user)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    getConversationMessages(__convId:any): Observable<any> {
      return this.http
        .get<any>(this.base_path+'/conversation/'+__convId)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    getConversationLastMessage(__convId:any): Observable<any> {
      return this.http
        .get<any>(this.base_path+'/conversation/last-message/'+__convId)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    newMessage(__form:any)
    {
      return this.http.post(this.base_path+'/conversation/new-message', __form)
      .subscribe(data => {
       }, error => {
        console.log(error);
      });

    }


    deleteConversation(__convId:any): Observable<any> {
      return this.http
        .get<any>(this.base_path+'/conversation/delete-conversation/'+__convId)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }


    getUserMessagesNotifications(): Observable<any> {
      return this.http
        .get<any>(this.base_path+'/top-notifications/'+this.current_user)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }

    getMessagesHistoryByProfile(profIf): Observable<any> {

      //console.log('wxtsdfsd', profIf, this.current_user);
      return this.http
        .get<any>(this.base_path+'/conversation/conversation-by-single-profile/'+this.current_user+'/'+profIf)
        .pipe(
          retry(2),
          catchError(this.handleError)
        )
    }


    
  

}
