import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog'; //dialogue module
import { Web3ModalComponent } from '../../modals/web3-modal/web3-modal.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  blockNumber: any;
  isLoggedIn: boolean = false;
  currentUser: any = [];
  categories: any = []; //categories table
  target: string = '';
  creditsSold: any;
  nbrUnreadMessages: number = 0;
  SearchShowed: boolean = false;
  isMarket: boolean = false;
  walletConnector: any = null;

  constructor(
    private router: Router,
    private matDialog: MatDialog,

  ) {
    if (localStorage.getItem('isLoggedIn') == 'true'){

      this.isLoggedIn = true;
      this.isMarket = true;

    } else {

      this.isLoggedIn = false;

    }

  }

  ngOnInit() {}
  getSearchInput(event: any) {
    console.log(event.target.value);
    this.target = event.target.value;
  }

  getSearch() {
    this.router.navigate(['/search/', this.target]);
  }

  showNavSearch() {
    this.SearchShowed = !this.SearchShowed;
  }

  setMarket() {
    this.isMarket = !this.isMarket;
  }





  showWeb3Modal() {
    const dialogRef = this.matDialog.open(Web3ModalComponent, {
      //disableClose: true,
      panelClass: 'dialog-crypto-login-custom',
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'auto',
      width: 'auto',
      //data: { senderDetails: this.senderDetails },
    });
  }


}
