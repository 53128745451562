<app-layout-header></app-layout-header>



<div class="content success-page-cont">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">

                <div class="card success-card">
                    <div class="card-body">
                        <div class="success-cont">
                            <i class="fa fa-check" style="background: #004FFF;border-color:#004FFF"></i>
                            <h3>Withdraw Successfully confirmed !</h3>
                            <p>Withdraw successfuly confirmed on <strong> {{current_date | date:'yyyy MM dd'}}</strong>. <br> your payment take 3 to 5 work days</p>
                            <a class="btn btn-primary view-inv-btn" style="background: #004FFF;border-color:#004FFF"> View Invoice </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>