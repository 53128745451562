import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { UserService } from '../../../services/apis/users/user.service';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {

  loginForm!: FormGroup;
  otp!: string;

  //temps
  phone;
  countryCode;
  otpAuth;

  constructor(
    private router: Router,
    private auth: AuthService,
    public UserService: UserService,
  ) { 

    this.phone = localStorage.getItem('tempPhpne');
    this.countryCode = localStorage.getItem('tempCountry');
    this.otpAuth = localStorage.getItem('tempAuthCode');


  }

  ngOnInit(): void {
    
  }

  onOtpChange(event:any) {
    this.otp = event.target.value;
    //console.log(this.otp);
  }


  /*async verifyOtp() {
    if(this.otp == this.otpAuth){
      let regPhone = this.phone;
      let countryCode = this.countryCode;
      this.createNewUser(countryCode, regPhone, reg);
    }else{
      alert('otp not valid')
    }
  }


   createNewUser(countryCode:any, regPhone:any, reg:any) {
    this.UserService.createSingleUser(countryCode, regPhone, reg).subscribe(response => {

      //console.log(response.id);

      localStorage.setItem('pay_id', response.pay_call_id);
      localStorage.setItem('pay_user_id', response.id);
      localStorage.setItem('isLoggedIn', 'true');

      window.location.href="/";
     

      

    })
  }*/

}
