import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

     // API path
     base_path = 'https://preprod.paycall.co/index.php/api/search';
     filter_base_path = 'https://preprod.paycall.co/index.php/api/search-filter';
     current_user = localStorage.getItem('pay_id');
  
     constructor(private http: HttpClient) { }
   
   
     // Handle API errors
     handleError(error: HttpErrorResponse) {
       if (error.error instanceof ErrorEvent) {
         // A client-side or network error occurred. Handle it accordingly.
         console.error('An error occurred:', error.error.message);
       } else {
         // The backend returned an unsuccessful response code.
         // The response body may contain clues as to what went wrong,
         console.error(
           `Backend returned code ${error.status}, ` +
           `body was: ${error.error}`);
       }
       // return an observable with a user-facing error message
       return throwError(
         'Something bad happened; please try again later.');
     };
   
   
     // Get students data
      searchUsers(word:any): Observable<any> {
        return this.http
          .get<any>(this.base_path +'/'+word)
          .pipe(
            retry(2),
            catchError(this.handleError)
          )
      }

      searchById(payId:any): Observable<any> {
        return this.http
          .get<any>('https://preprod.paycall.co/index.php/api/search-by-id/'+ payId)
          .pipe(
            retry(2),
            catchError(this.handleError)
          )
      }
  
   
      /**
       * advanced filter form 
      **/
      advancedFilterSearch(__form: any) {
        return this.http.post<any>(this.filter_base_path , __form)
        .pipe(
          catchError(this.handleError)
        );
      }

}
