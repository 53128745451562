<!-- Incoming Call -->
<div class="call-box incoming-box">
        <div class="call-wrapper">
            <div class="call-inner">
                <div class="call-user">
                    <img class="call-avatar" src="{{data.senderDetails.thumbnail}}" alt="{{data.senderDetails.full_name}}">
                    <h4>{{data.senderDetails.full_name}}</h4>
                    <span>Calling ...</span>
                </div>
                <div class="call-items">
                    <a href="javascript:void(0);" class="btn call-item call-end" data-bs-dismiss="modal" aria-label="Close" (click)="closeDialog()"><i class="material-icons">call_end</i></a>
                    <a href="javascript:void(0);" (click)="acceptCall()" class="btn call-item call-start"><i class="material-icons">phone</i></a>
                </div>
            </div>
        </div>
    </div>
    <!-- /Incoming Call -->
