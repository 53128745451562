import { Component, OnInit } from '@angular/core';
import { CallService } from '../../../services/apis/calls/call.service';

@Component({
  selector: 'app-phone-history',
  templateUrl: './phone-history.component.html',
  styleUrls: ['./phone-history.component.scss']
})
export class PhoneHistoryComponent implements OnInit {

  callsHistory:any = [];

  constructor(
    public callService: CallService,
  ) { }

  ngOnInit(): void {
    this.callsHistoryList();
  }


  callsHistoryList() {
    this.callService.getCallsHistory().subscribe(response => {
      console.log(response);
      this.callsHistory = response;

    })
  }

}
