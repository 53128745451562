import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/apis/notifications/notification.service';
import { AppointmentService } from '../../services/apis/appointments/appointment.service';
import { WalletService } from '../../services/apis/wallets/wallet.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  notifications:any = [];
  userWallet:any = [];
  walletperc:any;
  notpercent:any;

  constructor(
    private router: Router,
    public notificationsService: NotificationService,
    public appointmentService: AppointmentService,
    public walletService: WalletService,
  ) { }

  ngOnInit(): void {
    this.getAllNotifications();
    this.getSingleUserWallet();
  }

  getAllNotifications() {
    //Get saved list of students
    this.notificationsService.getSingleUserFavory().subscribe(response => {
      //console.log(response);
      this.notifications = response;
      this.notpercent = response.length;

    })
  }

  getSingleUserWallet() {
    //Get saved list of students
    this.walletService.getSingleUserWallet().subscribe(response => {
      //this.getSingleWalletHistory(response['id']);
      this.userWallet = response;
      this.walletperc = response['sold'];
    })
  }

}
