/*import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Conversation, UserAgent, Session, Stream } from '@apirtc/apirtc';
import { ActivatedRoute, Router } from '@angular/router';
import { CallService } from '../../../services/apis/calls/call.service';
import { WalletService } from '../../../services/apis/wallets/wallet.service';
import { interval, Subscription } from 'rxjs';
import * as uuid from 'uuid';

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss']
})
export class VideoCallComponent implements OnInit {
  @ViewChild("localVideo") videoRef: ElementRef;

  ccUserId: any;
  callUserType: any;
  myCallId: any;
  room: any;
  dataRoomUser: any = [];
  isVideo: boolean = false;
  isAudio: boolean = true;
  timeCall: number = 0;
  conversation: any;
  remotesCounter = 0;
  isVideoMuted: boolean = false;
  isAudioMuted: boolean = false;
  walletSubscription: Subscription; // Subscription for wallet deduction
  payPeerSecond:number;
  receiverId:number;
  senderId:number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public callService: CallService,
    public walletService: WalletService
  ) {}

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.ccUserId = routeParams.get('userId');
    this.callUserType = routeParams.get('callType');

    if (this.callUserType == 'video') {
      this.isVideo = true;
    }

    if (this.activatedRoute.snapshot.paramMap.get('roomId')) {
      let room_id = this.activatedRoute.snapshot.paramMap.get('roomId');
      this.room = room_id;
      this.myCallId = room_id;
      this.getEnCallRoomData(this.myCallId);
      this.getOrCreateConversation(this.myCallId);
    } else {
      this.myCallId = uuid.v4();   
      this.callService.createNewCall(this.ccUserId, this.myCallId, this.callUserType).subscribe(response => {
        this.getEnCallRoomData(this.myCallId);
        this.getOrCreateConversation(this.myCallId);
      });
    }
    
  }

  getOrCreateConversation(conversationRoom: any) {
    var localStream = null;
    var userAgent = new UserAgent({
      //uri: 'apiKey:819abef1fde1c833e0601ec6dd4a8226'
      uri: 'apiKey:c825d902a47c5355b2a2fecca240db8b'
    });

    userAgent.register().then((session: Session) => {
      const conversation: Conversation = session.getConversation(conversationRoom);
      this.conversation = conversation;

      conversation.on('streamListChanged', (streamInfo: any) => {
        if (streamInfo.listEventType === 'added') {
          if (streamInfo.isRemote === true) {

            conversation.subscribeToMedia(streamInfo.streamId).then((stream: Stream) => {
              
              this.walletSubscription = interval(1000).subscribe(() => {
                this.timeCall += 1;
                this.createTransaction(this.receiverId, this.payPeerSecond);
                this.createTransactionSender(this.senderId, this.payPeerSecond);
              });
              
            }).catch((err) => {
              console.error('subscribeToMedia error', err);
            });
          }
        }
      });

      conversation.on('streamAdded', (stream: Stream) => {
        this.remotesCounter += 1;
        stream.addInDiv('remote-container', 'remote-media-' + stream.streamId, {}, false);
      }).on('streamRemoved', (stream: any) => {
        this.remotesCounter -= 1;
        stream.removeFromDiv('remote-container', 'remote-media-' + stream.streamId);
        this.router.navigate(['/call-end-page', this.myCallId, this.timeCall]).then(() => {
          window.location.reload();
        });
      });

  


      userAgent.createStream({
        constraints: {
          audio: this.isAudio,
          video: this.isVideo,
        }
      }).then((stream: Stream) => {
        localStream = stream;
        localStream.attachToElement(this.videoRef.nativeElement);
  
        conversation.join().then(() => {
          conversation.publish(localStream).then((stream: Stream) => {
            // Mute local audio stream when publishing
            //localStream.muteAudio(true); // Mute local audio
            console.log('published', stream);
          }).catch((err: any) => {
            console.error('publish error', err);
          });
        }).catch((err: any) => {
          console.error('Conversation join error', err);
        });
      }).catch((err: any) => {
        console.error('create stream error', err);
      });




    });
  }


  

  getEnCallRoomData(__room: any) {
    this.callService.getEndRoomData(__room).subscribe((response) => {
      if(response != null){
        this.dataRoomUser = response;
        this.receiverId = response.receiver;
        this.senderId = response.sender;
        this.payPeerSecond = this.callUserType == 'video' ? response.video_call_price / 60 : response.voice_call_price / 60;
        console.log('rooooooooooooom', response, 'paaaaaaaaay', this.payPeerSecond);
      }
    });
  }



  createTransaction(userId: any, totalPayment: any): void {
    this.walletService.createTransaction(userId, totalPayment)
      .subscribe(data => {
        // Handle successful response
        console.log(data);
      }, error => {
        // Handle error
        console.log(error.message);
      });
  }


  createTransactionSender(userId: any, totalPayment: any): void {
    this.walletService.createTransactionSender(userId, totalPayment)
      .subscribe(data => {
        // Handle successful response
        console.log(data);
      }, error => {
        // Handle error
        console.log(error.message);
    });
  } 

  HangUp() {
    this.router.navigate(['/call-end-page', this.myCallId, this.timeCall]).then(() => {
      window.location.reload();
    });
  }

  toggleVideoMute() {
    this.isVideoMuted = !this.isVideoMuted;
    this.conversation.getLocalStream().muteVideo(this.isVideoMuted);
  }

  toggleAudioMute() {
    this.isAudioMuted = !this.isAudioMuted;
    this.conversation.getLocalStream().muteAudio(this.isAudioMuted);
  }



  ngOnDestroy(): void {
    // Unsubscribe from the wallet deduction interval when component is destroyed
    if (this.walletSubscription) {
      this.walletSubscription.unsubscribe();
    }
  }

}*/

import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';


@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss']
})
export class VideoCallComponent {
  
  constructor(
    
  ) {}

 
}
