<app-layout-header></app-layout-header>
<!-- Page Content -->
<div class="content">
    <div class="container">

        <div class="row">
            <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">

                <app-menu-layout></app-menu-layout>


            </div>

            <div class="col-md-7 col-lg-8 col-xl-9">



                <div class="row">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="accordion border-0 " id="accordionPanelsStayOpenExample">

                                    <h1 style="color:#0e82fd;font-size: 32px;margin-bottom:2rem;">Privacy Settings</h1>


                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-heading1">
                                            <button class="accordion-button collapsed border-0" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse1"
                                                aria-expanded="false" aria-controls="panelsStayOpen-collapse1">
                                                {{blackListe.length}} Blocked User
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse1" class="accordion-collapse collapse border-0"
                                            aria-labelledby="panelsStayOpen-heading1">
                                            <div class="accordion-body">
                                                <div class="table-responsive">
                                                    <table class="table table-hover table-center mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>User Name</th>
                                                                <th>Appt Date</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let user of blackListe">
                                                                <td >
                                                                    <h2 class="table-avatar">
                                                                        <a class="avatar avatar-sm me-2">
                                                                            <img class="avatar-img rounded-circle" src="{{user.thumbnail}}" alt="User Image">
                                                                        </a>
                                                                        <a>{{user.full_name}}<span>#{{user.member}}</span></a>
                                                                    </h2>
                                                                </td>
                                                                <td>{{user.created_at}}</td>
                                                                <td><span
                                                                    class="badge rounded-pill bg-danger-light">Blocked</span></td>
                                                                <td>
                                                                    <div class="table-action">
                                                                        
                                                                        <a href="javascript:void(0);" class="btn btn-sm bg-primary-light">
                                                                            <i class="fa fa-check"></i> Unblock
                                                                        </a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            
                                                        </tbody>
                                                    </table>		
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="panelsStayOpen-heading2">
                                            <button class="accordion-button collapsed border-0" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse2"
                                                aria-expanded="false" aria-controls="panelsStayOpen-collapse2">
                                                Account Settings
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapse2" class="accordion-collapse collapse border-0"
                                            aria-labelledby="panelsStayOpen-heading2">
                                            <div class="accordion-body">
                                                <button class="btn btn-danger" (click)="deleteAccount()">Delete my account</button>
                                            </div>
                                        </div>
                                    </div>




                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</div>
<!-- /Page Content -->