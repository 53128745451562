import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

declare let window: any;
declare let Web3: any;



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // API path
  base_path = 'https://preprod.paycall.co/index.php/api/user';
  current_user = localStorage.getItem('pay_id');

  //web3 declarations
  private web3: any;
  public account: string | null = null;

  constructor(private http: HttpClient) {
    
    if (typeof window !== 'undefined' && typeof window.ethereum !== 'undefined') {
      this.web3 = new Web3(window.ethereum);
    } else {
      console.error('MetaMask is not installed or not available!');
    }

   }


    // send otp code 

    sendUserOtp(phone:any, otp:any): Observable<any> {
     return this.http
       .get<any>(this.base_path +'/send-otp/'+phone+'/'+otp)
       .pipe(
         retry(2),
         //catchError(this.handleError)
       )
   }



   async connectMetaMask(): Promise<void> {
    try {
      // Request account access
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log('MetaMask connected!');
    } catch (error) {
      console.error('User denied account access', error);
    }
  }

  async getAccount(): Promise<string> {
    const accounts = await this.web3.eth.getAccounts();
    return accounts[0];
  }






}
