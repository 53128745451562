/*import { Component, OnInit, ViewChild, Input, Inject, AfterViewInit, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { PlisioService } from 'src/app/services/cryptopayment/plisio.service';

import { WalletConnectService } from '../../../../services/cryptopayment/wallet-connect.service';

//tags input 
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';



declare var Web3: any;


@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit, AfterViewInit {


  @Input() amount;
  @Input() walletId;
  current_user = localStorage.getItem('pay_id');
  loader: boolean = false;

  serviceFee: any;
  totalToPay: any;
  stripeTest: FormGroup;


  @ViewChild('minutes') public minutes: ElementRef;
  @ViewChild('seconds') public seconds: ElementRef;


  counterStart: boolean = true;
  date: any;
  now: any;
  targetDate: any = new Date();
  targetTime: any = this.targetDate.getTime();
  difference: number;


  sourceCurrency: string = 'USD';
  sourceAmount: any = 2;
  orderNumber: string = '1';
  currency: string = 'BTC';
  email: string = 'customer@plisio.net';
  orderName: string = 'btc1';
  callbackUrl: string = 'http://test.com/callback';
  apiKey: string = '1XP1YSaJoSJWmJD2EHzqcRZuCYlNAGZEQmscioDUvXI7cjBXUB6iq50s1FuipSnf';

  invoiceUrl: string | null = null;
  errorMessage: string | null = null;

  
  inPayment: boolean = false;
  isLoading = false;
  chainId: string;

  private web3Modal: any;

  selectedCountry: string;
  //countries
  countriesListe: string[] = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Argentina',
  ];

  transactionData: any;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private plisioService: PlisioService,
    private walletConnectService: WalletConnectService,
    public dialogRef: MatDialogRef<PaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {

  }

  ngOnInit(): void {

    this.checkWalletConnected();
    this.initWeb3Modal();

  }


  ngAfterViewInit(): void {

    if (this.data.amount <= 300) {

      this.serviceFee = 12;
      this.totalToPay = (this.data.amount / 100) * 112;

    } else {

      this.serviceFee = 10;
      this.totalToPay = (this.data.amount / 100) * 110;

    }

  

    if (this.counterStart) {

      setInterval(() => {
        this.tickTock();
        this.checkTransactionStatus();
      }, 1000);
    }

  }

  tickTock() {
    this.date = new Date();
    this.now = this.date.getTime();
    //console.log(60 - this.date.getMinutes());
    this.minutes.nativeElement.innerText = 60 - this.date.getMinutes();
    this.seconds.nativeElement.innerText = 60 - this.date.getSeconds();
  }

  //create + transaction 
  addCoinsToWallet(userId, totalPayement) {

    return this.http
      .get<any>("https://preprod.paycall.co/index.php/api/wallet/wallet-max/" + userId + "/" + totalPayement)
      .subscribe(data => {
        this.dialogRef.close();
        //this.router.navigate(['/dashboard/wallet']);
        this.router.navigate(['/dashboard/wallet']).then(() => {
          window.location.reload();
        });
        this.toastr.success(totalPayement + ' Coins successfully added to your wallet.');
      }, error => {
        console.log(error.message);
      });

  }


  closeDialog() {
    this.dialogRef.close();
  }

  createInvoice() {
    this.isLoading = true;

    this.counterStart = true;

    const fd = new FormData();

    fd.append('source_currency', 'USD');
    fd.append('source_amount', this.totalToPay);
    fd.append('order_number', this.getTimestampString());
    fd.append('currency', this.chainId);
    fd.append('email', this.email);
    fd.append('order_name', 'UID123');
    fd.append('callback_url', 'http://localhost:4200/#/dashboard/wallet');


    this.plisioService.createInvoice(fd).subscribe(
      response => {
        //console.log('Errorrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr:', response);
        //this.checkTransactionStatus();
        if (response.status == 'success') {
          this.transactionData = response;
          this.inPayment = true;

        } else { //"error"
          //console.log(response.data.message);
          this.toastr.error(response.data.message);
          this.isLoading = false;
        }
      },
      error => {
        console.error('Error:', error);
        this.isLoading = false;
      }
    );
  }

  checkTransactionStatus() {

    const fd = new FormData();

    fd.append('transaction_id', this.transactionData.data.txn_id);

    this.plisioService.getOperationDetails(fd).subscribe(
      response => {
        //console.log('Error:traaaaaaaaaaaaaaaaaaaaaaaaaaansaction', response.data);
        if (response.data.status == 'completed') {
          this.addCoinsToWallet(this.current_user, this.data.amount);
        }
      },
      error => {
        //console.error('Error:', error);
      }
    );
  }

  getTimestampString() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Adding leading zero if needed
    const day = ('0' + currentDate.getDate()).slice(-2); // Adding leading zero if needed
    const hours = ('0' + currentDate.getHours()).slice(-2); // Adding leading zero if needed
    const minutes = ('0' + currentDate.getMinutes()).slice(-2); // Adding leading zero if needed
    const seconds = ('0' + currentDate.getSeconds()).slice(-2); // Adding leading zero if needed

    // Format the timestamp as YYYY-MM-DD HH:MM:SS
    const timestampString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return timestampString;
  }

  connectToWallet = () => {
    this.walletConnectService.connectWallet();
  }
  checkWalletConnected = async () => {
    const accounts = await this.walletConnectService.checkWalletConnected();
    if (accounts.length > 0) {
      //this.walletConnected = true;
      //this.walletId = accounts[0];
      console.log(accounts[0]);
    }
  }

  //web3 model
  initWeb3Modal(): void {
    const providerOptions = {
    };

    this.web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions // required
    });
  }

  async connectModal(): Promise<void> {
    try {
      const provider = await this.web3Modal.connect();
      console.log("Provider:", provider);
    } catch (e) {
      console.error("Could not get a wallet connection", e);
    }
  }


}*/


import { Component, OnInit, ViewChild, Input, Inject, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { PlisioService } from 'src/app/services/cryptopayment/plisio.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent implements OnInit, AfterViewInit, OnDestroy  {


  @Input() amount;
  @Input() walletId;
  current_user = localStorage.getItem('pay_id');
  loader: boolean = false;

  serviceFee: any;
  totalToPay: any;
  stripeTest: FormGroup;

  @ViewChild('minutes') public minutes: ElementRef;
  @ViewChild('seconds') public seconds: ElementRef;

  counterStart: boolean = true;
  date: any;
  now: any;
  targetDate: any = new Date();
  targetTime: any = this.targetDate.getTime();
  difference: number;

  sourceCurrency: string = 'USD';
  sourceAmount: any = 2;
  orderNumber: string = '1';
  currency: string = 'BTC';
  email: string = 'customer@plisio.net';
  orderName: string = 'btc1';
  callbackUrl: string = 'http://test.com/callback';
  apiKey: string = '1XP1YSaJoSJWmJD2EHzqcRZuCYlNAGZEQmscioDUvXI7cjBXUB6iq50s1FuipSnf';

  invoiceUrl: string | null = null;
  errorMessage: string | null = null;

  inPayment: boolean = false;
  isLoading = false;
  chainId: string;

  selectedCountry: string;
  countriesListe: string[] = [
    'Afghanistan',
    'Albania',
    'Algeria',   
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Argentina',
  ];

  transactionData: any;

  private subscriptions: Subscription = new Subscription();
  intervalId: any;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private plisioService: PlisioService,
    public dialogRef: MatDialogRef<PaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {

    if (this.data.amount <= 300) {
      this.serviceFee = 12;
      this.totalToPay = (this.data.amount / 100) * 112;
    } else {
      this.serviceFee = 10;
      this.totalToPay = (this.data.amount / 100) * 110;
    }

    /*if (this.counterStart) {
      setInterval(() => {
        this.tickTock();
        this.checkTransactionStatus();
      }, 1000);
    }*/

    if (this.counterStart) {
      this.startCheckingTransactionStatus();
    }


  }

  ngAfterViewInit(): void {
    
  }


  ngOnDestroy() {
    this.stopCheckingTransactionStatus();
    this.subscriptions.unsubscribe(); // Clean up subscriptions
  }

  tickTock() {
    this.date = new Date();
    this.now = this.date.getTime();
    if (this.minutes && this.minutes.nativeElement) {
      this.minutes.nativeElement.innerText = 60 - this.date.getMinutes();
    }
    if (this.seconds && this.seconds.nativeElement) {
      this.seconds.nativeElement.innerText = 60 - this.date.getSeconds();
    }
  }

  addCoinsToWallet(userId, totalPayement) {
    return this.http
      .get<any>("https://preprod.paycall.co/index.php/api/wallet/wallet-max/" + userId + "/" + totalPayement)
      .subscribe(data => {
        this.dialogRef.close();
        this.router.navigate(['/dashboard/wallet']).then(() => {
          window.location.reload();
        });
        this.toastr.success(totalPayement + ' Coins successfully added to your wallet.');
      }, error => {
        console.log(error.message);
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  createInvoice() {
    this.isLoading = true;
    this.counterStart = true;
    const fd = new FormData();
    fd.append('source_currency', 'USD');
    fd.append('source_amount', this.totalToPay);
    fd.append('order_number', this.getTimestampString());
    fd.append('currency', this.chainId);
    fd.append('email', this.email);
    fd.append('order_name', 'UID123');
    fd.append('callback_url', 'http://localhost:4200/#/dashboard/wallet');
    this.plisioService.createInvoice(fd).subscribe(
      response => {
        if (response.status == 'success') {
          this.transactionData = response;
          this.inPayment = true;
        } else {
          this.toastr.error(response.data.message);
          this.isLoading = false;
        }
      },
      error => {
        console.error('Error:', error);
        this.isLoading = false;
      }
    );
  }

  

  checkTransactionStatus() {
    const fd = new FormData();
    fd.append('transaction_id', this.transactionData.data.txn_id);
    const subscription = this.plisioService.getOperationDetails(fd).subscribe(
      response => {
        if (response.data.status === 'completed') {
          this.addCoinsToWallet(this.current_user, this.data.amount);
          this.stopCheckingTransactionStatus(); // Stop checking if transaction is completed
        }
      },
      error => {
        console.error('Error checking transaction status', error);
      }
    );

    this.subscriptions.add(subscription);
  }



  startCheckingTransactionStatus() {
    this.intervalId = setInterval(() => {
      this.tickTock();
      this.checkTransactionStatus();
      //console.log(this.intervalId);
    }, 1000);
  }

  stopCheckingTransactionStatus() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }


  /*checkTransactionStatus() {
    const fd = new FormData();
    fd.append('transaction_id', this.transactionData.data.txn_id);
    this.plisioService.getOperationDetails(fd).subscribe(
      response => {
        if (response.data.status == 'completed') {
          this.addCoinsToWallet(this.current_user, this.data.amount);
        }
      },
      error => { }
    );
  }*/

  getTimestampString() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    const day = ('0' + currentDate.getDate()).slice(-2);
    const hours = ('0' + currentDate.getHours()).slice(-2);
    const minutes = ('0' + currentDate.getMinutes()).slice(-2);
    const seconds = ('0' + currentDate.getSeconds()).slice(-2);
    const timestampString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return timestampString;
  }




}
