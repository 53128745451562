import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router'; //activated route
import { CallService } from '../../../services/apis/calls/call.service';

@Component({
  selector: 'app-end-call',
  templateUrl: './end-call.component.html',
  styleUrls: ['./end-call.component.scss']
})
export class EndCallComponent implements OnInit {

  roomId:any;
  callTimeSeconds:any;
  dataRoom:any = [];
  callType:string;
  calc:any;
  seconds:any;

  current_user = localStorage.getItem("pay_id");

  constructor(
   private route: ActivatedRoute,
   private router: Router,
   public callService: CallService,
   private http: HttpClient,
  ) { }



  ngOnInit() {
    // First get the product id from the current route.
    const routeParams = this.route.snapshot.paramMap;
    this.roomId = routeParams.get('roomId');
    this.getEnCallRoomData(this.roomId);
    this.seconds  = parseInt(routeParams.get('callTime'))/60;
  }


  /**
  * Fetch room data
  */
 getEnCallRoomData(__room:any) {
  //Get social  media links list
  this.callService.getEndRoomData(__room).subscribe((response) => {
    console.log('readyyyyyyyyyyyyyyyyyyyyyyyyyyyyy', response);
    
    this.dataRoom = response;
    this.callTimeSeconds =  parseFloat(JSON.stringify(this.seconds)).toFixed(2);
    console.log('readyyyyyyyyyyyyyyyyyyyyyyyyyyyyy seconds ', this.callTimeSeconds);
    if(this.dataRoom.call_type == 'audio'){
      console.log('eady audio ', this.dataRoom.voice_call_price);

      this.calc = this.callTimeSeconds * this.dataRoom.voice_call_price; 
      //this.createTransaction(response.sender, this.callTimeSeconds * this.dataRoom.voice_call_price);
      //this.createsenderTransaction(response.receiver, this.callTimeSeconds * this.dataRoom.voice_call_price);

    }else{

      this.calc = this.callTimeSeconds * this.dataRoom.video_call_price; 
      //this.createTransaction(response.sender, this.callTimeSeconds * this.dataRoom.video_call_price);
      //this.createsenderTransaction(response.receiver, this.callTimeSeconds * this.dataRoom.video_call_price);
      
      //console.log('eady video ', Number(this.dataRoom.video_call_price));
      
    }
  });
  
}


  //create - transaction 
  createTransaction(userId, totalPayement){

      return this.http
      .get<any>("https://preprod.paycall.co/index.php/api/wallet/wallet-min/" + userId + "/" + totalPayement)
      .subscribe(data => {  
        //this.ngOnInit();
        //console.log(data); 
       }, error => {
        console.log(error.message);
      });
   
  }

    //create + transaction 
    createsenderTransaction(userId, totalPayement){

      return this.http
      .get<any>("https://preprod.paycall.co/index.php/api/wallet/wallet-max/" + userId + "/" + totalPayement)
      .subscribe(data => {  
        //this.ngOnInit();
        //console.log(data); 
       }, error => {
        console.log(error.message);
      });
   
  }

}
