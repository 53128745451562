import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {NgForm} from '@angular/forms';

//imports 
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

//users service 
import { UserService } from '../../../services/apis/users/user.service';
import { FavorieService } from '../../../services/apis/favorites/favorie.service';


@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  blackListe:any = [];

  constructor(
    private router: Router,
    public UserService: UserService,
    private http:HttpClient,
    private toastr: ToastrService,
    public favorieService: FavorieService,
  ) { }

  ngOnInit(): void {
    this.getBlackListe();
  }


  /**
  * delete account 
  **/
 deleteAccount() {
  
  this.UserService.deleteMyAccount().subscribe((response) => {
    //console.log('dfgdgdfgfd', response);
     if(response.error == false)
     {
      this.toastr.success(response.message);
      localStorage.removeItem('pay_id');
      localStorage.removeItem('pay_user_id');
      localStorage.removeItem('isLoggedIn');
      this.router.navigate(['/home']);
     }


  });
}


getBlackListe() {
  //Get saved list of students
  this.favorieService.getSingleUserBlackListe().subscribe(response => {
    console.log(response);
    this.blackListe = response;
  })
}

}
