<div [id]="localCallId" class="local"></div>
<div *ngFor="let callId of remoteCalls" [id]="callId" class="remote"></div>

<span class="call-duration" style="position: absolute;bottom: 5%;left: 2%;font-size: 18px;">{{timeCall}} Seconds - {{payPeerSecond * timeCall | currency:'USD':'symbol':'1.2-2'}} </span>
<div class="call-footer"  style="position: fixed;bottom: 3%;left: 50%;transform: translateX(-50%);background-color: #f0f0f0;padding: 10px;box-sizing: border-box;text-align: center;border: none;border-radius: 14px;">
    <div class="call-icons">
        <span class="call-duration d-none">20</span>
        <ul class="call-items">
            <li class="call-item">
                <a class="mute-video" title="Toggle Video" data-placement="top" data-bs-toggle="tooltip">
                    <i class="feather-video"></i>
                </a>
            </li>
            <li class="call-item">
                <a  class="mute-bt" title="Toggle Audio" data-placement="top" data-bs-toggle="tooltip">
                    <i class="feather-mic"></i>
                </a>
            </li>
            <li class="call-item">
                <a  (click)="handleCallEnd()"  class="mute-bt bg-danger" title="Toggle Laptop Audio" data-placement="top" data-bs-toggle="tooltip">
                    <i class="feather-phone text-white"></i>
                </a>
            </li>
        </ul>
    </div>
</div>
   
