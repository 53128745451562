/*import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'; //dialogue module
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';

@Component({
  selector: 'app-deposit-wallet',
  templateUrl: './deposit-wallet.component.html',
  styleUrls: ['./deposit-wallet.component.scss']
})
export class DepositWalletComponent implements OnInit {

  creditsList: any = [];
  nbrCredits: number = 50;

  payPalConfig: PayPalConfig;
  dateNow = new Date();

  constructor(private matDialog: MatDialog) {

    this.initConfig();
    
    this.creditsList = [
      { id: '10', price: '5', creedit: '5', bonus: '00' },
      { id: '10', price: '20', creedit: '20', bonus: '00' },
      { id: '10', price: '50', creedit: '50', bonus: '00' },
    ];
  }

  ngOnInit(): void {
    
  }

  async payementModal() {
    const dialogRef = this.matDialog.open(PaymentModalComponent, {
      panelClass: 'dialog-payment-custom',
      data: { walletId: 10, amount: this.nbrCredits },
    });
  }

  initConfig() {
    this.payPalConfig = new PayPalConfig(PayPalIntegrationType.ClientSideREST, PayPalEnvironment.Sandbox, {
      commit: true,
      client: {
        sandbox: 'AfcZwwOTd9WoVgccNcqNsi7aVLQhFmqDvJ6xNeg3jMGDnd_ZJdny_Ra6UrZgQbPwjDuiaVObz54Ch4lG' // from test1 app
      },
      button: {
        label: 'paypal',
        size: 'responsive',
        layout: 'vertical',
        color: 'gold',
        shape: 'pill'
      },
      onPaymentComplete: (data, actions) => {
        console.log("Transaction completed", { data, actions });
        actions.payment.execute().then(x => {
          console.log(x);
        });
      },
      onCancel: (data, actions) => {
        console.log('OnCancel');
      },
      onError: (err) => {
        console.log('OnError');
      },
      transactions: [
        {
          amount: {
            total: 100,
            currency: 'PHP',
            details: {
              subtotal: 100,
              tax: 0,
              shipping: 0,
              handling_fee: 0,
              shipping_discount: 0,
              insurance: 0
            }
          },
          custom: 'Custom value',
          item_list: {
            items: [
              {
                name: 'Booking for Appointment #123412323',
                description: 'some really long dataatatat',
                quantity: 1,
                price: 100,
                tax: 0,
                sku: '1',
                currency: 'PHP'
              }
            ]
          },
        }
      ]
    });
  }
}*/


// deposit-wallet.component.ts

import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';

@Component({
  selector: 'app-deposit-wallet',
  templateUrl: './deposit-wallet.component.html',     
  styleUrls: ['./deposit-wallet.component.scss']
})
export class DepositWalletComponent implements OnInit {
  @ViewChild('payPalElem1') payPalElem1: any; // ViewChild to access ngx-paypal element

  creditsList: any = [];
  nbrCredits: number = 50;

  constructor(private matDialog: MatDialog) {
    
    this.creditsList = [
      { id: '10', price: '10', creedit: '10', bonus: '00' },
      { id: '30', price: '30', creedit: '30', bonus: '00' },
      { id: '10', price: '50', creedit: '50', bonus: '00' },
    ];
  }

  ngOnInit(): void {}


  async paymentModal() {
    const dialogRef = this.matDialog.open(PaymentModalComponent, {
      disableClose: true,
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'full-screen-modal',
      data: { walletId: 10, amount: this.nbrCredits },
    });
  }



  setRadioValues(event:any){
    console.log(event.target.value);
    this.nbrCredits = event.target.value;
  }


}
