<app-layout-header></app-layout-header>

<div class="content">
    <div class="container">
        <div class="row">
            <div class="col-md-7 col-lg-8 offset-2">
                <div class="card">
                    <div class="card-body">

                        <div>


                            <div class="payment-widget">
                                <h4 class="card-title">Widhraw </h4>
  
                                <div class="payment-list">

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="mb-3 card-label">
                                                <div>
                                                    <input type="radio" id="crypto1" name="currency" class="card-radio" [(ngModel)]="chainId" value="USDT_TRX">
                                                    <label for="crypto1">
                                                        <svg width="24" height="24" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                            <title>Icons Crypto/Tether USD TRC20</title>
                                                            <g id="Icons-Crypto/Tether-USD-TRC20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                              <g id="Tether_Logo" transform="translate(0.184524, 0.275000)" fill-rule="nonzero">
                                                                <circle id="Oval" fill="#26A17B" cx="24.6825397" cy="24.6825397" r="24.6825397"></circle>
                                                                <g id="Group" transform="translate(9.301587, 12.138889)" fill="#FFFFFF">
                                                                  <path d="M18.0119048,14.8214286 L18.0119048,14.8174603 C17.8412698,14.8293651 16.9603175,14.8809524 15,14.8809524 C13.4325397,14.8809524 12.3333333,14.8373016 11.9444444,14.8174603 L11.9444444,14.8214286 C5.91666667,14.5555556 1.42063492,13.5079365 1.42063492,12.25 C1.42063492,10.9960317 5.92063492,9.94444444 11.9444444,9.67857143 L11.9444444,13.7777778 C12.3373016,13.8055556 13.468254,13.8730159 15.0277778,13.8730159 C16.9007937,13.8730159 17.8373016,13.7936508 18.0119048,13.7777778 L18.0119048,9.68253968 C24.0277778,9.95238095 28.5119048,11 28.5119048,12.2539683 C28.5119048,13.5079365 24.0238095,14.5555556 18.0119048,14.8253968 M18.0119048,9.25793651 L18.0119048,5.59126984 L26.4047619,5.59126984 L26.4047619,0 L3.55555556,0 L3.55555556,5.59126984 L11.9484127,5.59126984 L11.9484127,9.25793651 C5.12698413,9.57142857 0,10.9206349 0,12.5396825 C0,14.1587302 5.13095238,15.5079365 11.9484127,15.8253968 L11.9484127,27.5793651 L18.015873,27.5793651 L18.015873,15.8253968 C24.8253968,15.5119048 29.9444444,14.1626984 29.9444444,12.5436508 C29.9444444,10.9285714 24.8253968,9.57539683 18.015873,9.26190476" id="Shape"></path>
                                                                </g>
                                                              </g>
                                                              <g id="TRC20" transform="translate(30.000000, 0.000000)">
                                                                <circle id="Oval" fill="#FF060A" cx="10" cy="10" r="10"></circle>
                                                                <path d="M15.4337111,8.1984446 C14.8911922,7.67907649 14.1407077,6.88596202 13.5294698,6.32346948 L13.4933019,6.29721983 C13.4331242,6.24711641 13.365274,6.20782279 13.2925699,6.18097137 L13.2925699,6.18097137 C11.8187269,5.89597515 4.95947976,4.56661779 4.82565844,4.58349257 C4.78816099,4.58893751 4.75231893,4.6030333 4.72077145,4.62474202 L4.68641192,4.65286665 C4.64410346,4.6974162 4.61196946,4.75123607 4.59237531,4.81036456 L4.58333333,4.83473923 L4.58333333,4.96786247 L4.58333333,4.98848719 C5.35551855,7.21783261 8.4044747,14.5208607 9.00486227,16.234588 C9.0410302,16.3508364 9.10974925,16.5720835 9.23814539,16.5833333 L9.26707973,16.5833333 C9.33579879,16.5833333 9.62875899,16.1820887 9.62875899,16.1820887 C9.62875899,16.1820887 14.8658747,9.59717604 15.3957348,8.89593534 C15.464321,8.80955712 15.5248707,8.7166443 15.5765744,8.61843903 C15.6034191,8.46214098 15.549185,8.302702 15.4337111,8.1984446 Z M10.9723974,8.96530942 L13.2075753,7.04345993 L14.5186626,8.29594331 L10.9723974,8.96530942 Z M10.1043672,8.83968609 L6.2560999,5.56972948 L12.4824083,6.76033868 L10.1043672,8.83968609 Z M10.4515793,9.69654972 L14.3902664,9.03843345 L9.88735966,14.6633588 L10.4515793,9.69654972 Z M5.73347338,5.89597515 L9.78247268,9.45842788 L9.19655228,14.6671088 L5.73347338,5.89597515 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                                                              </g>
                                                            </g>
                                                          </svg>
                                                        Tether TRC-20
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="mb-3 card-label">
                                                <div>
                                                    <input type="radio" id="crypto2" name="currency" class="card-radio" [(ngModel)]="chainId" value="BUSD">
                                                    <label for="crypto2">
                                                        <svg width="24" height="24" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <title>Icons Crypto/Binance USD</title>
                                                            <g id="Icons-Crypto/Binance-USD" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <path
                                                                    d="M25,0 C38.807095,0 50,11.192905 50,25 C50,38.807095 38.807095,50 25,50 C11.192905,50 0,38.807095 0,25 C0,11.192905 11.192905,0 25,0 Z M37.7532129,20.7342236 L20.7334672,37.7540361 L24.9797189,42.0000201 L42,24.9804752 L37.7532129,20.7342236 Z M31.387751,14.3567135 L14.3680054,31.3764592 L18.6145248,35.6230455 L35.6342704,18.6032329 L31.387751,14.3567135 Z M12.2465194,20.7625368 L8,25.0089893 L12.2465194,29.2555087 L16.4929719,25.0089893 L12.2465194,20.7625368 Z M25.010241,8.00002008 L14.360241,18.6500201 L18.6067604,22.8964726 L29.2567604,12.2465395 L25.010241,8.00002008 Z"
                                                                    id="Combined-Shape" fill="#F0B90B"></path>
                                                            </g>
                                                        </svg>
                                                        Binance USD BEP-20
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="mb-3 card-label">
                                                <div>
                                                    <input type="radio" id="crypto3" name="currency" class="card-radio" [(ngModel)]="chainId" value="USDT_BSC ">
                                                    <label for="crypto3">
                                                        
                                                        <svg width="24" height="24" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                            <title>Icons Crypto/Tether USD BSC20</title>
                                                            <g id="Icons-Crypto/Tether-USD-BSC20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                <g id="Tether_Logo" transform="translate(0.184524, 0.275000)" fill-rule="nonzero">
                                                                    <circle id="Oval" fill="#26A17B" cx="24.6825397" cy="24.6825397" r="24.6825397"></circle>
                                                                    <g id="Group" transform="translate(9.301587, 12.138889)" fill="#FFFFFF">
                                                                        <path d="M18.0119048,14.8214286 L18.0119048,14.8174603 C17.8412698,14.8293651 16.9603175,14.8809524 15,14.8809524 C13.4325397,14.8809524 12.3333333,14.8373016 11.9444444,14.8174603 L11.9444444,14.8214286 C5.91666667,14.5555556 1.42063492,13.5079365 1.42063492,12.25 C1.42063492,10.9960317 5.92063492,9.94444444 11.9444444,9.67857143 L11.9444444,13.7777778 C12.3373016,13.8055556 13.468254,13.8730159 15.0277778,13.8730159 C16.9007937,13.8730159 17.8373016,13.7936508 18.0119048,13.7777778 L18.0119048,9.68253968 C24.0277778,9.95238095 28.5119048,11 28.5119048,12.2539683 C28.5119048,13.5079365 24.0238095,14.5555556 18.0119048,14.8253968 M18.0119048,9.25793651 L18.0119048,5.59126984 L26.4047619,5.59126984 L26.4047619,0 L3.55555556,0 L3.55555556,5.59126984 L11.9484127,5.59126984 L11.9484127,9.25793651 C5.12698413,9.57142857 0,10.9206349 0,12.5396825 C0,14.1587302 5.13095238,15.5079365 11.9484127,15.8253968 L11.9484127,27.5793651 L18.015873,27.5793651 L18.015873,15.8253968 C24.8253968,15.5119048 29.9444444,14.1626984 29.9444444,12.5436508 C29.9444444,10.9285714 24.8253968,9.57539683 18.015873,9.26190476" id="Shape"></path>
                                                                    </g>
                                                                </g>
                                                                <g id="BSC20" transform="translate(30.000000, 0.000000)">
                                                                    <path d="M10,0 C15.5228495,0 20,4.47715047 20,10 C20,15.5228495 15.5228495,20 10,20 C4.47715047,20 0,15.5228495 0,10 C0,4.47715047 4.47715047,0 10,0" id="Combined-Shape" fill="#F0B90B"></path>
                                                                    <path d="M12.5791664,11.0774967 L14.0791664,12.5774967 L10.0024953,16.6608256 L5.91250866,12.5774967 L7.41250866,11.0774967 L8.93000533,12.5833289 L9.09666312,12.7500133 L10.0016698,13.6549933 L12.5791664,11.0774967 Z M4.83948069,8.49553928 L6.34560586,10.0016644 L4.83948069,11.5078162 L3.33332889,10.0016644 L4.83948069,8.49553928 Z M15.1611478,8.49468176 L16.667273,10.0008336 L15.1611478,11.5069854 L13.654996,10.0008336 L15.1611478,8.49468176 Z M10.004482,8.49135286 L11.5106072,9.99750466 L10.004482,11.5036565 L8.49833023,9.99750466 L10.004482,8.49135286 Z M10.0024874,3.33331558 L14.0791585,7.41667111 L12.5791585,8.92583222 L10.0024874,6.34082557 L8.92666844,7.41667111 L7.41250067,8.92583222 L5.91250067,7.41667111 L10.0024874,3.33331558 Z" id="Combined-Shape" fill="#FFFFFF"></path>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                        Tether BEP 20
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="card-label">
                                                <label for="card_number">Wallet adress for withdrawal</label>
                                                <input [(ngModel)]="recipientEmail" class="form-control"
                                                    id="card_number" placeholder="Wallet adress for withdrawal"
                                                    type="email">
                                                    <button style="position: relative;
                                                    top: -46px;
                                                    left: 83%;
                                                    border: 0;
                                                    height: 42px;
                                                    border-radius: 3px;">Use my wallet</button>
                                            </div>
                                        </div>


                                        <label
                                            class="mb-2 w-100 d-flex align-items-center justify-content-between">Minimum
                                            Withdrawal amount <strong>20 USD</strong></label>

                                        <div class="col-md-12">
                                            <div class="mb-3 card-label">
                                                <label for="card_number">Withdrawal amount</label>
                                                <input [(ngModel)]="witdhawAmount" class="form-control" id="card_number"
                                                    placeholder="Withdrawal amount" type="number"
                                                    (keyup)="calcAmount()">
                                            </div>
                                        </div>
                                        <label
                                            class="mb-2 w-100 d-flex align-items-center justify-content-between">Daily
                                            Withdrawal limit <strong>1000 USD</strong></label>

                                        <div class="col-md-12">
                                            <div class="mb-3 card-label">
                                                <label for="expiry_month">Amount you receive</label>
                                                <input [(ngModel)]="receiveAmount" class="form-control"
                                                    id="expiry_month" placeholder="Amount you receive" type="number"
                                                    disabled>
                                            </div>
                                        </div>

                                        <label
                                            class="mb-2 w-100 d-flex align-items-center justify-content-between">Service
                                            fees <strong> {{serviceFees}}%</strong></label>

                                    </div>
                                </div>



                                <div class="submit-section mt-4" style="text-align:right;">
                                    <button class="btn btn-primary submit-btn" [disabled]="loading" (click)="withdraw()">
                                        <span *ngIf="!loader">Withdraw <i
                                                class="fa fa-angle-right font-size-16 ml-2"></i></span>
                                        <span *ngIf="loader"><i
                                                class="fa fa-circle-o-notch fa-spin font-size-16"></i></span>
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>